import { computed, inject, readonly } from '@vue/composition-api';

import type { Ref, UnwrapRef } from '@vue/composition-api';
import type { DeepReadonly } from '@vue/composition-api';
import type { ScenarioEntity } from '@digitsole/blackburn-entities/dist/entities/scenario/scenario.entity';
import type { AnalysisEntityCompleted } from '@/plugins/podocore/repositories/analysis.repository';

export function useAnalysis<T extends AnalysisEntityCompleted>(
  analysis: Ref<T>
): {
  analysis: DeepReadonly<Ref<T>>;
  scenarioKeyPrefix: DeepReadonly<Ref<ScenarioEntity['key']>>;
  isWalking: DeepReadonly<Ref<boolean>>;
  isRunning: DeepReadonly<Ref<boolean>>;
  isDegraded: DeepReadonly<Ref<boolean>>;
  // TODO : fix this when Typescript version is bumped to 4.5.5
  patient: any;
} {
  const _analysis = analysis ?? inject<UnwrapRef<T>>('analysis');

  if (!_analysis) throw new Error('analysis not provided');

  const scenarioKeyPrefix = computed(() => _analysis.value._scenario.key.split('_')[0]);
  const isWalking = computed(() => scenarioKeyPrefix.value.startsWith('walking'));
  const isRunning = computed(() => scenarioKeyPrefix.value.startsWith('running'));
  const isDegraded = computed(() => _analysis.value.analysis_validity === 1);

  const patient = computed(() => {
    if ('_patient' in _analysis.value) {
      // TODO : remove this typing when Typescript version is bumped to 4.5.5
      return readonly(_analysis.value._patient);
    }
    return undefined;
  });

  return {
    analysis: readonly(_analysis),
    scenarioKeyPrefix: readonly(scenarioKeyPrefix),
    isWalking: readonly(isWalking),
    isRunning: readonly(isRunning),
    isDegraded: readonly(isDegraded),
    patient
  };
}
