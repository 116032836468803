












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartWalkingFootProgressionAngleCircumduction from '@/components/charts/walking/ChartWalkingFootProgressionAngleCircumduction.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartFootProgressionAngle',
  components: {
    ChartExportationContainer,
    ChartWalkingFootProgressionAngleCircumduction
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const valid = computed(
      () =>
        properties.displayMetrics.walking_foot_progression_angle_flat_foot &&
        properties.displayMetrics.walking_swing_width
    );

    const normsType = computed(() => {
      return {
        base: [
          properties.norms?.walking_foot_progression_angle_flat_foot.bounds.neutral.min,
          properties.norms?.walking_foot_progression_angle_flat_foot.bounds.neutral.max
        ]
      };
    });

    return {
      // Valid
      valid,
      // Values
      normsType
    };
  }
});
