import { usePodocoreModuleService } from '@/plugins/podocore';

export function dateFormatRelatedToSelectedLanguage(): string {
  const doctorService = usePodocoreModuleService('doctor');
  const doctorConfigLang = (doctorService.state.value as any).context.doctor.config.lang;

  switch (doctorConfigLang) {
    case 'fr':
      return 'DD/MM/YYYY';
    default:
      return 'MM/DD/YYYY';
  }
}
