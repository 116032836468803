

















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartStandardGaugeSections from '@/components/charts/standard/ChartStandardGaugeSections.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningWidgetCadence',
  components: {
    ChartContainer,
    ChartStandardGaugeSections
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    simple: {
      type: Boolean
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.aggregates?.running_step_cadence);
    const normsType = computed(() => {
      return {
        base: [
          properties.norms.running_step_cadence.bounds.neutral.min,
          properties.norms.running_step_cadence.bounds.neutral.max
        ]
      };
    });

    return {
      valid,
      normsType
    };
  }
});
