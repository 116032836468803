


















































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { enumToArrayAndTranslate } from '@/helpers/helped-functions.helper';
// Import type -------------------------------------------------------------------------------------
import { ConditionsWalkingAidDevices } from '@/plugins/podocore/repositories/analysis.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'HomeAnalysisSummary',
  props: {
    conditions: {
      type: Object,
      required: true
    }
  },
  setup() {
    function getPainVariant(stringLevel: string) {
      const level = parseFloat(stringLevel);
      if (level <= 3) return 'success';
      else if (level > 3 && level < 8) return 'warning';
      else return 'error';
    }

    const internalWalkingAidList: any = enumToArrayAndTranslate(ConditionsWalkingAidDevices, {
      key: 'analysis.steps.conditions.walking_aids'
    });
    function getWalkingAidName(value: string) {
      const result = internalWalkingAidList.find((x: any) => x.value === value).text;
      if (result) return result;
      return value;
    }
    return {
      // Methods
      getPainVariant,
      getWalkingAidName
    };
  }
});
