































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, reactive, Ref, PropType } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionStanceTime from '@/components/charts/evolution/ChartEvolutionStanceTime.vue';
import ChartEvolutionSwingTime from '@/components/charts/evolution/ChartEvolutionSwingTime.vue';
import ChartEvolutionStrideLength from '@/components/charts/evolution/ChartEvolutionStrideLength.vue';
import ChartEvolutionSpeed from '@/components/charts/evolution/ChartEvolutionSpeed.vue';
import ChartEvolutionCadence from '@/components/charts/evolution/ChartEvolutionCadence.vue';
import ChartEvolutionSymmetry from '@/components/charts/evolution/ChartEvolutionSymmetry.vue';
import ChartEvolutionCircumduction from '@/components/charts/evolution/ChartEvolutionCircumduction.vue';
import ChartEvolutionFootProgressionAngle from '@/components/charts/evolution/ChartEvolutionFootProgressionAngle.vue';
import ChartEvolutionPropulsionRate from '@/components/charts/evolution/ChartEvolutionPropulsionRate.vue';
import ChartEvolutionClearance from '@/components/charts/evolution/ChartEvolutionClearance.vue';
import ChartEvolutionAttackAngle from '@/components/charts/evolution/ChartEvolutionAttackAngle.vue';
import ChartEvolutionHeelStrike from '@/components/charts/evolution/ChartEvolutionHeelStrike.vue';
import ChartEvolutionFlatFoot from '@/components/charts/evolution/ChartEvolutionFlatFoot.vue';
import ChartEvolutionHeelOff from '@/components/charts/evolution/ChartEvolutionHeelOff.vue';
import ChartEvolutionToesOff from '@/components/charts/evolution/ChartEvolutionToesOff.vue';
import ChartEvolutionWalkingPhases from '@/components/charts/evolution/ChartEvolutionWalkingPhases.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionGroupsWalking',
  components: {
    ChartEvolutionSymmetry,
    ChartEvolutionSpeed,
    ChartEvolutionCadence
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    selectedCharts: {
      type: Array as PropType<string[]>,
      required: true
    },
    pathology: {
      type: String,
      required: false
    },
    relative: {
      type: Boolean,
      required: true
    }
  },
  setup(properties) {
    const aggregates = computed(() => properties.values.data.aggregates);
    const norms = computed(() => properties.values.data.norms);
    const _selectedCharts: Ref<string[]> = computed(() => properties.selectedCharts);
    const _pathology = computed(() => properties.pathology);
    const manifest = computed(() => properties.values.analysisManifests);

    const modes = reactive({
      standard: true,
      advanced: false
    });

    const chartsData: any = reactive({
      strideLength: {
        chart: ChartEvolutionStrideLength,
        props: {
          strideLength: aggregates.value.walking_stride_length,
          norms: norms.value.walking_stride_length.bounds.neutral
        }
      },
      stanceTime: {
        chart: ChartEvolutionStanceTime,
        props: {
          stanceTime: aggregates.value.walking_stance_time,
          proportionStanceTime: aggregates.value.walking_proportion_stance_time,
          doubleTime: aggregates.value.walking_double_support_time,
          proportionDoubleTime: aggregates.value.walking_proportion_double_support_time,
          norms: norms.value.walking_stance_time.bounds.neutral,
          relativeNorms: norms.value.walking_proportion_stance_time.bounds.neutral,
          doubleType: 'contact'
        }
      },
      swingTime: {
        chart: ChartEvolutionSwingTime,
        props: {
          swingTime: aggregates.value.walking_swing_time,
          proportionSwingTime: aggregates.value.walking_proportion_swing_time,
          norms: norms.value.walking_swing_time.bounds.neutral,
          relativeNorms: norms.value.walking_proportion_swing_time.bounds.neutral
        }
      },
      propulsionRate: {
        chart: ChartEvolutionPropulsionRate,
        props: {
          propulsionRatio: aggregates.value.walking_propulsion_ratio,
          norms: norms.value.walking_propulsion_ratio.bounds.neutral
        }
      },
      attackAngle: {
        chart: ChartEvolutionAttackAngle,
        props: {
          attackAngle: aggregates.value.walking_plantar_flexion_angle_foot_in,
          norms: norms.value.walking_plantar_flexion_angle_foot_in.bounds.neutral
        }
      },
      circumduction: {
        chart: ChartEvolutionCircumduction,
        props: {
          circumduction: aggregates.value.walking_swing_width,
          norms: norms.value.walking_swing_width.bounds.neutral
        }
      },
      clearance: {
        chart: ChartEvolutionClearance,
        props: {
          clearance: aggregates.value.walking_minimum_toe_clearance,
          norms: norms.value.walking_minimum_toe_clearance.bounds.neutral
        }
      },
      footProgressionAngle: {
        chart: ChartEvolutionFootProgressionAngle,
        props: {
          footProgressionAngle: aggregates.value.walking_foot_progression_angle_flat_foot,
          norms: norms.value.walking_foot_progression_angle_flat_foot.bounds.neutral
        }
      },
      heelStrike: {
        chart: ChartEvolutionHeelStrike,
        props: {
          heelStrike: aggregates.value.walking_pronation_angle_foot_in,
          norms: norms.value.walking_pronation_angle_foot_in.bounds.neutral
        }
      },
      flatFoot: {
        chart: ChartEvolutionFlatFoot,
        props: {
          flatFoot: aggregates.value.walking_pronation_angle_FFI,
          norms: norms.value.walking_pronation_angle_FFI.bounds.neutral
        }
      },
      heelOff: {
        chart: ChartEvolutionHeelOff,
        props: {
          heelOff: aggregates.value.walking_pronation_angle_FFO,
          norms: norms.value.walking_pronation_angle_FFO.bounds.neutral
        }
      },
      toesOff: {
        chart: ChartEvolutionToesOff,
        props: {
          toesOff: aggregates.value.walking_pronation_angle_foot_out,
          norms: norms.value.walking_pronation_angle_foot_out.bounds.neutral
        }
      }
    });

    const chartsPathology: any = reactive({
      neurological: [
        chartsData.strideLength,
        chartsData.stanceTime,
        chartsData.attackAngle,
        chartsData.propulsionRate,
        chartsData.circumduction,
        chartsData.footProgressionAngle
      ],
      traumatology: [
        chartsData.heelStrike,
        chartsData.flatFoot,
        chartsData.heelOff,
        chartsData.toesOff,
        chartsData.stanceTime,
        chartsData.propulsionRate,
        chartsData.circumduction,
        chartsData.footProgressionAngle
      ],
      're-athleticism': [
        chartsData.stanceTime,
        chartsData.swingTime,
        chartsData.strideLength,
        chartsData.propulsionRate,
        chartsData.attackAngle,
        chartsData.heelStrike,
        chartsData.flatFoot,
        chartsData.heelOff,
        chartsData.toesOff,
        chartsData.footProgressionAngle,
        chartsData.circumduction,
        chartsData.clearance
      ]
    });

    const customCharts: any = reactive({
      'stride-length': chartsData.strideLength,
      'stance-time': chartsData.stanceTime,
      'swing-time': chartsData.swingTime,
      // 'stride-duration'
      'clearance-steppage': [chartsData.attackAngle, chartsData.clearance],
      'propulsion-rate': chartsData.propulsionRate,
      'prosup-angles': [chartsData.heelStrike, chartsData.flatFoot, chartsData.heelOff, chartsData.toesOff]
    });

    const charts: any = computed(() => {
      if (_pathology.value && _pathology.value !== 'custom') return chartsPathology[_pathology.value];

      let _charts: any = [];
      let id: string;

      for (id of _selectedCharts.value) {
        if (Array.isArray(customCharts[id])) _charts = [..._charts, ...customCharts[id]];
        if (customCharts[id]) _charts.push(customCharts[id]);
      }
      return _charts;
    });

    return {
      charts,
      aggregates,
      norms,
      manifest,
      modes
    };
  }
});
