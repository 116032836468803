




















































































































































import { computed, defineComponent, toRefs } from '@vue/composition-api';
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import {
  getInsoleDescriptions,
  getInsoleImage,
  getOrthoticsData,
  getWedgesImages
} from '@/utils/orthotics.utils';
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';

export default defineComponent({
  name: 'ExportationFrameOrthotics',
  components: { ChartExportationContainer, ExportationViewerPage },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    scenario: {
      type: String,
      required: true
    },
    pdfData: {
      type: Object,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    }
  },
  setup(properties) {
    const { analysis, scenario } = toRefs(properties);

    const isWalking = computed(() => scenario.value.startsWith('walking'));
    const isRunning = computed(() => scenario.value.startsWith('running'));

    const orthoticsData = computed(() =>
      getOrthoticsData({
        isWalking: isWalking.value,
        isRunning: isRunning.value,
        analysis: analysis.value
      })
    );

    const soleImage = computed(() =>
      getInsoleImage({
        isWalking: isWalking.value,
        isRunning: isRunning.value,
        orthotics: orthoticsData.value
      })
    );

    const soleDescription = computed(() =>
      getInsoleDescriptions({
        isWalking: isWalking.value,
        isRunning: isRunning.value,
        orthotics: orthoticsData.value
      })
    );

    const wedges = computed(() =>
      getWedgesImages({
        isWalking: isWalking.value,
        isRunning: isRunning.value,
        orthotics: orthoticsData.value
      })
    );

    return {
      orthoticsData,
      soleImage,
      soleDescription,
      wedges
    };
  }
});
