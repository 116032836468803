export class DrawShoes {
  // Val
  private readonly canvas: HTMLCanvasElement;
  private readonly ctx: CanvasRenderingContext2D;
  private readonly angleType: string;

  private readonly margins: any = {
    rearfoot_strike: {
      x: -20,
      y: -30
    },
    midfoot_strike: {
      x: -25,
      y: -32
    },
    forefoot_strike: {
      x: -35,
      y: -47
    }
  };

  private readonly footPos: any = {
    rearfoot_strike: {
      x: 25,
      y: 10
    },
    midfoot_strike: {
      x: 35,
      y: 10
    },
    forefoot_strike: {
      x: 60,
      y: -8
    }
  };

  private readonly rotation: any = {
    rearfoot_strike: 7,
    midfoot_strike: 25,
    forefoot_strike: 45
  };

  private readonly imagesProperties = {
    height: 56,
    width: 90
  };

  constructor(canvas: HTMLCanvasElement, angleType: string) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d')!;

    this.angleType = angleType;
  }

  async drawShoes() {
    this.clean();
    // Draw
    this.drawShoesImg();
  }

  drawShoesImg() {
    // GLOBALS
    const that: any = this;
    const angleImages = {
      img: new Image(),
      imgSrc: require('@/assets/images/single-shoe.svg')
    };

    this.ctx.translate(this.footPos[this.angleType].x, this.footPos[this.angleType].y);
    this.ctx.rotate((this.rotation[this.angleType] * Math.PI) / 180);

    angleImages.img.addEventListener('load', () => {
      that.ctx!.drawImage(angleImages.img, 0, 0, that.imagesProperties.width, that.imagesProperties.height);
    });

    angleImages.img.src = angleImages.imgSrc;
  }

  clean() {
    // Store the current transformation matrix
    this.ctx.resetTransform();

    // Use the identity matrix while clearing the canvas
    // this.ctx!.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Restore the transform
    this.ctx.restore();
  }
}
