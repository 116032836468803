












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, PropType } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils - ----------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionRehabGlobal',
  components: {
    ChartEvolutionContainer
  },
  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    unit: {
      type: String as PropType<EUnitKey>,
      required: false
    },
    customUnit: {
      type: String,
      required: false
    },
    round: {
      type: Number,
      default: 0
    },
    manifest: {
      type: Array
    },
    parameterType: {
      type: String as PropType<'avg' | 'sum'>,
      default: 'avg'
    },
    manageImp: {
      type: Boolean
    },
    exportation: {
      type: Boolean
    }
  },
  setup(properties) {
    const { evolutionChartStructure, getGlobalData, getGlobalDataVariations, getSerieItemStructure } =
      useChart();

    const data: any = computed(() =>
      getGlobalData(properties.data, properties.manageImp, properties.parameterType)
    );

    const snakeCaseTitle = computed(() =>
      properties.title
        ? properties.title
            .replace(/\W+/g, ' ')
            .split(/ |\B(?=[A-Z])/)
            .map((word) => word.toLowerCase())
            .join('_')
        : 'undefined'
    );

    const dataVariations = computed(() => getGlobalDataVariations(data.value.global));

    const valid = computed(() => !!data.value.global.filter((x: number | null) => x).length);

    const series = computed(() => {
      return [
        getSerieItemStructure(
          `${snakeCaseTitle.value}`,
          i18n.t(`commons.standards.${properties.title}`) as string,
          data.value.global,
          EThemeColor.Chart1
        )
      ];
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(
          i18n.t(`commons.standards.${properties.title}`) as string,
          properties.manifest,
          properties.unit || properties.customUnit,
          properties.round,
          { exportation: properties.exportation }
        ),
        series: series.value
      };
    });

    return {
      valid,
      dataVariations,
      chartOptions
    };
  }
});
