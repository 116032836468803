



















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, watch, ref, onMounted } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import utils ------------------------------------------------------------------------------------
import { useI18n } from '@/utils/i18n.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
import HomeLatestAnalysis from '@/components/home/HomeLatestAnalysis.vue';
// Import type -------------------------------------------------------------------------------------
import type { AnalysisEntityCompleted } from '@/plugins/podocore/repositories/analysis.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'HomeLatestAnalysisList',
  components: {
    AlertError,
    HomeLatestAnalysis
  },
  props: {
    latestAnalysisListRequest: {
      type: Object,
      required: true
    },
    light: {
      type: Boolean
    },
    isPatientFile: {
      type: Boolean,
      default: true
    }
  },
  setup(properties, { root }) {
    const { dateFormat, hourFormat } = useI18n();
    const { trackSuccess } = useAnalytics();
    // const requestModule = usePodocoreModule('request');

    const analysisListRequest = properties.latestAnalysisListRequest;

    const hasAnalysisList = ref(false);

    // const patientRequests = ref(new Map<string, any>());

    /* watch(hasAnalysisList, (value) => {
      if (value) {
        analysisListRequest.data.value?.docs.map((analysis: AnalysisEntityCompleted) => {
          patientRequests.value.set(
            analysis.patientCuid,
            requestModule.useAuthenticatedRequest(`${apiConfig.default}/patients/${analysis.patientCuid}`)
          );
        });

        [...patientRequests.value.values()].forEach((patientRequest) => {
          patientRequest.request();
        });
      }
    }); */

    onMounted(() => {
      watch(
        analysisListRequest.isPending,
        (isPending) => {
          if (!isPending && analysisListRequest.data.value.docs.length) {
            hasAnalysisList.value = true;
          }
        },
        { immediate: true }
      );
    });

    /* const fullAnalysis = computed(() => {
      if (!hasAnalysisList.value) return [];
      return analysisListRequest.data.value.docs.map((analysis: any) => {
        const patientRequest = patientRequests.value.get(analysis.patientCuid);

        if (patientRequest?.data.value) {
          return { ...analysis, patient: patientRequest.data };
        } else {
          return analysis;
        }
      });
    }); */

    /* const patientCuids = [
      ...new Set(
        analysisListRequest.data.value?.docs.map((analysis: AnalysisEntityCompleted) => {
          return analysis.patientCuid;
        })
      )
    ]; */

    /* const patientRequests = patientCuids.map((cuid) =>
      requestModule.useAuthenticatedRequest(`${apiConfig.default}/patients/${cuid}`)
    );

    patientRequests.forEach((request) => {
      request.request();
    });

    const patients = computed(() => {
      return patientRequests.map((request) => {
        return request.data;
      });
    });

    const fullAnalysis = computed(() => {
      return analysisListRequest.data.value.docs.map((analysis: AnalysisEntityCompleted) => {
        const patient = patients.find((patient) => patient.value?.cuid === analysis.patientCuid);
        return {
          ...analysis,
          patient
        };
      });
    }); */

    function navigateToAnalysis(analysis: any) {
      root.$router.push({
        path: `patients/${analysis.patientCuid}/result/${analysis.cuid}`
      });
      // Analytics
      if (analysis._patient.demoCuid)
        trackSuccess(`Demo ${analysis._scenario.key.split('_')[0]} analysis`, { value: true });
    }

    const isPending = computed(() => properties.latestAnalysisListRequest.isPending.value);

    return {
      // Requests
      analysisListRequest,
      // fullAnalysis,
      // Methods
      navigateToAnalysis,
      // Utils
      dateFormat,
      hourFormat,
      trackSuccess,
      // Flags
      hasAnalysisList,
      isPending
    };
  }
});
