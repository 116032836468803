import { DrawShoes } from '@/plugins/charts/strike-pattern/single-angles-strike-shoes-running';

const sideColors = {
  left: {
    fill: '#ffaa02',
    background: '#fff7e6'
  },
  right: {
    fill: '#003dd1',
    background: '#e5ecfa'
  }
};

export type TStrikePattern = {
  [key: string]: any;
  angleType: string;
};
export type TSide = 'left' | 'right';

export class DrawSVG {
  // Val
  private readonly canvas: HTMLCanvasElement;
  private readonly shoesCanvas: any;
  private readonly side: TSide;
  private readonly ctx: CanvasRenderingContext2D;

  private readonly strikePattern: TStrikePattern = {
    angleType: ''
  };

  private readonly pos: any = {
    chartStructure: {
      startY: 0,
      endY: 0
    },
    middleX: 0,
    xPad: 0,
    x: {
      rearfoot_strike: 0,
      midfoot_strike: 0,
      forefoot_strike: 0
    }
  };

  private footGenerator: any;

  constructor(canvas: HTMLCanvasElement, strikePattern: TStrikePattern, side: TSide, shoesCanvas: any) {
    this.canvas = canvas;
    this.strikePattern = strikePattern;
    this.shoesCanvas = shoesCanvas;
    this.side = side;
    this.ctx = canvas.getContext('2d')!;
    // Structure data
    this.pos.chartStructure.startY = this.canvas.height * 0.15; // get 15%
    this.pos.chartStructure.endY = this.canvas.height * 0.7; // get 70%
    this.pos.middleX = this.canvas.width / 2;
    // Value positions
    this.pos.x.rearfoot_strike = 40;
    this.pos.x.midfoot_strike = this.pos.middleX;
    this.pos.x.forefoot_strike = 100;
    this.pos.x.angleText = this.pos.middleX - this.pos.middleX / 3;
    // Value positions
    this.pos.xPad = this.canvas.width / 5;
  }

  async draw() {
    this.clean();
    // Draw
    this.shoeAngle();
  }

  shoeAngle() {
    // GLOBALS
    const angleDirection = this.strikePattern.angleType === 'forefoot_strike' ? 1 : -1;
    const angleHeight = 18;
    const line = {
      size: 75,
      width: 3,
      marginBottom: 1.5
    };
    const angles: any = {
      x: Math.cos((angleHeight * Math.PI) / 180) * line.size,
      y: Math.sin((angleHeight * Math.PI) / 180) * line.size
    };
    //
    this.ctx.fillStyle = 'rgba(63,145,222,0.1)';
    this.ctx.strokeStyle = '#144CD4';
    this.ctx.lineWidth = line.width;
    this.ctx.lineCap = 'round';

    // --- DRAW --- //

    // SHOE IMAGES
    this.footGenerator = new DrawShoes(this.shoesCanvas, this.strikePattern.angleType);
    requestAnimationFrame(() => {
      this.footGenerator.drawShoes();
    });

    // ANGLES LINES
    if (this.strikePattern.angleType !== 'midfoot_strike') {
      this.ctx.fillStyle = this.side === 'left' ? sideColors.left.background : sideColors.right.background;
      // Angle shape
      this.ctx.beginPath();
      this.ctx.moveTo(this.pos.x[this.strikePattern.angleType], this.pos.chartStructure.endY);
      this.ctx.lineTo(
        this.pos.x[this.strikePattern.angleType] - angles.x * angleDirection,
        this.pos.chartStructure.endY - angles.y
      );
      this.ctx.lineTo(
        this.pos.x[this.strikePattern.angleType] - angles.x * angleDirection,
        this.pos.chartStructure.endY
      );
      this.ctx.fill();

      // Angle line
      this.ctx.strokeStyle = this.side === 'left' ? sideColors.left.fill : sideColors.right.fill;
      this.ctx.beginPath();
      this.ctx.moveTo(this.pos.x[this.strikePattern.angleType], this.pos.chartStructure.endY);
      this.ctx.lineTo(
        this.pos.x[this.strikePattern.angleType] - angles.x * angleDirection,
        this.pos.chartStructure.endY - angles.y
      );
      this.ctx.stroke();
    }
  }

  clean() {
    // Store the current transformation matrix
    this.ctx.save();

    // Use the identity matrix while clearing the canvas
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Restore the transform
    this.ctx.restore();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
}
