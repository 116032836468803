





















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartRehabSideHopJumpProfile from '@/components/charts/rehab/ChartRehabSideHopJumpProfile.vue';
import ChartRehabSideHopJumpProfileSimplified from '@/components/charts/rehab/ChartRehabSideHopJumpProfileSimplified.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameJumpingChartSideHopJumpProfile',
  components: {
    ChartExportationContainer,
    ChartRehabSideHopJumpProfile,
    ChartRehabSideHopJumpProfileSimplified
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    activityDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.aggregates.jumping_flight_time);

    const advanced = computed(
      () =>
        properties.displayMetrics.aggregates.jumping_stance_time_left_stance &&
        properties.displayMetrics.aggregates.jumping_stance_time_right_stance &&
        properties.displayMetrics.aggregates.jumping_flight_time
    );

    return {
      valid,
      advanced
    };
  }
});
