













































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, watch, reactive, ref, onMounted } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientReasonsForConsultationCard',
  setup(_, { root }) {
    const { data: patient, setPatientInfos } = usePatient();
    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    const patientConsultation = reactive({
      editComment: undefined as string | undefined,
      editMode: false,
      loading: false,
      error: undefined
    });

    function breakLine(value: string) {
      return value.replace(/(\r\n|\n)/g, '<br/>');
    }

    const cancelPatientConsultation = () => {
      patientConsultation.editMode = false;
      patientConsultation.loading = false;
      patientConsultation.editComment = patient.value?.infos?.consultationReason
        ? patient.value.infos.consultationReason
        : undefined;
    };

    const { isPending } = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/patients/${root.$route.params.cuid}`
    );

    let patientPatchRequest = ref<any>(undefined);

    const savePatientConsultation = () => {
      patientPatchRequest.value.request({
        axios: {
          method: 'PATCH',
          data: {
            consultationReason: patientConsultation.editComment
          }
        }
      });
      patientConsultation.loading = isPending.value;
    };

    onMounted(() => {
      patientPatchRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/patients/${patient.value?.cuid}/infos`,
        {
          axios: {
            method: 'PATCH'
          }
        }
      );
      if (patient.value?.infos.consultationReason)
        patientConsultation.editComment = patient.value.infos.consultationReason;
    });

    const isPatientPatchSuccess = computed(() => {
      return (
        !patientPatchRequest.value?.isPending &&
        !patientPatchRequest.value?.error &&
        !!patientPatchRequest.value?.data
      );
    });

    watch(isPatientPatchSuccess, (value) => {
      if (value) {
        setPatientInfos(patientPatchRequest.value.data.infos);
        cancelPatientConsultation();
        busModule.publish(
          busModule.events.patientPatched({
            patient: patientPatchRequest.value.data
          })
        );
      }
    });

    return {
      // Values
      patientConsultation,
      patient,
      // Methods
      cancelPatientConsultation,
      savePatientConsultation,
      breakLine
    };
  }
});
