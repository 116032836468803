



























































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingCircumduction',
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    side: {
      type: String,
      required: true
    },
    vertical: {
      type: Boolean
    },
    simple: {
      type: Boolean
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();
    const valueType = computed(() => (isImperial.value ? 'imp_avg' : 'avg'));

    const svgSize = computed(() => {
      return {
        width: properties.vertical ? 58 : 177,
        height: properties.vertical ? 177 : 58
      };
    });
    const svgRotation = computed(() =>
      properties.vertical ? 'rotate(-90 177 58) translate(55 -120)' : 'rotate(0)'
    );
    const svgTextRotation = computed(() => {
      return {
        left: properties.vertical ? 'translate(135 -93) rotate(90)' : 'rotate(0)',
        right: properties.vertical ? 'translate(114 15) rotate(90)' : 'translate(99.874 22)'
      };
    });

    const unit = computed(() => getUnit(EValueTypeUnit.Circumduction));

    return {
      // Values
      valueType,
      svgSize,
      svgRotation,
      svgTextRotation,
      unit
    };
  }
});
