























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, watchEffect, Ref } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { DrawSVG } from '@/plugins/charts/strike-pattern/single-strike-pattern-running-simplified';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningStrikePatternSimplified',
  props: {
    aggregates: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const rLeftCanvas: Ref<HTMLCanvasElement | null> = ref(null);
    const rRightCanvas: Ref<HTMLCanvasElement | null> = ref(null);
    const rLeftFootCanvas: Ref<HTMLCanvasElement | null> = ref(null);
    const rRightFootCanvas: Ref<HTMLCanvasElement | null> = ref(null);

    const angleTranslations: any = {
      rearfoot_strike: 'commons.standards.heel',
      midfoot_strike: 'commons.standards.midfoot',
      forefoot_strike: 'commons.standards.forefoot'
    };

    const strikePattern = computed(() => {
      return {
        left: {
          angleType: properties.aggregates.running_strike_pattern.left_foot.avg
        },
        right: {
          angleType: properties.aggregates.running_strike_pattern.right_foot.avg
        }
      };
    });

    function draw() {
      const shoesCanvas = {
        left: rLeftFootCanvas.value as HTMLCanvasElement,
        right: rRightFootCanvas.value as HTMLCanvasElement
      };
      if (rLeftCanvas.value && rRightCanvas.value) {
        const leftCanvas = new DrawSVG(
          rLeftCanvas.value as HTMLCanvasElement,
          strikePattern.value.left,
          'left',
          shoesCanvas.left
        );
        requestAnimationFrame(() => {
          leftCanvas!.draw();
        });
        const rightCanvas = new DrawSVG(
          rRightCanvas.value as HTMLCanvasElement,
          strikePattern.value.right,
          'right',
          shoesCanvas.right
        );
        requestAnimationFrame(() => {
          rightCanvas!.draw();
        });
      }
    }

    watchEffect(() => {
      draw();
    });

    return {
      rLeftCanvas,
      rRightCanvas,
      rLeftFootCanvas,
      rRightFootCanvas,
      angleTranslations,
      strikePattern
    };
  }
});
