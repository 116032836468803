

































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useVuetifyBreakpoints } from '@/utils/vuetify-breakpoints.utils';
import { usePatient } from '@/utils/patient.utils';
// Import components -------------------------------------------------------------------------------
import PatientBirthdayCard from '@/components/patient/PatientBirthdayCard.vue';
import PatientGenderCard from '@/components/patient/PatientGenderCard.vue';
import PatientShoesSizeCard from '@/components/patient/PatientShoesSizeCard.vue';
import PatientHeightCard from '@/components/patient/PatientHeightCard.vue';
import PatientWeightCard from '@/components/patient/PatientWeightCard.vue';
import PatientPathologiesCard from '@/components/patient/PatientPathologiesCard.vue';
import PatientReasonsForConsultationCard from '@/components/patient/PatientReasonsForConsultationCard.vue';
import PatientBackgroundCard from '@/components/patient/PatientBackgroundCard.vue';
import PatientPhoneCard from '@/components/patient/PatientPhoneCard.vue';
import PatientEmailCard from '@/components/patient/PatientEmailCard.vue';
import PatientTrackCard from '@/components/patient/PatientTrackCard.vue';
import PatientZipCityCard from '@/components/patient/PatientZipCityCard.vue';
import PatientCountryCard from '@/components/patient/PatientCountryCard.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientFile',
  components: {
    PatientBirthdayCard,
    PatientGenderCard,
    PatientShoesSizeCard,
    PatientHeightCard,
    PatientWeightCard,
    PatientPathologiesCard,
    PatientReasonsForConsultationCard,
    PatientBackgroundCard,
    PatientPhoneCard,
    PatientEmailCard,
    PatientTrackCard,
    PatientZipCityCard,
    PatientCountryCard
  },
  setup() {
    const { isLgAndLarger } = useVuetifyBreakpoints();
    const { data: patient } = usePatient();

    const hasContactInformations = computed(() => {
      return patient.value?.infos.contact?.phone || patient.value?.infos.contact?.email;
    });

    const hasAddressInformations = computed(() => {
      return (
        patient.value?.infos.address?.street ||
        patient.value?.infos.address?.zipCode ||
        patient.value?.infos.address?.city ||
        patient.value?.infos.address?.country
      );
    });

    const hasPersonalInformations = computed(() => {
      return hasContactInformations.value || hasAddressInformations.value;
    });

    return {
      // Values
      hasContactInformations,
      hasAddressInformations,
      hasPersonalInformations,
      isLgAndLarger
    };
  }
});
