




















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartStandardSlider from '@/components/charts/standard/ChartStandardSlider.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingStrideLength',
  components: {
    ChartStandardSlider
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { isImperial } = useUnit().currentUnit();
    const unit = getUnit(EValueTypeUnit.StrideLength);

    return {
      isImperial,
      unit
    };
  }
});
