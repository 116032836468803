




























































































































































































































































































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { ESide } from '@/plugins/charts/gaitline/gaitline';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabTripleHopJumpProfile',
  props: {
    segments: {
      type: Object,
      required: true
    },
    data: Object,
    isAdvancedMode: Boolean
  },
  setup(properties) {
    const { cdn } = useCDN();

    const defaultAngleOptions = {
      cx: 46,
      cy: 42,
      radius: 45,
      startAngle: 0,
      endAngle: 0,
      lineLength: 45,
      invert: false
    };

    function getAngle(options: typeof defaultAngleOptions) {
      const start = polarToCartesian(options.cx, options.cy, options.radius, options.endAngle);
      const end = polarToCartesian(options.cx, options.cy, options.radius, options.startAngle);
      const largeArcFlag = options.endAngle - options.startAngle <= 180 ? '0' : '1';

      const lineStart = polarToCartesian(
        options.cx,
        options.cy,
        options.lineLength,
        options.invert ? options.startAngle : options.endAngle
      );

      const curveOrigin = {
        x: 208,
        y: 84
      };

      const curveLimitY = curveOrigin.y - (Math.abs(options.endAngle - options.startAngle) / 90) * 1000;

      const curveStart = polarToCartesian(
        curveOrigin.x + 90,
        curveOrigin.y - 10,
        50,
        (options.invert ? options.startAngle : options.endAngle) * 1.1
      );

      const x1 = lineStart.x;
      const y1 = lineStart.y;
      const x2 = options.cx;
      const y2 = options.cy;

      return {
        circularSector: [
          'M',
          start.x,
          start.y,
          'A',
          options.radius,
          options.radius,
          0,
          largeArcFlag,
          0,
          end.x,
          end.y,
          'L',
          options.cx,
          options.cy,
          'Z'
        ].join(' '),
        line: {
          x1,
          y1,
          x2,
          y2
        },
        curve: [
          'M',
          ...[curveOrigin.x, curveOrigin.y], // A point
          'C',
          ...[curveOrigin.x + 90, curveOrigin.y], // A-B bezier point
          ...[curveStart.x - 40, Math.max(Math.min(curveStart.y + 20, curveOrigin.y), curveLimitY)], // B-A bezier point
          ...[curveStart.x, Math.max(Math.min(curveStart.y, curveOrigin.y), curveLimitY)], // B point
          'C',
          ...[curveStart.x + 14, Math.max(Math.min(curveStart.y - 5, curveOrigin.y), curveLimitY)], // B-C bezier point
          ...[curveStart.x + 20, Math.max(Math.min(curveStart.y + 5, curveOrigin.y + 8), curveLimitY + 8)], // C-B bezier point
          ...[curveStart.x + 20, Math.max(Math.min(curveStart.y + 15, curveOrigin.y + 8), curveLimitY + 8)], // C point
          'L',
          ...[curveStart.x + 20, curveOrigin.y + 8], // D point
          'L',
          ...[curveStart.x + 40, curveOrigin.y + 8] // E point
        ].join(' ')
      };
    }

    function normalizeAngle(angle: number) {
      return angle * -1;
    }

    function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
      };
    }

    function getSidedAngle(value: number, side: ESide) {
      let config: Partial<typeof defaultAngleOptions>;
      if (side === ESide.Left) {
        if (Math.sign(value) < 1) {
          config = {
            startAngle: 90 + value,
            endAngle: 90,
            invert: true
          };
        } else {
          config = {
            cx: 52,
            startAngle: -90,
            endAngle: -90 + value
          };
        }
      } else {
        if (Math.sign(value) < 1) {
          config = {
            cx: 52,
            startAngle: -90,
            endAngle: -90 - value
          };
        } else {
          config = {
            startAngle: 90 - value,
            endAngle: 90,
            invert: true
          };
        }
      }

      return getAngle({
        ...defaultAngleOptions,
        ...config
      });
    }

    const angles = computed(() => {
      return {
        jump1: {
          left: getSidedAngle(
            properties.segments.jumping_pronation_angle_foot_in.left_foot.avg[0],
            ESide.Left
          ),
          right: getSidedAngle(
            properties.segments.jumping_pronation_angle_foot_in.right_foot.avg[0],
            ESide.Right
          )
        },
        jump2: {
          left: getSidedAngle(
            properties.segments.jumping_pronation_angle_foot_in.left_foot.avg[1],
            ESide.Left
          ),
          right: getSidedAngle(
            properties.segments.jumping_pronation_angle_foot_in.right_foot.avg[1],
            ESide.Right
          )
        },
        jump3: {
          left: getSidedAngle(
            properties.segments.jumping_pronation_angle_foot_in.left_foot.avg[2],
            ESide.Left
          ),
          right: getSidedAngle(
            properties.segments.jumping_pronation_angle_foot_in.right_foot.avg[2],
            ESide.Right
          )
        }
      };
    });

    return {
      // Values
      angles,
      // Methods
      cdn
    };
  }
});
