



























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, reactive, toRefs, watch } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import FormFieldText from '@/components/forms/FormFieldText.vue';
// Import utils ------------------------------------------------------------------------------------
import { useFormFieldText, useFormObserver, cleanFieldValue, useForm } from '@/utils/forms.utils';
import { useRehab } from '@/utils/rehab.utils';
// Import helpers ----------------------------------------------------------------------------------
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { AnalysisEntityCompleted } from '@digitsole/blackburn-entities/dist/entities/analysis/analysis-completed.entity';
import { EAnalysisName } from '@/components/patient/results/PatientResultsHeader.vue';
import type { TAnalysisNames } from '@/components/patient/results/PatientResultsHeader.vue';
import { AnalysisType } from '@/plugins/podocore/repositories/analysis.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AnalysisRenameForm',
  props: {
    analysis: {
      type: Object as PropType<AnalysisEntityCompleted>,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FormFieldText
  },
  setup(properties, { emit }) {
    const { analysis } = toRefs(properties);
    const rObserver = useFormObserver();

    const { getMode } = useRehab();
    const nameByAnalysisType: TAnalysisNames = reactive({
      [AnalysisType.walking]: EAnalysisName.Walking,
      [AnalysisType.running]: EAnalysisName.Running,
      [AnalysisType.jumping]: EAnalysisName.Jumping
    });

    const name = useFormFieldText<string | null>({
      key: 'name',
      value: null,
      rules: {
        required: false,
        min: 2,
        max: 50
      }
    });

    const { submit } = useForm(
      {
        name
      },
      rObserver,
      () => {
        emit('submit', getFields());
      }
    );

    function getFields() {
      return {
        name: cleanFieldValue(name)
      };
    }

    watch(
      analysis,
      (value) => {
        if (value) name.value = value.name ?? null;
      },
      { immediate: true }
    );

    watch(
      name,
      (value) => {
        emit('setName', name);
      },
      { immediate: true }
    );

    return {
      // References
      rObserver,
      // Value
      nameByAnalysisType,
      // Form fields
      name,
      // Methods
      getMode,
      submit
    };
  }
});
