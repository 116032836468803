












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils - ----------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
import { useUnit } from '@/utils/unit.utils';
// Import types ------------------------------------------------------------------------------------
import type { TDataStandard } from '@/components/patient/PatientEvolution.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionFootProgressionAngle',
  components: {
    ChartEvolutionContainer
  },
  props: {
    manifest: {
      type: Array
    },
    footProgressionAngle: {
      type: Object
    },
    norms: {
      type: Object
    },
    exportation: {
      type: Boolean
    }
  },
  setup(properties) {
    const {
      evolutionChartStructure,
      getSideData,
      getSideDataVariations,
      getSerieItemStructure,
      getNormsStructure
    } = useChart();
    const { isImperial } = useUnit().currentUnit();

    const title = 'commons.standards.foot-progression-angle';

    const footProgressionAngle: TDataStandard = computed(() => getSideData(properties.footProgressionAngle));

    const dataVariations = computed(() =>
      getSideDataVariations({
        left: footProgressionAngle.value?.left.values,
        right: footProgressionAngle.value?.right.values
      })
    );

    const valid = computed(() => !!footProgressionAngle.value);

    const series = computed(() => {
      let data = [
        // Left
        getSerieItemStructure(
          'foot_progression_angle_left',
          `${i18n.t('commons.standards.foot-progression-angle')} (${(
            i18n.t('commons.standards.left') as string
          ).slice(0, 1)})`,
          footProgressionAngle.value?.left.values,
          EThemeColor.Chart1
        ),
        getSerieItemStructure(
          'foot_progression_angle_left',
          undefined,
          footProgressionAngle.value?.left.ranges,
          EThemeColor.Chart1,
          true
        ),
        // Right
        getSerieItemStructure(
          'foot_progression_angle_right',
          `${i18n.t('commons.standards.foot-progression-angle')} (${(
            i18n.t('commons.standards.right') as string
          ).slice(0, 1)})`,
          footProgressionAngle.value?.right.values,
          EThemeColor.Chart2
        ),
        getSerieItemStructure(
          'foot_progression_angle_right',
          undefined,
          footProgressionAngle.value?.right.ranges,
          EThemeColor.Chart2,
          true
        )
      ];
      // Have norms
      if (properties.norms)
        data.push(
          getNormsStructure(
            'foot_progression_angle_norms',
            properties.norms as { min: number[]; max: number[] }
          )
        );

      return data;
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(title, properties.manifest, EUnitKey.Degree, 1, {
          exportation: properties.exportation
        }),
        series: series.value
      };
    });

    return {
      title,
      dataVariations,
      chartOptions,
      valid
    };
  }
});
