<template>
  <div class="chart-wrapper">
    <div class="header">
      <span>{{ $t(`commons.standards.${chart.title}`) }}</span>
    </div>
    <div class="d-flex flex-column justify-center align-center">
      <div class="d-flex align-center justify-center" style="min-width: 180px">
        <img
          :src="cdn('image', `${chart.title}.svg`)"
          height="60"
          :alt="$t(`commons.standards.${chart.title}`)"
        />
      </div>
      <div class="content-wrapper">
        <div class="d-flex justify-center font-weight-bold">N°</div>
        <div class="d-flex justify-center"><div class="number">1</div></div>
        <div class="d-flex justify-center"><div class="number">2</div></div>
        <div class="d-flex justify-center"><div class="number">3</div></div>
        <div class="d-flex justify-center font-weight-bold">{{ $t('commons.standards.left').charAt(0) }}</div>
        <div class="d-flex justify-space-around">
          <div
            class="data"
            v-for="(data, index) in chart.data"
            :key="index"
            :class="{ active: data.jump1DataDiffSide === 'left' }"
          >
            {{ data.jump1LeftData }}{{ getSimplifiedUnit(data.unit) }}
          </div>
        </div>
        <div class="d-flex justify-space-around">
          <div
            class="data"
            v-for="(data, index) in chart.data"
            :key="index"
            :class="{ active: data.jump2DataDiffSide === 'left' }"
          >
            {{ data.jump2LeftData }}{{ getSimplifiedUnit(data.unit) }}
          </div>
        </div>
        <div class="d-flex justify-space-around">
          <div
            class="data"
            v-for="(data, index) in chart.data"
            :key="index"
            :class="{ active: data.jump3DataDiffSide === 'left' }"
          >
            {{ data.jump3LeftData }}{{ getSimplifiedUnit(data.unit) }}
          </div>
        </div>
        <div></div>
        <div class="d-flex justify-space-around">
          <span class="data-diff" v-for="(data, index) in chart.data" :key="index"
            >{{ data.jump1DataDiff }}{{ getSimplifiedUnit(data.unit) }}</span
          >
        </div>
        <div class="d-flex justify-space-around">
          <span class="data-diff" v-for="(data, index) in chart.data" :key="index"
            >{{ data.jump2DataDiff }}{{ getSimplifiedUnit(data.unit) }}</span
          >
        </div>
        <div class="d-flex justify-space-around">
          <span class="data-diff" v-for="(data, index) in chart.data" :key="index"
            >{{ data.jump3DataDiff }}{{ getSimplifiedUnit(data.unit) }}</span
          >
        </div>
        <div class="d-flex justify-center font-weight-bold">
          {{ $t('commons.standards.right').charAt(0) }}
        </div>
        <div class="d-flex justify-space-around">
          <div
            class="data"
            v-for="(data, index) in chart.data"
            :key="index"
            :class="{ active: data.jump1DataDiffSide === 'right' }"
          >
            {{ data.jump1RightData }}{{ getSimplifiedUnit(data.unit) }}
          </div>
        </div>
        <div class="d-flex justify-space-around">
          <div
            class="data"
            v-for="(data, index) in chart.data"
            :key="index"
            :class="{ active: data.jump2DataDiffSide === 'right' }"
          >
            {{ data.jump2RightData }}{{ getSimplifiedUnit(data.unit) }}
          </div>
        </div>
        <div class="d-flex justify-space-around">
          <div
            class="data"
            v-for="(data, index) in chart.data"
            :key="index"
            :class="{ active: data.jump3DataDiffSide === 'right' }"
          >
            {{ data.jump3RightData }}{{ getSimplifiedUnit(data.unit) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabGeneralParametersAdvanced',
  props: {
    chart: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { cdn } = useCDN();
    const { getSimplifiedUnit } = useRehab();

    const units = computed(() => properties.chart.data.map((data) => data.unit).join(' & '));
    return {
      // Methods
      cdn,
      getSimplifiedUnit,
      // Values
      units
    };
  }
});
</script>

<style lang="scss" scoped>
.chart-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.header {
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 600;
  color: black;
}

.content-wrapper {
  margin-left: auto;
  max-width: 600px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  padding: 10px;
}

.data-diff {
  color: #bcbfc6;
  font-size: 14px;
  font-weight: 600;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background: #fafafa;
  font-weight: 600;
  font-size: 14px;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.data {
  color: black;
  padding: 2px 8px;
  background: #fafafa;
  border-radius: 14px;
  font-size: 14px;
  font-weight: 600;

  &.active {
    background: #ebeced;
  }
}
</style>
