






















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartRehabGeneralParameters from '@/components/charts/rehab/ChartRehabGeneralParameters.vue';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameJumpingChartCMJGeneralParameters',
  components: {
    ChartExportationContainer,
    ChartRehabGeneralParameters
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    isAdvancedMode: {
      type: Boolean,
      required: true
    },
    activityDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  },
  setup(properties) {
    const { getCMJGeneralParametersSimplifiedData, getCMJGeneralParametersAdvancedData } = useRehab();

    const simplifiedModeCharts = getCMJGeneralParametersSimplifiedData(properties.aggregates);
    const advancedModeCharts = getCMJGeneralParametersAdvancedData(properties.aggregates);

    return {
      simplifiedModeCharts,
      advancedModeCharts
    };
  }
});
