













































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, watch, reactive, ref, onMounted } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientBackgroundCard',
  setup(_, { root }) {
    const { data: patient, setPatientInfos } = usePatient();
    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    const patientBackground = reactive({
      editComment: undefined as string | undefined,
      editMode: false,
      loading: false,
      error: undefined
    });

    function breakLine(value: string) {
      return value.replace(/(\r\n|\n)/g, '<br/>');
    }

    const cancelPatientBackground = () => {
      patientBackground.editMode = false;
      patientBackground.loading = false;
      patientBackground.editComment = patient.value?.infos?.background
        ? patient.value.infos.background
        : undefined;
    };

    const { isPending } = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/patients/${root.$route.params.cuid}`
    );

    let patientPatchRequest = ref<any>(undefined);

    const savePatientBackground = () => {
      patientPatchRequest.value.request({
        axios: {
          method: 'PATCH',
          data: {
            background: patientBackground.editComment
          }
        }
      });
      patientBackground.loading = isPending.value;
    };

    onMounted(() => {
      patientPatchRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/patients/${patient.value?.cuid}/infos`,
        {
          axios: {
            method: 'PATCH'
          }
        }
      );
      if (patient.value?.infos.background) patientBackground.editComment = patient.value?.infos.background;
    });

    const isPatientPatchSuccess = computed(() => {
      return (
        !patientPatchRequest.value?.isPending &&
        !patientPatchRequest.value?.error &&
        !!patientPatchRequest.value?.data
      );
    });

    watch(isPatientPatchSuccess, (value) => {
      if (value) {
        setPatientInfos(patientPatchRequest.value.data.infos);
        cancelPatientBackground();
        busModule.publish(
          busModule.events.patientPatched({
            patient: patientPatchRequest.value.data
          })
        );
      }
    });

    return {
      // Values
      patientBackground,
      patient,
      // Methods
      cancelPatientBackground,
      savePatientBackground,
      breakLine
    };
  }
});
