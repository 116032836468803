












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartRunningSpeedCadenceDetailledExport from '@/components/charts/running/ChartRunningSpeedCadenceDetailledExport.vue';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameRunningChartSpeedCadenceDetailled',
  components: {
    ChartExportationContainer,
    ChartRunningSpeedCadenceDetailledExport
  },
  props: {
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    },
    segments: {
      type: Object,
      required: true
    },
    exportSelectedSegments: {
      type: Array,
      required: true
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();
    const { currentUnit } = useUnit();
    const speedUnit = computed(() => getUnit(EValueTypeUnit.Speed));

    const valid = computed(
      () =>
        !!properties.segments.running_step_cadence?.global?.avg &&
        !!properties.segments.running_speed?.global?.avg
    );

    return {
      valid,
      speedUnit,
      currentUnit,
      isImperial
    };
  }
});
