
























































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartStandardGaugeSlider',
  props: {
    data: {
      type: Object,
      required: true
    },
    norms: {
      type: Object
    },
    simple: {
      type: Boolean
    },
    unit: {
      type: String,
      required: true
    },
    noRange: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    imperial: {
      type: Boolean
    },
    valuePrefix: {
      type: String,
      default: 'avg'
    }
  },
  setup(properties) {
    const value =
      properties.data.global[
        properties.imperial ? `imp_${properties.valuePrefix}` : properties.valuePrefix
      ] || 0;
    const status = properties.data.global.indicator;

    const missingNorms = computed(() => !properties.norms && !properties.noRange);

    const colors = reactive({
      neutralValue: '#B3BBCE',
      neutral: '#F2F2F2',
      goodRange: '#33CC30',
      warningRange: '#FFAA02',
      badRange: '#ED361E',
      noRange: '#003DD1'
    });

    // SYMMETRY CHART
    const radius = ref(40);
    const strokeWidth = computed(() => {
      return radius.value * 0.12;
    });
    const innerRadius = computed(() => {
      return radius.value - strokeWidth.value;
    });
    const circumference = computed(() => {
      return innerRadius.value * 2 * Math.PI;
    });
    const arc = computed(() => {
      return circumference.value * (270 / 360);
    });
    const dashArray = computed(() => {
      return `${arc.value} ${circumference.value}`;
    });
    const offset = computed(() => {
      if (properties.noRange) return 0;
      return arc.value - (value / 100) * arc.value;
    });
    const transform = computed(() => {
      return `rotate(135, ${radius.value}, ${radius.value})`;
    });
    const valueColor = computed(() => {
      if (!properties.valid) return colors.neutral;
      if (properties.noRange) return colors.noRange;
      switch (status) {
        case 'warning':
          return colors.warningRange;
        case 'bad':
          return colors.badRange;
        case 'good':
          return colors.goodRange;
        default:
          return colors.neutral;
      }
    });

    return {
      // Values
      value,
      missingNorms,
      radius,
      strokeWidth,
      innerRadius,
      colors,
      dashArray,
      offset,
      transform,
      valueColor
    };
  }
});
