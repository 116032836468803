import { render, staticRenderFns } from "./PatientMedias.vue?vue&type=template&id=28efcf50&scoped=true&"
import script from "./PatientMedias.vue?vue&type=script&lang=ts&"
export * from "./PatientMedias.vue?vue&type=script&lang=ts&"
import style0 from "./PatientMedias.vue?vue&type=style&index=0&id=28efcf50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28efcf50",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_a266eb3209234f15fc02fe57f54d2cc5/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardText,VCarousel,VCarouselItem,VCheckbox,VHover,VImg,VProgressCircular,VProgressLinear,VTextField})
