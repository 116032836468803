import axios from 'axios';
import i18n from '@/plugins/i18n';
import { getCountries } from '@/utils/i18n.utils';
import { AnalysisType } from '@/plugins/podocore/repositories/analysis.repository';
import { AbilityFeature } from '@digitsole/blackburn-entities/dist/entities/ability/types/ability.enums';

interface EnumItem<E> {
  text: keyof E;
  value: E;
}

export function downloadPdf(url: string, fileName: string) {
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob'
  })
    .then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(fileLink);

      fileLink.click();
    })
    .catch((error: any) => {
      console.log('ERROR', error);
    });
}

export function enumToArray<E>(enumeration: E): EnumItem<E>[] {
  return Object.entries(enumeration).map((entry) => ({
    text: entry[0] as keyof E,
    value: entry[1] as unknown as E
  }));
}

export function enumToArrayAndTranslate<E>(
  enumeration: E,
  { key, plural, payload }: { key: string; plural?: boolean; payload?: any }
) {
  const _enumArray = enumToArray(enumeration);

  return _enumArray.map((entry: any) => ({
    ...entry,
    text: plural ? i18n.tc(`${key}.${entry.value}`, payload) : i18n.t(`${key}.${entry.value}`, payload)
  }));
}

export function getCountryByCode(code: string) {
  const countries = getCountries();
  const country = countries.find((c) => c.alpha2 === code);
  return country?.name ?? code;
}

export function isValidAnalysis(analysis: any | null): boolean {
  if (analysis) {
    if (analysis.analysisType === AnalysisType.running) {
      const validSegments = analysis.computedData.segments.filter(
        (x: any) =>
          x.left?.aggregates?.stanceTime?.avg !== 0 &&
          x.right?.aggregates?.stanceTime?.avg !== 0 &&
          analysis.computedData.left?.aggregates?.proportionSwingTime
      );
      return !!validSegments.length;
    }

    return analysis.computedData?.valid !== 0;
  }
  return false;
}

export function isDegradedAnalysis(analysis?: any | null): boolean {
  if (analysis?._scenario.key.startsWith('walking')) return analysis.analysis_validity === 1;
  return false;
}

/**
 * Determines if the given abilities object contains only the Orthotics feature.
 * @param abilities - An object containing boolean values for each ability feature.
 * @returns A boolean indicating whether the abilities object contains only the Orthotics feature.
 */
export function getIsOnlyOrthotics(abilities: Record<AbilityFeature, boolean>): boolean {
  return abilities[AbilityFeature.Orthotics] && Object.values(abilities).filter((x) => x).length === 1;
}
