








// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, toRefs } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils - ----------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
// Import types ------------------------------------------------------------------------------------
import type { TDataStandard } from '@/components/patient/PatientEvolution.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionAttackAngle',
  components: {
    ChartEvolutionContainer
  },
  props: {
    manifest: {
      type: Array,
      required: true
    },
    attackAngle: {
      type: Object
    },
    norms: {
      type: Object
    },
    exportation: {
      type: Boolean,
      default: false
    }
  },
  setup(properties) {
    const {
      evolutionChartStructure,
      getSideData,
      getSideDataVariations,
      getSerieItemStructure,
      getNormsStructure
    } = useChart();

    const { attackAngle } = toRefs(properties);

    const title = 'commons.standards.attack-angle';

    const formatedData = computed(() => {
      let data = attackAngle!.value;
      return {
        ...data,
        left_foot: {
          ...data!.left_foot,
          avg: data!.left_foot.avg.map((x: number | null) => (x ? x * -1 : x))
        },
        right_foot: {
          ...data!.right_foot,
          avg: data!.right_foot.avg.map((x: number | null) => (x ? x * -1 : x))
        }
      };
    });

    const _attackAngle: TDataStandard = computed(() => getSideData(formatedData.value));

    const dataVariations = computed(() =>
      getSideDataVariations({
        left: _attackAngle.value?.left.values,
        right: _attackAngle.value?.right.values
      })
    );

    const valid = computed(() => !!_attackAngle.value);

    const series = computed(() => {
      let data = [
        // Left
        getSerieItemStructure(
          'attack_angle_left',
          `${i18n.t('commons.standards.attack-angle')} (${(i18n.t('commons.standards.left') as string).slice(
            0,
            1
          )})`,
          _attackAngle.value?.left.values,
          EThemeColor.Chart1
        ),
        getSerieItemStructure(
          'attack_angle_left',
          undefined,
          _attackAngle.value?.left.ranges,
          EThemeColor.Chart1,
          true
        ),
        // Right
        getSerieItemStructure(
          'attack_angle_right',
          `${i18n.t('commons.standards.attack-angle')} (${(i18n.t('commons.standards.right') as string).slice(
            0,
            1
          )})`,
          _attackAngle.value?.right.values,
          EThemeColor.Chart2
        ),
        getSerieItemStructure(
          'attack_angle_right',
          undefined,
          _attackAngle.value?.right.ranges,
          EThemeColor.Chart2,
          true
        )
      ];
      // Have norms
      if (properties.norms) {
        const norms = {
          min: properties.norms!.min.map((x: number) => x * -1),
          max: properties.norms!.max.map((x: number) => x * -1)
        };
        data.push(getNormsStructure('attack_angle_norms', norms as { min: number[]; max: number[] }));
      }

      return data;
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(title, properties.manifest, EUnitKey.Degree, 1, {
          exportation: properties.exportation
        }),
        series: series.value
      };
    });

    return {
      title,
      dataVariations,
      chartOptions,
      valid
    };
  }
});
