











// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, Ref } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// Import helpers ----------------------------------------------------------------------------------
import { staticValues, TFootSizes } from '@/helpers/static-values.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientShoesSizeCard',
  setup() {
    const { data: patient } = usePatient();

    const { footSizesStandards } = staticValues();

    const patientFootSize: Ref<TFootSizes> = computed(
      () =>
        footSizesStandards.find((size: TFootSizes) => size.value === (patient.value as any).infos.footSize) ??
        footSizesStandards[0]
    );
    const patientUSFootSize = computed(
      () => patientFootSize.value.us[(patient.value as any).infos.gender as 1 | 2]
    );

    return {
      // Values
      patientFootSize,
      patientUSFootSize
    };
  }
});
