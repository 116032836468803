

















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, watch, toRefs, computed } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameComments',
  props: {
    analysisComments: {
      type: Array,
      required: false
    },
    height: {
      type: Number,
      required: false,
      default: 300
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  setup(properties) {
    const { analysisComments } = toRefs(properties);
    const comments = ref('');
    const actions = ref('');

    const _analysisComments: any = computed(() => analysisComments?.value);
    const fillComments = ref(false);

    function breakLine(value: string) {
      return value.replace(/(\r\n|\n)/g, '<br/>');
    }

    watch(_analysisComments, (value) => {
      if (value.length) {
        fillComments.value = true;
        _analysisComments.value!.forEach((value: any) => {
          if (comments.value === '') {
            comments.value = value.content;
          } else {
            comments.value += '\n' + value.content;
          }
        });
        fillComments.value = false;
      }
    });

    return {
      breakLine,
      comments,
      actions
    };
  }
});
