








































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, reactive } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionStanceTime from '@/components/charts/evolution/ChartEvolutionStanceTime.vue';
import ChartEvolutionSwingTime from '@/components/charts/evolution/ChartEvolutionSwingTime.vue';
// import ChartEvolutionPropulsionSpeed from '@/components/charts/evolution/ChartEvolutionPropulsionSpeed.vue';
import ChartEvolutionStrideLength from '@/components/charts/evolution/ChartEvolutionStrideLength.vue';
import ChartEvolutionSpeed from '@/components/charts/evolution/ChartEvolutionSpeed.vue';
import ChartEvolutionCadence from '@/components/charts/evolution/ChartEvolutionCadence.vue';
import ChartEvolutionHeelStrike from '@/components/charts/evolution/ChartEvolutionHeelStrike.vue';
import ChartEvolutionToesOff from '@/components/charts/evolution/ChartEvolutionToesOff.vue';
import ChartEvolutionFlatFoot from '@/components/charts/evolution/ChartEvolutionFlatFoot.vue';
import ChartEvolutionAttackAngle from '@/components/charts/evolution/ChartEvolutionAttackAngle.vue';
import ChartEvolutionImpactForce from '@/components/charts/evolution/ChartEvolutionImpactForce.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionGroupsRunning',
  components: {
    ChartEvolutionStanceTime,
    ChartEvolutionSwingTime,
    // ChartEvolutionPropulsionSpeed,
    ChartEvolutionStrideLength,
    ChartEvolutionSpeed,
    ChartEvolutionCadence,
    ChartEvolutionHeelStrike,
    ChartEvolutionToesOff,
    ChartEvolutionFlatFoot,
    ChartEvolutionAttackAngle,
    ChartEvolutionImpactForce
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    relative: {
      type: Boolean,
      required: true
    }
  },
  setup(properties) {
    const aggregates = computed(() => properties.values.data.aggregates);
    const norms = computed(() => properties.values.data.norms);
    const manifest = computed(() => properties.values.analysisManifests);

    const modes = reactive({
      standard: true,
      advanced: false
    });

    return {
      aggregates,
      norms,
      manifest,
      modes
    };
  }
});
