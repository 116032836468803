









































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartStandardScoringSections',
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    interpretations: {
      type: Object
    },
    small: {
      type: Boolean
    }
  },
  setup(properties) {
    const value = 50 || 0;
    const status: any = 'good';

    const missingNorms = computed(() => !properties.norms);

    const colors = reactive({
      neutralValue: '#B3BBCE',
      neutral: '#F2F2F2',
      goodRange: '#33CC30',
      warningRange: '#FFAA02',
      badRange: '#ED361E',
      noRange: '#003DD1'
    });

    // SYMMETRY CHART
    const radius = ref(125);
    const strokeWidth = computed(() => {
      return radius.value * 0.07;
    });
    const strokeGVIWidth = computed(() => {
      return radius.value * 0.1;
    });
    const strokeGVIBorderWidth = computed(() => {
      return radius.value * 0.14;
    });
    const innerRadius = computed(() => {
      return radius.value - strokeWidth.value;
    });
    const circumference = computed(() => {
      return innerRadius.value * 2 * Math.PI;
    });
    const arc = computed(() => {
      return circumference.value * (180 / 360);
    });
    const dashArray = computed(() => {
      return `${arc.value} ${circumference.value}`;
    });
    const transform = computed(() => {
      return `rotate(180, ${radius.value}, ${radius.value})`;
    });
    const valueColor = computed(() => {
      switch (status) {
        case 'warning':
          return colors.warningRange;
        case 'bad':
          return colors.badRange;
        case 'good':
          return colors.goodRange;
        default:
          return colors.neutral;
      }
    });

    const gaugesRatio = computed(() => {
      return {
        veryLow: getRatio(properties.norms.walking_gait_variability_index.bounds.very_low.min),
        low: getRatio(properties.norms.walking_gait_variability_index.bounds.low.min),
        neutralMin: getRatio(properties.norms.walking_gait_variability_index.bounds.neutral.min),
        neutralMax: getRatio(properties.norms.walking_gait_variability_index.bounds.neutral.max),
        high: getRatio(properties.norms.walking_gait_variability_index.bounds.high.max),
        veryHigh: getRatio(properties.norms.walking_gait_variability_index.bounds.very_high.max)
      };
    });

    const gaugesPositions = computed(() => {
      return {
        veryLow: getGaugePosition(gaugesRatio.value.veryLow, gaugesRatio.value.low),
        low: getGaugePosition(gaugesRatio.value.low, gaugesRatio.value.neutralMin),
        neutral: getGaugePosition(gaugesRatio.value.neutralMin, gaugesRatio.value.neutralMax),
        high: getGaugePosition(gaugesRatio.value.neutralMax, gaugesRatio.value.high),
        veryHigh: getGaugePosition(gaugesRatio.value.high, gaugesRatio.value.veryHigh)
      };
    });

    const GVIRatio = computed(() => {
      return {
        left: getRatio(properties.aggregates.walking_gait_variability_index.left_foot.avg),
        right: getRatio(properties.aggregates.walking_gait_variability_index.right_foot.avg)
      };
    });
    const GVIPositions = computed(() => {
      return {
        left: getGaugePosition(GVIRatio.value.left, GVIRatio.value.left),
        right: getGaugePosition(GVIRatio.value.right, GVIRatio.value.right)
      };
    });

    const displayedSections = computed(() => {
      return {
        veryLow: getDisplayedStatus('veryLow'),
        low: getDisplayedStatus('low'),
        neutral: getDisplayedStatus('neutral'),
        high: getDisplayedStatus('high'),
        veryHigh: getDisplayedStatus('veryHigh')
      };
    });

    function getDisplayedStatus(section: string) {
      if (
        section === 'veryLow' &&
        (GVIRatio.value.left < gaugesRatio.value.low || GVIRatio.value.right < gaugesRatio.value.low)
      )
        return true;
      if (
        section === 'low' &&
        ((GVIRatio.value.left < gaugesRatio.value.neutralMin &&
          GVIRatio.value.left >= gaugesRatio.value.low) ||
          (GVIRatio.value.right < gaugesRatio.value.neutralMin &&
            GVIRatio.value.right >= gaugesRatio.value.low))
      )
        return true;
      if (
        section === 'neutral' &&
        ((GVIRatio.value.left >= gaugesRatio.value.neutralMin &&
          GVIRatio.value.left <= gaugesRatio.value.neutralMax) ||
          (GVIRatio.value.right >= gaugesRatio.value.neutralMin &&
            GVIRatio.value.right <= gaugesRatio.value.neutralMax))
      )
        return true;
      if (
        section === 'high' &&
        ((GVIRatio.value.left > gaugesRatio.value.neutralMax &&
          GVIRatio.value.left <= gaugesRatio.value.high) ||
          (GVIRatio.value.right > gaugesRatio.value.neutralMax &&
            GVIRatio.value.right <= gaugesRatio.value.high))
      )
        return true;
      if (
        section === 'veryHigh' &&
        (GVIRatio.value.left > gaugesRatio.value.high || GVIRatio.value.right > gaugesRatio.value.high)
      )
        return true;

      return false;
    }
    function getRatio(value: number) {
      const min: number = properties.norms.walking_gait_variability_index.bounds.very_low.min;
      const max: number = properties.norms.walking_gait_variability_index.bounds.very_high.max;
      return ((value - min) * 100) / (max - min);
    }
    function getGaugePosition(min: number, max: number) {
      const totalPercentage = max - min;
      const offset = 360 - 360 * (totalPercentage / 100);
      const rotation = 180 + 180 * (min / 100);

      return {
        offset,
        rotation
      };
    }

    return {
      displayedSections,
      gaugesPositions,
      GVIPositions,
      // Values
      value,
      missingNorms,
      radius,
      strokeWidth,
      strokeGVIWidth,
      strokeGVIBorderWidth,
      innerRadius,
      colors,
      dashArray,
      transform,
      valueColor
    };
  }
});
