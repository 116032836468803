












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartWalkingClearanceSteppage from '@/components/charts/walking/ChartWalkingClearanceSteppage.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartClearanceSteppage',
  components: {
    ChartExportationContainer,
    ChartWalkingClearanceSteppage
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.walking_plantar_flexion_angle_foot_in);
    const simplified = computed(
      () =>
        properties.displayMetrics.walking_plantar_flexion_angle_foot_in &&
        !properties.displayMetrics.walking_plantar_flexion_angle_foot_out
    );

    const normsType = computed(() => {
      return {
        base: [
          properties.norms.walking_minimum_toe_clearance.bounds.neutral.min,
          properties.norms.walking_minimum_toe_clearance.bounds.neutral.max
        ]
      };
    });
    const clearanceUnit = computed(() => getUnit(EValueTypeUnit.Clearance));

    return {
      // Valid
      valid,
      simplified,
      normsType,
      clearanceUnit
    };
  }
});
