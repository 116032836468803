









// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientGenderCard',
  setup() {
    const { data: patient } = usePatient();

    const gender = computed(() =>
      patient.value?.infos.gender.toString() === '1' ? 'commons.standards.male' : 'commons.standards.female'
    );

    return {
      // Values
      gender
    };
  }
});
