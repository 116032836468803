import type { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import type { Doctor } from '@/plugins/podocore/repositories/doctor.repository';
import type { Patient } from '@/plugins/podocore/repositories/patients.repository';

export type { AnalysisEntityCompleted } from '@digitsole/blackburn-entities/dist/entities/analysis/analysis-completed.entity';

export enum AnalysisType {
  walking = 'walking',
  jumping = 'jumping',
  running = 'running'
}

export enum ConditionsPainLocations {
  Hip = 'hip',
  Thigh = 'thigh',
  Knee = 'knee',
  Leg = 'leg',
  Ankle = 'ankle',
  Foot = 'foot'
}

// -------------------------------------------------------------------------------------------------

export enum ConditionsPainSide {
  Left = 'left',
  Right = 'right'
}

export enum ConditionsPainZone_1 {
  Medial = 'medial',
  Side = 'side',
  Both = 'both',
  NotConcerned = 'not-concerned'
}

export enum ConditionsPainZone_2_C1 {
  Anterior = 'anterior',
  Posterior = 'posterior',
  Both = 'both',
  NotConcerned = 'not-concerned'
}

export enum ConditionsPainZone_2_C2 {
  Plantar = 'plantar',
  Dorsal = 'dorsal',
  Both = 'both',
  NotConcerned = 'not-concerned'
}

export type ConditionsPainLocations_c1 = Exclude<ConditionsPainLocations, ConditionsPainLocations.Foot>;

export type ConditionsPainLocations_c2 = ConditionsPainLocations.Foot;

export type ConditionsPain_f1 = {
  side: ConditionsPainSide;
  zone1: ConditionsPainZone_1;
  level: number;
};

export type ConditionsPain =
  | (ConditionsPain_f1 & {
      location: ConditionsPainLocations_c1;
      zone2: ConditionsPainZone_2_C1;
    })
  | (ConditionsPain_f1 & {
      location: ConditionsPainLocations_c2;
      zone2: ConditionsPainZone_2_C2;
    });

// -------------------------------------------------------------------------------------------------

export enum ConditionsShoesTypeRigidity {
  Low = 'low',
  Moderate = 'moderate',
  Important = 'important'
}

export enum ConditionsShoesTypeCushioning {
  Low = 'low',
  Moderate = 'moderate',
  Important = 'important'
}

export enum ConditionsShoesTypeWear {
  PosteroExternal = 'postero-external',
  PosteroMedian = 'postero-median',
  PosteroInternal = 'postero-internal',
  AnteroExternal = 'antero-external',
  AnteroMedian = 'antero-median',
  AnteroInternal = 'antero-internal'
}

export type ConditionsShoesType = {
  rigidity: ConditionsShoesTypeRigidity;
  cushioning: ConditionsShoesTypeCushioning;
  wear: ConditionsShoesTypeWear;
};

// -------------------------------------------------------------------------------------------------

export enum ConditionsWalkingAidDevices {
  SimpleCane = 'simple-cane',
  EnglishCane = 'english-cane',
  EnglishCane2 = 'english-cane-double',
  Rollator = 'rollator',
  Walker = 'walker',
  LiftAndOrthopaedicShoes = 'lift-and-orthopaedic-shoes',
  Other = 'other'
}

// -------------------------------------------------------------------------------------------------

export enum AnalysisMode {
  standard = 'standard'
}

export interface AnalysisSimplified {
  doctorCuid: Entity<Doctor>['cuid'];
  patientCuid: Entity<Patient>['cuid'];
  start: Date;
  analysisType: AnalysisType;
}

export type StepConditions = {
  pains: ConditionsPain[];
  shoesType: ConditionsShoesType | null;
  walkingAids: ConditionsWalkingAidDevices[];
};
