


















































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingWalkProfile',
  components: {
    ChartExportationContainer
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();

    const valid = computed(
      () =>
        properties.displayMetrics.walking_stride_length &&
        properties.displayMetrics.walking_proportion_stance_time &&
        properties.displayMetrics.walking_proportion_swing_time &&
        properties.displayMetrics.walking_proportion_double_support_time
    );

    const valueType = computed(() => (isImperial.value ? 'imp_avg' : 'avg'));

    const strideLengthUnit = computed(() => getUnit(EValueTypeUnit.StrideLength));
    return { valid, valueType, strideLengthUnit };
  }
});
