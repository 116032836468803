












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartWalkingWidgetSymmetry from '@/components/charts/walking/widgets/ChartWalkingWidgetSymmetry.vue';
import ChartWalkingWidgetSpeed from '@/components/charts/walking/widgets/ChartWalkingWidgetSpeed.vue';
import ChartWalkingWidgetCadence from '@/components/charts/walking/widgets/ChartWalkingWidgetCadence.vue';
import ChartWalkingWidgetScoringSQI from '@/components/charts/walking/widgets/ChartWalkingWidgetScoringSQI.vue';
import ChartWalkingWidgetScoringGVI from '@/components/charts/walking/widgets/ChartWalkingWidgetScoringGVI.vue';
// Import plugins ----------------------------------------------------------------------------------
import { useLocalStorage } from '@vueuse/core';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerGroupBanner',
  components: {
    ChartWalkingWidgetSymmetry,
    ChartWalkingWidgetSpeed,
    ChartWalkingWidgetCadence,
    ChartWalkingWidgetScoringSQI,
    ChartWalkingWidgetScoringGVI
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    recommendations: {
      type: Object,
      required: false
    },
    degraded: {
      type: Boolean
    },
    simple: {
      type: Boolean
    }
  },
  setup(properties) {
    const showScoringWidget = useLocalStorage('showScoringWidget', null);

    const haveScoring = computed(
      () =>
        properties.displayMetrics.value.aggregates.walking_gait_variability_index &&
        properties.displayMetrics.value.aggregates.walking_SQI
    );
    return {
      haveScoring,
      showScoringWidget
    };
  }
});
