






// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
import moment from 'moment';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningSpeedCadence',
  props: {
    segments: {
      type: Object,
      required: true
    },
    imperial: {
      type: Boolean
    },
    hideTooltips: {
      type: Boolean
    },
    height: {
      type: Number,
      default: 400
    }
  },
  setup(properties) {
    const speedUnit = computed(() => getUnit(EValueTypeUnit.Speed));

    const cadence = computed(() => {
      const data: any = [];
      properties.segments.running_step_cadence.global.avg.forEach((item: any, index: number) => {
        data.push([properties.segments.segment_duration.global.sum[index] * 1000, item || 0]);
      });

      return data;
    });

    const speed = computed(() => {
      const data: any = [];
      const running_speed =
        properties.segments.running_speed.global[properties.imperial ? 'imp_avg' : 'avg'] || [];
      running_speed.forEach((item: any, index: number) => {
        data.push([properties.segments.segment_duration.global.sum[index] * 1000, item || 0]);
      });
      return data;
    });

    const series = computed(() => {
      return [
        {
          id: 'Speed',
          name: i18n.t('commons.standards.speed'),
          data: speed.value,
          type: 'areaspline',
          color: '#FF804C',
          shadow: false,
          yAxis: 0,
          lineWidth: 3,
          marker: {
            symbol: 'circle',
            lineWidth: 2
          },
          zIndex: 2
        },
        {
          id: 'Cadence',
          name: i18n.t('commons.standards.cadence'),
          data: cadence.value,
          type: 'areaspline',
          color: 'var(--v-primary-base)',
          shadow: false,
          yAxis: 1,
          lineWidth: 3,
          marker: {
            symbol: 'circle',
            lineWidth: 2
          }
        }
      ];
    });

    let chartOptions = computed(() => {
      return {
        chart: {
          animation: !properties.hideTooltips,
          height: properties.height
        },
        title: {
          text: i18n.t('commons.standards.cadence') + ' / ' + i18n.t('commons.standards.speed'),
          style: {
            fontSize: '0'
          }
        },
        xAxis: {
          labels: {
            formatter(): any {
              // @ts-ignore
              return moment.utc(this.value).format('mm:ss');
            }
          },
          crosshair: {
            width: 60,
            color: 'rgba(206,214,217,0.5)'
          }
        },
        yAxis: [
          {
            labels: {
              format: `{value}`
            },
            title: {
              text: `${i18n.t('commons.standards.speed')} (${speedUnit.value})`
            },
            min: 0,
            // @ts-ignore
            maxZoom: 20
          },
          {
            opposite: true,
            labels: {
              // @ts-ignore
              align: 'left',
              format: `{value}`
            },
            title: {
              text: `${i18n.t('commons.standards.cadence')} (${i18n.t('commons.units.steps-min')})`,
              margin: 20
            },
            // @ts-ignore
            maxZoom: 100
          }
        ],
        plotOptions: {
          areaspline: {
            lineWidth: 3,
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, 'rgba(186,204,215,0)'],
                [1, 'rgba(255,255,255,0)']
              ]
            }
          },
          series: {
            enableMouseTracking: !properties.hideTooltips,
            animation: !properties.hideTooltips
          }
        },
        legend: {
          useHTML: true,
          layout: 'horizontal',
          squareSymbol: false,
          symbolHeight: 4,
          symbolWidth: 16,
          symbolRadius: 2,
          symbolPadding: 10,
          itemStyle: {
            lineHeight: '20px',
            color: '#6e768d',
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontWeight: '700'
          },
          align: 'center',
          verticalAlign: 'bottom',
          floating: false,
          x: 0,
          y: 0
        },
        tooltip: {
          enabled: !properties.hideTooltips,
          distance: 50,
          crosshairs: true,
          shared: true,
          followPointer: true,
          formatter() {
            const that: any = this;
            let s = `<span style="font-size: 15px;">${moment(
              properties.segments.segment_duration.global.sum[that.points[0].point.index] * 1000
            ).format('mm:ss')} min</span>`;
            s += `<br/><span>-</span><br/>`;
            for (const p of that.points) {
              const isCadence = p.series.name === i18n.t('commons.standards.cadence');
              s += `<span style="font-size: 15px; font-weight: bold; color: ${p.color}">${
                p.series.name
              }</span><span style="font-size: 15px; font-weight: 500; color: #020C27;"> : ${p.point.y} ${
                isCadence ? i18n.t('commons.units.steps-min') : speedUnit.value
              }</span>`;
              s += `<br/>`;
            }

            return s;
          }
        },
        series: series.value
      };
    });

    return {
      chartOptions
    };
  }
});
