























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartWalkingCircumduction from '@/components/charts/walking/ChartWalkingCircumduction.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// Import types ------------------------------------------------------------------------------------
import { EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartCircumduction',
  components: {
    ChartExportationContainer,
    ChartWalkingCircumduction
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();

    const valid = computed(() => properties.displayMetrics.circumduction);
    const unit = getUnit(EValueTypeUnit.Circumduction);

    return {
      // Valid
      valid,
      // Values
      isImperial,
      unit
    };
  }
});
