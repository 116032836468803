// Import vendors ----------------------------------------------------------------------------------
import { Ref, inject } from '@vue/composition-api';
// Import type -------------------------------------------------------------------------------------
import { AnalysisEntityConditions } from '@digitsole/blackburn-entities/dist/entities/analysis/condition/analysis-condition.entity';
import { AnalysisEntityCompleted } from '@digitsole/blackburn-entities/dist/entities/analysis/analysis-completed.entity';
import { DeepWriteable } from '@/types/utils';
// -------------------------------------------------------------------------------------------------

type TAnalysis = DeepWriteable<AnalysisEntityCompleted>;

type TReferenceAnalysis = Ref<TAnalysis>;
type TResponse = {
  singleAnalysis: TReferenceAnalysis;
  setSingleAnalysis: (analysis: TAnalysis) => void;
  setSingleAnalysisConditions: (conditions: AnalysisEntityConditions) => void;
};

export function useSingleAnalysis(): TResponse {
  const singleAnalysis = inject('single-analysis') as TReferenceAnalysis;

  const setSingleAnalysis = (analysis: TAnalysis) => {
    singleAnalysis.value = analysis;
  };

  const setSingleAnalysisConditions = (conditions: AnalysisEntityConditions) => {
    singleAnalysis.value.conditions = conditions;
  };

  return {
    singleAnalysis,
    setSingleAnalysis,
    setSingleAnalysisConditions
  };
}
