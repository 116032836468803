












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { rounded } from '@/helpers/conversion.helper';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientWeightCard',
  setup() {
    const { data: patient } = usePatient();
    const currentUnit = useUnit().currentUnit();
    return {
      // Values
      patient,
      currentUnit,
      rounded
    };
  }
});
