













// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartStandardGaugeSlider from '@/components/charts/standard/ChartStandardGaugeSlider.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerSymmetry',
  components: {
    ChartContainer,
    ChartStandardGaugeSlider
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.aggregates?.walking_symmetry);

    return {
      valid
    };
  }
});
