












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils - ----------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
import { useUnit } from '@/utils/unit.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
// Import types ------------------------------------------------------------------------------------
import type { TDataStandard } from '@/components/patient/PatientEvolution.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionClearance',
  components: {
    ChartEvolutionContainer
  },
  props: {
    manifest: {
      type: Array,
      required: true
    },
    clearance: {
      type: Object
    },
    norms: {
      type: Object
    },
    exportation: {
      type: Boolean
    }
  },
  setup(properties) {
    const {
      evolutionChartStructure,
      getSideData,
      getSideDataVariations,
      getSerieItemStructure,
      getNormsStructure
    } = useChart();
    const { isImperial } = useUnit().currentUnit();

    const title = 'commons.standards.clearance';

    const normsType = computed(() => {
      return {
        base: [properties.norms?.min[0], properties.norms?.max[0]]
      };
    });

    const clearance: TDataStandard = computed(() => getSideData(properties.clearance));

    const dataVariations = computed(() =>
      getSideDataVariations({ left: clearance.value?.left.values, right: clearance.value?.right.values })
    );

    const valid = computed(() => !!clearance.value);

    const series = computed(() => {
      let data = [
        // Left
        getSerieItemStructure(
          'clearance_left',
          `${i18n.t('commons.standards.clearance')} (${(i18n.t('commons.standards.left') as string).slice(
            0,
            1
          )})`,
          clearance.value?.left.values,
          EThemeColor.Chart1
        ),
        getSerieItemStructure(
          'clearance_left',
          undefined,
          clearance.value?.left.ranges,
          EThemeColor.Chart1,
          true
        ),
        // Right
        getSerieItemStructure(
          'clearance_right',
          `${i18n.t('commons.standards.clearance')} (${(i18n.t('commons.standards.right') as string).slice(
            0,
            1
          )})`,
          clearance.value?.right.values,
          EThemeColor.Chart2
        ),
        getSerieItemStructure(
          'clearance_right',
          undefined,
          clearance.value?.right.ranges,
          EThemeColor.Chart2,
          true
        )
      ];
      // Have norms
      if (properties.norms)
        data.push(getNormsStructure('clearance_norms', properties.norms as { min: number[]; max: number[] }));

      return data;
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(
          title,
          properties.manifest,
          isImperial.value ? EUnitKey.Inch : EUnitKey.Centimeter,
          2,
          { exportation: properties.exportation }
        ),
        series: series.value
      };
    });

    return {
      title,
      dataVariations,
      chartOptions,
      normsType,
      valid
    };
  }
});
