












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartStandardPronationSupinationAngles from '@/components/charts/standard/ChartStandardPronationSupinationAngles.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingGroupStepsAngles',
  components: {
    ChartStandardPronationSupinationAngles
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    advanced: {
      type: Boolean
    }
  },
  setup(properties) {
    const angles = ref(
      properties.advanced
        ? [{ heelStrike: 'foot_in' }, { flatFoot: 'FFI' }, { heelOff: 'FFO' }, { toesOff: 'foot_out' }]
        : [{ heelStrike: 'foot_in' }, { toesOff: 'foot_out' }]
    );
    return { angles };
  }
});
