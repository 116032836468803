












































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import ExportationFrameHeader from '@/components/exportation/frames/ExportationFrameHeader.vue';
import ExportationFrameComments from '@/components/exportation/frames/ExportationFrameComments.vue';
//
import ChartEvolutionStanceTime from '@/components/charts/evolution/ChartEvolutionStanceTime.vue';
import ChartEvolutionSwingTime from '@/components/charts/evolution/ChartEvolutionSwingTime.vue';
import ChartEvolutionStrideLength from '@/components/charts/evolution/ChartEvolutionStrideLength.vue';
import ChartEvolutionSpeed from '@/components/charts/evolution/ChartEvolutionSpeed.vue';
import ChartEvolutionCadence from '@/components/charts/evolution/ChartEvolutionCadence.vue';
import ChartEvolutionAttackAngle from '@/components/charts/evolution/ChartEvolutionAttackAngle.vue';
import ChartEvolutionImpactForce from '@/components/charts/evolution/ChartEvolutionImpactForce.vue';
import ChartEvolutionHeelStrike from '@/components/charts/evolution/ChartEvolutionHeelStrike.vue';
import ChartEvolutionFlatFoot from '@/components/charts/evolution/ChartEvolutionFlatFoot.vue';
import ChartEvolutionToesOff from '@/components/charts/evolution/ChartEvolutionToesOff.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameRunningEvolution',
  components: {
    ExportationFrameHeader,
    ExportationViewerPage,
    ExportationFrameComments,
    //
    ChartEvolutionStanceTime,
    ChartEvolutionSwingTime,
    ChartEvolutionStrideLength,
    ChartEvolutionSpeed,
    ChartEvolutionCadence,
    ChartEvolutionAttackAngle,
    ChartEvolutionImpactForce,
    ChartEvolutionHeelStrike,
    ChartEvolutionFlatFoot,
    ChartEvolutionToesOff
  },
  props: {
    analyses: {
      type: Object,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    },
    pdfData: {
      type: Object,
      required: true
    },
    headerInformations: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const aggregates = computed(() => properties.analyses.data.aggregates);
    const norms = computed(() => properties.analyses.data.norms);
    const manifest = computed(() => properties.analyses.analysisManifests);

    return {
      aggregates,
      norms,
      manifest
    };
  }
});
