var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exportation__viewer"},[_c('export-frame',_vm._b({ref:"rIframe"},'export-frame',{ patient: _vm.patient },false),[(_vm.scenario.startsWith('walking'))?[(_vm.filter == 'default')?_c('ExportationFrameWalking',_vm._b({},'ExportationFrameWalking',{
          analysis: _vm.analysis,
          advancedMode: _vm.advancedMode,
          pageTotal: _vm.pageTotal,
          pdfData: _vm.pdfData,
          headerInformations: _vm.headerInformations,
          displayTooltips: _vm.displayTooltips
        },false)):_vm._e(),(_vm.filter == 'neurological')?_c('ExportationFrameWalkingNeurological',_vm._b({},'ExportationFrameWalkingNeurological',{ analysis: _vm.analysis, advancedMode: _vm.advancedMode, pageTotal: _vm.pageTotal, pdfData: _vm.pdfData, headerInformations: _vm.headerInformations, displayTooltips: _vm.displayTooltips },false)):_vm._e(),(_vm.filter == 'traumatology')?_c('ExportationFrameWalkingTraumatology',_vm._b({},'ExportationFrameWalkingTraumatology',{ analysis: _vm.analysis, advancedMode: _vm.advancedMode, pageTotal: _vm.pageTotal, pdfData: _vm.pdfData, headerInformations: _vm.headerInformations, displayTooltips: _vm.displayTooltips },false)):_vm._e(),(_vm.filter == 're-athleticism')?_c('ExportationFrameWalkingReathleticism',_vm._b({},'ExportationFrameWalkingReathleticism',{ analysis: _vm.analysis, advancedMode: _vm.advancedMode, pageTotal: _vm.pageTotal, pdfData: _vm.pdfData, headerInformations: _vm.headerInformations, displayTooltips: _vm.displayTooltips },false)):_vm._e(),(_vm.customFilter)?_c('ExportationFrameWalkingCustom',_vm._b({},'ExportationFrameWalkingCustom',{ analysis: _vm.analysis, customFilter: _vm.customFilter, pageTotal: _vm.pageTotal, pdfData: _vm.pdfData, headerInformations: _vm.headerInformations, displayTooltips: _vm.displayTooltips },false)):_vm._e(),(_vm.hasOrthotics && _vm.pdfData.orthotics.value)?_c('ExportationFrameOrthotics',_vm._b({},'ExportationFrameOrthotics',{ analysis: _vm.analysis, pageTotal: _vm.pageTotal, pdfData: _vm.pdfData, headerInformations: _vm.headerInformations, hasOnlyOrthotics: _vm.hasOnlyOrthotics, scenario: _vm.scenario },false)):_vm._e()]:_vm._e(),(_vm.scenario.startsWith('running'))?[_c('ExportationFrameRunning',_vm._b({},'ExportationFrameRunning',{
          analysis: _vm.analysis,
          advancedMode: _vm.advancedMode,
          pageTotal: _vm.pageTotal,
          pdfData: _vm.pdfData,
          headerInformations: _vm.headerInformations,
          detailledRunningMode: _vm.detailledRunningMode,
          showDetails: _vm.showDetails,
          exportSelectedSegments: _vm.exportSelectedSegments,
          displayTooltips: _vm.displayTooltips
        },false)),(_vm.hasOrthotics && _vm.pdfData.orthotics.value)?_c('ExportationFrameOrthotics',_vm._b({},'ExportationFrameOrthotics',{ analysis: _vm.analysis, pageTotal: _vm.pageTotal, pdfData: _vm.pdfData, headerInformations: _vm.headerInformations, hasOnlyOrthotics: _vm.hasOnlyOrthotics, scenario: _vm.scenario },false)):_vm._e()]:_vm._e(),(_vm.scenario.startsWith('jumping'))?_c('ExportationFrameJumping',_vm._b({},'ExportationFrameJumping',{ analysis: _vm.analysis, advancedMode: _vm.advancedMode, pageTotal: _vm.pageTotal, pdfData: _vm.pdfData, headerInformations: _vm.headerInformations, displayTooltips: _vm.displayTooltips },false)):_vm._e()],2),(_vm.pending)?_c('VOverlay',{attrs:{"absolute":"","opacity":"0.8"}},[_c('VProgressCircular',{attrs:{"color":"white","indeterminate":"","size":"60"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }