





















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartRunningGroupStepsAngles from '@/components/charts/running/groups/ChartRunningGroupStepsAngles.vue';
import ChartRunningPronationSupinationAnglesAdvanced from '@/components/charts/running/ChartRunningPronationSupinationAnglesAdvanced.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameRunningChartPronationAngles',
  components: {
    ChartExportationContainer,
    ChartRunningGroupStepsAngles,
    ChartRunningPronationSupinationAnglesAdvanced
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    analysisDate: {
      type: String
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const advancedAngles = computed(
      () =>
        properties.displayMetrics.running_pronation_angle_foot_in &&
        properties.displayMetrics.running_pronation_angle_foot_out &&
        properties.displayMetrics.running_pronation_angle_mid
    );
    const simplifiedAngles = computed(
      () =>
        properties.displayMetrics.running_pronation_angle_foot_in &&
        properties.displayMetrics.running_pronation_angle_foot_out &&
        !properties.displayMetrics.running_pronation_angle_mid
    );
    const validAngles = computed(
      () =>
        properties.displayMetrics.running_pronation_angle_foot_in &&
        properties.displayMetrics.running_pronation_angle_foot_out
    );
    const validPhases = computed(
      () =>
        properties.displayMetrics.running_propulsion_time &&
        properties.displayMetrics.running_proportion_propulsion_time &&
        properties.displayMetrics.running_loading_time &&
        properties.displayMetrics.running_proportion_loading_time
    );

    return {
      // Valid
      advancedAngles,
      simplifiedAngles,
      validAngles,
      validPhases
    };
  }
});
