var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'page__patient simple-scrollbar',
    {
      'page__patient--loading': _vm.isPending,
      'page__patient--errored': _vm.error,
      'page__patient--mini-variant': _vm.patientSidebarMiniVariant
    }
  ]},[_c('Transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isPending)?_c('div',{key:"loading",staticClass:"d-flex align-center ma-4"},[_c('VProgressCircular',{attrs:{"indeterminate":"","color":"primary","width":"2","size":"16"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('commons.standards.loading'))+"...")])],1):(_vm.error)?_c('div',{key:"error",staticClass:"ma-4"},[_c('AlertError',{attrs:{"error":_vm.error,"hide-title":""}})],1):_c('div',{key:"content",staticClass:"bg-gray h-100 overflow-auto"},[_c('PatientNavbar',{attrs:{"isOnlyOrthotics":_vm.isOnlyOrthotics}}),_c('Transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isPatientFilePage)?_c('div',{class:("" + (_vm.hash === '#evolution' ? '' : 'px-15 py-6'))},[(_vm.hash === '#dashboard' || !_vm.hash)?_c('PatientDashboard'):(_vm.hash === '#patient-file')?_c('PatientFile'):(_vm.hash === '#practitioner-analysis')?_c('PractitionerAnalysis',{attrs:{"isOnlyOrthotics":_vm.isOnlyOrthotics}}):(_vm.hash === '#evolution')?_c('PatientEvolution'):(_vm.hash === '#medias')?_c('PatientMedias'):_vm._e()],1):_c('RouterView')],1),_c('DialogPatientUpdate'),_c('DialogAnalysisRename'),_c('DialogExportPDF'),_c('DialogAnalysisDelete')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }