import { useLocalStorage } from '@vueuse/core';

import type { Ref } from '@vue/composition-api';

const videoResolution = useLocalStorage('videoSource', 'hd');

export function useVideo(): {
  toggleSource(): void;
  videoResolution: Ref<string>;
} {
  function toggleSource() {
    videoResolution.value = videoResolution.value === 'hd' ? 'sd' : 'hd';
  }

  return {
    videoResolution,
    toggleSource
  };
}
