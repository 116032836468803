











































































































































































// Import vendors ----------------------------------------------------------------------------------
import {
  defineComponent,
  ref,
  computed,
  reactive,
  watch,
  onMounted,
  onBeforeUnmount
} from '@vue/composition-api';
import { merge } from 'lodash';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import helpers ----------------------------------------------------------------------------------
import { dateFormatRelatedToSelectedLanguage } from '@/helpers/basic-values.helper';
// Import utils ------------------------------------------------------------------------------------
import { useSidebar } from '@/utils/sidebar.utils';
import { usePatient } from '@/utils/patient.utils';
import { useRoute } from '@/utils/router.utils';
import { useFormFieldDate } from '@/utils/forms.utils';
import { useI18n } from '@/utils/i18n.utils';
import { useRehab } from '@/utils/rehab.utils';
import { getIconByScenario } from '@/utils/icon.utils';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import types ------------------------------------------------------------------------------------
import { EAnalysisName } from '@/components/patient/results/PatientResultsHeader.vue';
import type { TAnalysisNames } from '@/components/patient/results/PatientResultsHeader.vue';
import { AnalysisType } from '@/plugins/podocore/repositories/analysis.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PractitionerAnalysis',
  components: {
    AlertError
  },
  props: {
    isOnlyOrthotics: Boolean
  },
  setup(_, { root }) {
    const route = root.$route;
    const { dateFormat } = useI18n();
    const { getMode } = useRehab();

    const nameByAnalysisType: TAnalysisNames = reactive({
      [AnalysisType.walking]: EAnalysisName.Walking,
      [AnalysisType.running]: EAnalysisName.Running,
      [AnalysisType.jumping]: EAnalysisName.Jumping
    });

    const { params, query } = useRoute();

    const { data: patient } = usePatient();
    const { patientSidebarMiniVariant, toggleMiniVariant } = useSidebar();

    const busModule = usePodocoreModule('bus');

    const requestModule: any = usePodocoreModule('request');
    const analysisRequest = ref<any>(null);
    const analysisListRequest = ref<any>(null);

    const analysisList = reactive<any[]>([]);
    const analysisListHasNext = ref(false);

    const isPending = computed(() => analysisListRequest.value?.isPending);
    const error = computed(() => analysisListRequest.value?.error);

    const formattedDate = dateFormatRelatedToSelectedLanguage();

    function configureRequest(page?: number) {
      if (analysisListRequest.value?.cancel) analysisListRequest.value.cancel();

      analysisListRequest.value = requestModule.useAuthenticatedRequest(`${apiConfig.default}/analysis`, {
        axios: {
          params: {
            patientCuid: params.value.cuid,
            page,
            latestStatus: 'completed'
          }
        }
      });

      analysisListRequest.value.request();
    }

    function openAnalysis(cuid: string) {
      if (!root.$route?.params?.analysisCuid || root.$route?.params?.analysisCuid !== cuid)
        root.$router.push(`/patients/${patient.value?.cuid}/result/${cuid}`);
    }

    function openRenameAnalysis(cuid: string) {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-rename',
          meta: { analysisCuid: cuid }
        })
      );
    }

    function openComparison(cuid: string) {
      if (query.value?.firstAnalysis != cuid) {
        root.$router.push({
          name: 'patient-comparison',
          query: {
            firstAnalysis: cuid
          }
        });
      }
    }

    function openExport(analysis: any) {
      analysisRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/analysis/${analysis.cuid}`
      );
      analysisRequest.value.request();
    }

    function openDeleteAnalysis(analysis: any) {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-delete',
          meta: { analysisCuid: analysis.cuid }
        })
      );
    }

    function loadMore() {
      configureRequest(analysisListRequest.value.data.nextPage);
    }

    const isSuccessAnalysisRequestForExport = computed(
      () => !analysisRequest.value?.error && !!analysisRequest.value?.data
    );

    watch(
      () => analysisListRequest.value?.data,
      (data) => {
        if (data) {
          analysisList.push(...data.docs);
          analysisListHasNext.value = data.hasNextPage;
        }
      }
    );

    watch(isSuccessAnalysisRequestForExport, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.openDialog({
            id: 'analysis-export-pdf',
            meta: {
              scenario: analysisRequest.value?.data._scenario.key,
              analysis: analysisRequest.value?.data,
              patient: patient.value,
              mode: 'default',
              filter: analysisRequest.value?.data.filter
            }
          })
        );
      }
    });

    watch(params, (currentValue, previousValue) => {
      if (currentValue.cuid !== previousValue.cuid) {
        configureRequest();
      }
    });

    onMounted(() => {
      configureRequest();
    });

    onBeforeUnmount(() => {
      analysisListRequest.value.cancel();
    });

    busModule.useEventSubscriber(busModule.events.analysisDeleted, () => {
      analysisListRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/analysis`,
        merge(
          {},
          {
            axios: {
              params: {
                patientCuid: patient.value?.cuid,
                latestStatus: 'completed'
              }
            }
          }
        )
      );

      analysisList.splice(0, analysisList.length);

      analysisListRequest.value.request();
    });

    busModule.useEventSubscriber(busModule.events.analysisRenamed, () => {
      analysisListRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/analysis`,
        merge(
          {},
          {
            axios: {
              params: {
                patientCuid: patient.value?.cuid,
                latestStatus: 'completed'
              }
            }
          }
        )
      );

      analysisList.splice(0, analysisList.length);

      analysisListRequest.value.request();
    });

    const analysisTypes = reactive({
      key: 'analysisType',
      value: 'all',
      items: [
        {
          text: root.$t('commons.standards.all-types'),
          value: 'all'
        },
        {
          text: root.$t(`commons.standards.${nameByAnalysisType[AnalysisType.walking]}`).toString(),
          value: 'walking'
        },
        {
          text: root.$t(`commons.standards.${nameByAnalysisType[AnalysisType.running]}`).toString(),
          value: 'running'
        },
        {
          text: root.$t(`commons.standards.${nameByAnalysisType[AnalysisType.jumping]}`).toString(),
          value: 'rehab'
        }
      ],
      rules: {
        required: true
      }
    });

    const startDate = useFormFieldDate({
      key: 'startDate',
      value: '',
      rules: {
        date: dateFormat.value,
        required: false
      },
      datePicker: {
        max: root.$moment().toISOString()
      }
    });

    const endDate = useFormFieldDate({
      key: 'endDate',
      value: '',
      rules: {
        date: dateFormat.value,
        required: false
      },
      datePicker: {
        max: root.$moment().toISOString()
      }
    });

    return {
      // Values
      patient,
      route,
      error,
      analysisList,
      analysisListHasNext,
      patientSidebarMiniVariant,
      toggleMiniVariant,
      nameByAnalysisType,
      formattedDate,
      analysisTypes,
      startDate,
      endDate,
      // Methods
      openAnalysis,
      openRenameAnalysis,
      openComparison,
      openExport,
      openDeleteAnalysis,
      loadMore,
      getMode,
      // Flags
      isPending,
      // Helpers
      dateFormatRelatedToSelectedLanguage,
      // Utils
      getIconByScenario
    };
  }
});
