


























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, toRefs, computed, reactive, watch, onMounted } from '@vue/composition-api';
import { useMediaControls } from '@vueuse/core';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
// Import utils ------------------------------------------------------------------------------------
import { useVideo } from '@/utils/video.utils';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { VideoEntityCompleted } from '@digitsole/blackburn-entities/dist/entities/video/video.entity';
// -------------------------------------------------------------------------------------------------
export default defineComponent({
  name: 'PatientMediasVideo',
  components: {
    AlertError
  },
  props: {
    video: {
      type: Object as PropType<VideoEntityCompleted>,
      required: true
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },
  setup(properties) {
    const { video } = toRefs(properties);
    const { toggleSource, videoResolution } = useVideo();
    const rVideo = ref();
    const availableSources = computed(() => video.value.processed.videos);
    let switchSourceTemporaryProperties = reactive({
      isPlaying: false,
      currentTime: 0
    });
    const _selectedSource = ref(
      availableSources.value.find((source) => source.resolutionType === videoResolution.value) ??
        availableSources.value[0]
    );
    const selectedSource = computed({
      get: () => _selectedSource.value,
      set: (newSelectedSource) => {
        switchSourceTemporaryProperties.isPlaying = playing.value;
        switchSourceTemporaryProperties.currentTime = currentTime.value;
        if (playing.value) playing.value = false;
        _selectedSource.value = newSelectedSource;
      }
    });
    const selectedSourceIsHd = computed(() => selectedSource.value.resolutionType === 'hd');
    const selectedSourceUrl = computed(() => selectedSource.value._signedS3Url as string);
    const { playing, currentTime } = useMediaControls(rVideo, {
      src: selectedSourceUrl
    });
    function switchSource() {
      toggleSource();
      selectedSource.value =
        availableSources.value.find(
          (source) => source.resolutionType === (selectedSourceIsHd.value ? 'sd' : 'hd')
        ) ?? availableSources.value[0];
    }
    onMounted(() => {
      const observer = new MutationObserver(() => {
        const unwatch = watch(currentTime, (newCurrentTime) => {
          if (newCurrentTime <= 0) {
            currentTime.value = switchSourceTemporaryProperties.currentTime;
            if (switchSourceTemporaryProperties.isPlaying) playing.value = true;
            switchSourceTemporaryProperties = Object.assign(
              {},
              {
                isPlaying: false,
                currentTime: 0
              }
            );
          }
          unwatch();
        });
      });
      observer.observe(rVideo.value, {
        subtree: false,
        childList: true
      });
    });
    return {
      // Values
      rVideo,
      selectedSourceIsHd,
      // Fields
      selectedSource,
      // Methods
      switchSource
    };
  }
});
