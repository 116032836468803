















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartWalkingClearance from '@/components/charts/walking/ChartWalkingClearance.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartClearance',
  components: {
    ChartExportationContainer,
    ChartWalkingClearance
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.walking_minimum_toe_clearance);

    const relative = ref(false);
    const advanced = ref(false);

    const normsType = computed(() => {
      return {
        base: [
          properties.norms.walking_minimum_toe_clearance.bounds.neutral.min,
          properties.norms.walking_minimum_toe_clearance.bounds.neutral.max
        ]
      };
    });

    const clearanceUnit = computed(() => getUnit(EValueTypeUnit.Clearance));

    return {
      // Valid
      valid,
      // Values
      clearanceUnit,
      normsType,
      relative,
      advanced
    };
  }
});
