









// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientTrackCard',
  setup() {
    const { data: patient } = usePatient();

    return {
      // Values
      patient
    };
  }
});
