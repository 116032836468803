


















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartStandardGaugeSections from '@/components/charts/standard/ChartStandardGaugeSections.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingWidgetSpeed',
  components: {
    ChartContainer,
    ChartStandardGaugeSections
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    simple: {
      type: Boolean
    }
  },
  setup(properties) {
    const { currentUnit } = useUnit();

    const speedUnit = computed(() => getUnit(EValueTypeUnit.Speed));
    const valid = computed(() => properties.displayMetrics.aggregates.walking_speed);

    const normsType = computed(() => {
      return {
        base: [
          properties.norms.walking_speed.bounds.neutral[currentUnit().isImperial.value ? 'imp_min' : 'min'],
          properties.norms.walking_speed.bounds.neutral[currentUnit().isImperial.value ? 'imp_max' : 'max']
        ]
      };
    });

    return {
      valid,
      speedUnit,
      normsType
    };
  }
});
