













































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
import { chartColors } from '@/utils/charts-colors';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingClearance',
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();

    const data = computed(() => {
      return {
        left: {
          color: '#ffbd1a',
          textColor: '#ffbd1a',
          height: properties.aggregates.left_foot[isImperial.value ? 'imp_avg' : 'avg'] ?? '-'
        },
        right: {
          color: '#2962fd',
          textColor: '#2962fd',
          height: properties.aggregates.right_foot[isImperial.value ? 'imp_avg' : 'avg'] ?? '-'
        }
      };
    });

    const unit = computed(() => getUnit(EValueTypeUnit.Clearance));

    return {
      chartColors,
      data,
      unit
    };
  }
});
