



































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ----------------------------------------------------------------------------------
import { useAnalytics } from '@/utils/analytics.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogAnalysisDelete',
  components: { CompositeDialog, AlertError, AlertSuccess },
  setup() {
    const { trackSuccess } = useAnalytics();

    const dialogId: DialogId = 'analysis-delete';
    const rDialog = ref<any>(null);

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    let analysisRequest = ref<any>(null);
    let analysisDeleteRequest = ref<any>(null);

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (value) {
            analysisRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/analysis/${rDialog.value.meta.analysisCuid}`
            );
            analysisDeleteRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/analysis/${rDialog.value.meta.analysisCuid}`,
              {
                axios: {
                  method: 'DELETE'
                }
              }
            );

            analysisRequest.value.request();
          } else {
            analysisRequest.value.cancel();
            analysisDeleteRequest.value.cancel();

            nextTick(() => {
              analysisRequest.value.clear();
              analysisDeleteRequest.value.clear();

              analysisRequest.value = null;
            });
          }
        }
      );
    });

    function deleteAnalysis() {
      analysisDeleteRequest.value.request();
    }

    function closeDialog() {
      rDialog.value.close();
    }

    const isMounted = computed(() => {
      return analysisRequest.value && analysisDeleteRequest.value;
    });

    const isDisabled = computed(() => {
      return !analysisRequest.value || analysisRequest.value.isPending;
    });

    const isSuccess = computed(() => {
      return !analysisDeleteRequest.value?.error && !!analysisDeleteRequest.value?.data;
    });

    watch(isSuccess, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.analysisDeleted({
            analysisCuid: rDialog.value.meta.analysisCuid
          })
        );
        trackSuccess('PatientAnalysis/Delete', {
          analysisType: analysisRequest.value.data.analysisType
        });
      }
    });

    return {
      rDialog,
      dialogId,
      analysisRequest,
      analysisDeleteRequest,
      // Methods
      deleteAnalysis,
      closeDialog,
      // Flags
      isMounted,
      isDisabled,
      isSuccess
    };
  }
});
