


































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, watch, Ref } from '@vue/composition-api';
import { useLocalStorage } from '@vueuse/core';
// Import plugins ----------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------

export enum EFilter {
  Default = 'default',
  Neurological = 'neurological',
  Traumatology = 'traumatology',
  Reathleticism = 're-athleticism'
}

export default defineComponent({
  name: 'ElementEvolutionFilter',
  setup(_, { emit }) {
    // Filter[]
    const selectedFilter: Ref<string> = useLocalStorage('selectedFilter', EFilter.Default);
    const analysesFilters: Ref<EFilter[]> = ref([
      EFilter.Default,
      EFilter.Neurological,
      EFilter.Traumatology,
      EFilter.Reathleticism
    ]);

    const isDefaultView: Ref<boolean> = computed(() => selectedFilter.value === EFilter.Default);
    watch(selectedFilter, (filter) => {
      emit('setSelectedFilter', filter);
    });
    watch(isDefaultView, (defaultView) => {
      emit('setDefaultView', defaultView);
    });

    // Custom results page
    function selectFilter(filter: string) {
      selectedFilter.value = filter;
    }

    return {
      selectedFilter,
      analysesFilters,
      // Methods
      selectFilter
    };
  }
});
