var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"patient__results-header"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('div',{class:("analysis-type__icon analysis-type__" + (_vm.degradedAnalysis ? 'degraded' : _vm.refactoredAnalysisType[_vm.analysisType.split('_')[0]]) + "__icon")},[_c('GlobalIcon',{attrs:{"icon":_vm.getIconByScenario(_vm.singleAnalysis._scenario.key).icon,"library":_vm.getIconByScenario(_vm.singleAnalysis._scenario.key).library,"color":"white","size":"16"}})],1),(
          _vm.singleAnalysis._scenario.steps[0].activity.format === 'embedded' &&
          _vm.nameByAnalysisType[_vm.analysisType.split('_')[0]] === 'walk'
        )?_c('div',{staticClass:"embedded-icon ml-2"},[_c('img',{attrs:{"src":require('@/assets/images/embedded-icon.svg'),"alt":"embedded"}})]):_vm._e(),_c('div',{staticClass:"flex column justify-center ml-3"},[_c('div',{staticClass:"analysis-name"},[(_vm.singleAnalysis.name)?_c('span',[_vm._v(_vm._s(_vm.singleAnalysis.name))]):(_vm.analysisType.split('_')[0] === 'jumping')?_c('span',[_vm._v(_vm._s(_vm.$t(("commons.standards." + (_vm.getMode(_vm.analysisType))))))]):_c('span',[_vm._v(" "+_vm._s(_vm.$t(("commons.standards." + (_vm.nameByAnalysisType[_vm.analysisType.split('_')[0]]))))+" ")]),_c('VTooltip',{attrs:{"bottom":"","open-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('VBtn',_vm._g({attrs:{"icon":"","small":"","aria-label":_vm.$tc('commons.sentences.rename-analysis')},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openRenameAnalysis(_vm.singleAnalysis.cuid)}}},tooltip),[_c('GlobalIcon',{attrs:{"icon":"pen","color":"secondary","size":"14"}})],1)]}}])},[_vm._v(" "+_vm._s(_vm.$tc('commons.sentences.rename-analysis'))+" ")])],1),_c('div',{staticClass:"date"},[_c('span',[_vm._v(_vm._s(_vm.$moment(_vm.singleAnalysis.createdAt).format(_vm.formattedDate)))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(_vm.$moment(_vm.singleAnalysis.createdAt).format('LT')))]),('podomigration' in _vm.analysis)?[_c('div',{staticClass:"dot"}),('podomigration' in _vm.analysis)?_c('div',{staticClass:"d-flex align-center caption font-weight-semibold podosmart--text"},[_c('img',{staticClass:"mr-2",staticStyle:{"height":"20px"},attrs:{"src":require('@/assets/images/logo-migrated.svg'),"alt":"migrated"}}),_vm._v(" Podosmart ")]):_vm._e()]:_vm._e(),(_vm.analysis.demoCuid)?_c('VChip',{staticClass:"chip-primary ml-2",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.$t('commons.standards.demo')))]):_vm._e()],2)])]),_c('div',{staticClass:"flex row justify-end align-center"},[_c('div',{staticClass:"navigation"},[((!_vm.degradedAnalysis && _vm.isRehab) || (_vm.isWalking && _vm.selectedFilter === 'default') || _vm.isRunning)?[(!_vm.degradedAnalysis && !_vm.isRehab)?_c('VBtnToggle',{staticClass:"page-selecter ml-3 my-2",attrs:{"color":"white","mandatory":"","borderless":""},model:{value:(_vm.resultsTab),callback:function ($$v) {_vm.resultsTab=$$v},expression:"resultsTab"}},_vm._l((_vm.steps),function(step){return _c('VTooltip',{key:step.id,staticClass:"ml-2",attrs:{"disabled":!step.disabled,"max-width":"500","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('div',_vm._g({},tooltip),[_c('VBtn',{directives:[{name:"show",rawName:"v-show",value:(step.show),expression:"step.show"}],class:{ 'page-selecter__active': _vm.resultsTab === step.id },attrs:{"value":step.id,"height":33,"disabled":!!step.disabled}},[(step.label)?_c('span',{staticClass:"step-label"},[_vm._v(_vm._s(step.label))]):_vm._e(),_c('span',[_vm._v(_vm._s(step.text))])])],1)]}},{key:"default",fn:function(){return [_c('span',[_vm._v(_vm._s(step.disabled))])]},proxy:true}],null,true)})}),1):_vm._e()]:_vm._e()],2),(_vm.isWalking && !_vm.degradedAnalysis && !_vm.isOnlyOrthotics)?_c('ElementAnalysisFilter',_vm._b({on:{"setSelectedFilter":function($event){return _vm.setSelectedFilter($event)},"setDefaultView":function($event){return _vm.setDefaultView($event)}}},'ElementAnalysisFilter',{ analysis: _vm.analysis },false)):_vm._e(),_c('VMenu',{attrs:{"content-class":"elevation-3","rounded":"lg","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
return [_c('VBtn',_vm._g({staticClass:"ml-5 mr-3",attrs:{"color":"white","icon":"","depressed":"","small":""}},menu),[_c('GlobalIcon',{attrs:{"icon":"ellipsis-h","color":"secondary","size":"18"}})],1)]}}])},[_c('VList',{attrs:{"dense":""}},[_c('VListItem',{on:{"click":function($event){return _vm.openRenameAnalysis(_vm.singleAnalysis.cuid)}}},[_c('div',{staticClass:"list-menu-item"},[_c('GlobalIcon',{attrs:{"icon":"pen","color":"secondary","size":"13"}}),_c('span',[_vm._v(_vm._s(_vm.$t('commons.sentences.rename-analysis')))])],1)]),(!_vm.isOnlyOrthotics)?_c('VListItem',{on:{"click":function($event){return _vm.openComparison()}}},[_c('div',{staticClass:"list-menu-item"},[_c('GlobalIcon',{attrs:{"icon":"clone","color":"secondary","size":"13"}}),_c('span',[_vm._v(_vm._s(_vm.$t('commons.actions.compare')))])],1)]):_vm._e(),(!_vm.isOnlyOrthotics)?_c('VListItem',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openExport()}}},[_c('div',{staticClass:"list-menu-item"},[_c('GlobalIcon',{attrs:{"icon":"file-download","color":"secondary","size":"13"}}),_c('span',[_vm._v(_vm._s(_vm.$t('commons.actions.export.pdf')))])],1)]):_vm._e(),((_vm.isWalking || _vm.isRunning) && !_vm.isOnlyOrthotics)?_c('VListItem',{on:{"click":function($event){return _vm.downloadPdf(_vm.manual.url, _vm.manual.fileName)}}},[_c('div',{staticClass:"list-menu-item"},[_c('GlobalIcon',{attrs:{"icon":"arrow-to-bottom","color":"secondary","size":"13"}}),_c('span',[_vm._v(_vm._s(_vm.$t('commons.standards.interpretation-manual')))])],1)]):_vm._e(),_c('VListItem',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openDelete()}}},[_c('div',{staticClass:"list-menu-item"},[_c('GlobalIcon',{attrs:{"icon":"trash-alt","color":"#E8A500","size":"13"}}),_c('span',{staticClass:"remove"},[_vm._v(_vm._s(_vm.$t('commons.sentences.remove-acquisition')))])],1)])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }