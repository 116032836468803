
































































// Import vendors ----------------------------------------------------------------------------------
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  Ref,
  toRefs,
  PropType
} from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import DialogAnalysisCustomFilter from '@/components/dialogs/DialogAnalysisCustomFilter.vue';
// Import utils-- ----------------------------------------------------------------------------------
import { useSingleAnalysis } from '@/utils/single-analysis.utils';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import types ------------------------------------------------------------------------------------
import type { AnalysisEntityCompleted } from '@digitsole/blackburn-entities/dist/entities/analysis/analysis-completed.entity';
// -------------------------------------------------------------------------------------------------

export enum EFilter {
  Default = 'default',
  Neurological = 'neurological',
  Traumatology = 'traumatology',
  Reathleticism = 're-athleticism',
  Custom = 'custom'
}

export default defineComponent({
  name: 'ElementAnalysisFilter',
  components: {
    DialogAnalysisCustomFilter
  },
  props: {
    analysis: {
      type: Object as PropType<AnalysisEntityCompleted>,
      required: true
    }
  },
  setup(properties, { emit }) {
    const { analysis } = toRefs(properties);
    const busModule = usePodocoreModule('bus');
    const workspacesService = usePodocoreModuleService('workspaces');
    const { setSingleAnalysis, singleAnalysis } = useSingleAnalysis();

    const workspace = computed(() => (workspacesService.state.value as any).context.current);

    // Filters
    const selectedFilter: Ref<EFilter> = ref(EFilter.Default);
    const selectedDoctorFilter: Ref<string | null> = ref(null);

    const filters: Ref<EFilter[]> = ref([
      EFilter.Default,
      EFilter.Neurological,
      EFilter.Traumatology,
      EFilter.Reathleticism
    ]);
    const analysisWorkspaceFilter = computed(() =>
      analysis?.value
        ? workspace.value.filters.find((x: any) => x.cuid === analysis.value.filter)
        : EFilter.Default
    );

    const isDefaultView: Ref<boolean> = computed(() => selectedFilter.value === EFilter.Default);
    watch(isDefaultView, (defaultView) => {
      if (filters.value.includes(selectedFilter.value)) {
        emit('setDefaultView', defaultView);
      }
    });

    // Custom results page
    function openCustomFilter(filter?: any) {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-filter-custom-select',
          meta: { filter }
        })
      );
    }

    // Custom results page
    function renamefilter(name: string) {
      selectedDoctorFilter.value = name;
      selectedFilter.value = EFilter.Custom;
    }

    function selectFilter(_filter: EFilter) {
      selectedFilter.value = _filter;
      if (_filter !== 'custom') {
        emit('setSelectedFilter', _filter);
        setSingleAnalysis({ ...singleAnalysis.value, filter: _filter });
        busModule.publish(
          busModule.events.analysisFilter({
            filter: _filter,
            type: 'PATCH'
          })
        );
      }
    }

    function selectDoctorFilter(filter: any) {
      selectedDoctorFilter.value = filter.name;
      selectedFilter.value = EFilter.Custom;
      emit('setSelectedFilter', EFilter.Custom);
      setSingleAnalysis({ ...singleAnalysis.value, filter: filter.cuid });
      busModule.publish(
        busModule.events.analysisFilter({
          type: 'PATCH',
          filter: 'custom',
          name: filter.name,
          selectedCharts: filter.filters,
          cuid: filter.cuid
        })
      );
    }

    const isSuccessFilterDeleted = computed(() =>
      [{ deletingFilter: 'success' }].some((element) => workspacesService.state.value.matches(element))
    );

    watch(isSuccessFilterDeleted, (value) => {
      if (value && workspace.value.filters) {
        selectFilter(EFilter.Default);
      }
    });

    onMounted(() => {
      if (analysis.value.filter)
        if (Object.values(EFilter).includes(analysis.value.filter as EFilter)) {
          // Is pathology filter type
          emit('setSelectedFilter', analysis.value.filter);
          selectedFilter.value = analysis.value.filter as EFilter;
        }
        // Is custom filter type
        else if (analysisWorkspaceFilter.value) {
          selectedDoctorFilter.value =
            workspace.value.filters.find((x: any) => x.cuid === analysis.value.filter).name ?? null;
          selectedFilter.value = EFilter.Custom;
          emit('setSelectedFilter', EFilter.Custom);
        }
    });

    return {
      selectedFilter,
      selectedDoctorFilter,
      workspace,
      filters,
      // Methods
      renamefilter,
      selectFilter,
      selectDoctorFilter,
      openCustomFilter
    };
  }
});
