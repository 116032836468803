
































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onBeforeUnmount, watch } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
import { useSingleAnalysis } from '@/utils/single-analysis.utils';
// Import repositories -----------------------------------------------------------------------------
import { ConditionsWalkingAidDevices } from '@/plugins/podocore/repositories/analysis.repository';
// Import helpers ----------------------------------------------------------------------------------
import { enumToArrayAndTranslate } from '@/helpers/helped-functions.helper';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
import { AnyObject } from 'mongoose';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultSummary',
  components: {
    AlertError
  },
  props: {
    disabledConditions: {
      type: Array,
      required: false
    },
    disableEdition: {
      type: Boolean,
      required: false
    }
  },
  setup(properties) {
    const { singleAnalysis: analysis, setSingleAnalysisConditions } = useSingleAnalysis();

    const { params } = useRoute();
    const busModule = usePodocoreModule('bus');

    const requestModule = usePodocoreModule('request');
    const analysisRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/analysis/${params.value.analysisCuid}`
    );
    let analysisConditionsPatchRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/analysis/${params.value.analysisCuid}/conditions`,
      {
        axios: {
          method: 'PATCH'
        }
      }
    );

    const hideCondition = (condition: string) => properties.disabledConditions?.includes(condition);

    const internalWalkingAidList: any = enumToArrayAndTranslate(ConditionsWalkingAidDevices, {
      key: 'analysis.steps.conditions.walking_aids'
    });

    const painList = computed(() => {
      return {
        data: analysis.value.conditions?.painList,
        key: 'analysis.steps.conditions.pains'
      };
    });

    const shoesType = computed(() => {
      return {
        data: analysis.value.conditions?.shoesType,
        key: 'analysis.steps.conditions.shoes_type'
      };
    });

    const walkingAidList = computed(() => {
      return {
        data: analysis.value.conditions?.walkingAidList,
        key: 'analysis.steps.conditions.walking_aids'
      };
    });

    function getWalkingAidName(value: string) {
      const result = internalWalkingAidList.find((x: any) => x.value === value).text;
      if (result) return result;
      return value;
    }
    function getPainLevelType(stringLevel: string) {
      const level = parseFloat(stringLevel);
      if (level <= 3) {
        return {
          class: 'label-good',
          color: 'success'
        };
      } else if (level > 3 && level < 8) {
        return {
          class: 'label-warning',
          color: 'warning'
        };
      } else {
        return {
          class: 'label-bad',
          color: 'error'
        };
      }
    }

    // Pain
    function addPain() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-conditions-pains-update',
          meta: {
            analysisCuid: analysis.value.cuid,
            conditions: analysis.value.conditions,
            mode: 'add'
          }
        })
      );
    }

    function patchPain(index: number) {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-conditions-pains-update',
          meta: {
            index,
            analysisCuid: analysis.value.cuid,
            conditions: analysis.value.conditions,
            mode: 'patch'
          }
        })
      );
    }

    function deletePain(index: number) {
      const painList = analysis.value.conditions.painList.filter(
        (_x: unknown, _index: number) => _index != index
      );
      const data = {
        ...analysis.value.conditions,
        painList
      };
      patchAnalysisConditions(data);
    }

    // Walking aids
    function addWalkingAids() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-conditions-walking-aids-update',
          meta: {
            analysisCuid: analysis.value.cuid,
            conditions: analysis.value.conditions,
            mode: 'add'
          }
        })
      );
    }

    function patchWalkingAids() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-conditions-walking-aids-update',
          meta: {
            analysisCuid: analysis.value.cuid,
            conditions: analysis.value.conditions,
            mode: 'patch'
          }
        })
      );
    }

    function deleteWalkingAids(name: string) {
      const walkingAidList = analysis.value.conditions.walkingAidList.filter((x: string) => x != name);
      const data = {
        ...analysis.value.conditions,
        walkingAidList
      };
      patchAnalysisConditions(data);
    }

    // Shoes type
    function addShoesType() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-conditions-shoes-type-update',
          meta: {
            analysisCuid: analysis.value.cuid,
            conditions: analysis.value.conditions,
            mode: 'add'
          }
        })
      );
    }

    function patchShoesType() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-conditions-shoes-type-update',
          meta: {
            analysisCuid: analysis.value.cuid,
            conditions: analysis.value.conditions,
            mode: 'patch'
          }
        })
      );
    }

    function deleteShoesType() {
      const data = {
        painList: analysis.value.conditions.painList,
        walkingAidList: analysis.value.conditions.walkingAidList
      };
      patchAnalysisConditions(data);
    }

    function patchAnalysisConditions(data: AnyObject) {
      analysisConditionsPatchRequest.request({
        axios: {
          method: 'PATCH',
          data
        }
      });
    }

    const isSuccess = computed(() => {
      return (
        !analysisConditionsPatchRequest.isPending.value &&
        !analysisConditionsPatchRequest.error.value &&
        !!analysisConditionsPatchRequest.data.value
      );
    });

    watch(isSuccess, (value) => {
      if (value) {
        setSingleAnalysisConditions(analysisConditionsPatchRequest.data.value.conditions);
        busModule.publish(
          busModule.events.analysisConditionsPatched({
            analysisCuid: params.value.analysisCuid as string
          })
        );
      }
    });

    const isPending = computed(
      () => analysisRequest?.isPending.value || analysisConditionsPatchRequest?.isPending.value
    );

    const error = computed(() => analysisRequest?.error.value || analysisConditionsPatchRequest?.error.value);

    onBeforeUnmount(() => {
      analysisConditionsPatchRequest.cancel();
    });

    return {
      // Values
      analysis,
      error,
      painList,
      walkingAidList,
      shoesType,
      // Methods
      getWalkingAidName,
      getPainLevelType,
      hideCondition,
      // Pain
      addPain,
      patchPain,
      deletePain,
      // WalkingAid
      addWalkingAids,
      patchWalkingAids,
      deleteWalkingAids,
      // ShoesType
      addShoesType,
      patchShoesType,
      deleteShoesType,
      // Flags
      isPending
    };
  }
});
