
































































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onMounted, ref, reactive, watch } from '@vue/composition-api';
import { get } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
import i18n from '@/plugins/i18n';
// Import utils ------------------------------------------------------------------------------------
import { useLocalStorage } from '@vueuse/core';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import ExportationViewerComparison from '@/components/exportation/ExportationViewerComparison.vue';
import ExportationViewerEvolution from '@/components/exportation/ExportationViewerEvolution.vue';
import ExportationViewerSingleAnalysis from '@/components/exportation/ExportationViewerSingleAnalysis.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export enum EResultsTab {
  General = 'general',
  Advanced = 'advanced'
}

export type TExportAnalysis = {
  informations: TExportAnalysisInformations | TExportAnalysisInformationsComparison;
  standards: any;
};

export type TExportAnalysisInformations = {
  analysisType: string;
  activityDate: Date;
  valid: number;
  activityDuration?: Date;
  exportSelectedSegments?: any;
};
export type TExportAnalysisInformationsComparison = {
  first: TExportAnalysisInformations;
  second: TExportAnalysisInformations;
};

export enum EFilter {
  Default = 'default',
  Neurological = 'neurological',
  Traumatology = 'traumatology',
  Reathleticism = 're-athleticism',
  Custom = 'custom'
}

export default defineComponent({
  name: 'DialogExportPDF',
  components: {
    CompositeDialog,
    ExportationViewerComparison,
    ExportationViewerEvolution,
    ExportationViewerSingleAnalysis
  },
  setup() {
    // Dialog
    const dialogId: DialogId = 'analysis-export-pdf';

    // References
    const rDialog = ref<any>(null);
    const rFrame = ref<any>(null);

    // Services
    const workspacesService = usePodocoreModuleService('workspaces');
    const aclService = usePodocoreModuleService('acl');

    const currentWorkspace = computed(() => {
      return (workspacesService.state.value as any).context.current;
    });

    const resultsTab = computed(() => get(rDialog, 'value.meta.resultsTab', EResultsTab.General));
    const scenario = computed(() => get(rDialog, 'value.meta.scenario', null));
    const analysis = computed(() => get(rDialog, 'value.meta.analysis', null));
    const analyses = computed(() => get(rDialog, 'value.meta.analyses', null));
    const patient = computed(() => get(rDialog, 'value.meta.patient', null));
    const mode = computed(() => get(rDialog, 'value.meta.mode', 'standard'));
    const exportSelectedSegments = computed(() => get(rDialog, 'value.meta.exportSelectedSegments', []));
    const filter = computed(() => get(rDialog, 'value.meta.filter', null));

    const analysisWorkspaceFilter = computed(() => filter.value ?? EFilter.Default);
    const customFilter = computed(() => {
      return currentWorkspace.value.filters
        ? currentWorkspace.value.filters.find((x: any) => x.cuid === analysisWorkspaceFilter.value)
        : null;
    });

    const detailledRunningMode = computed(
      () =>
        resultsTab.value === EResultsTab.Advanced &&
        exportSelectedSegments.value &&
        exportSelectedSegments.value.length > 1
    );

    const printDialogIsOpened = ref<boolean>(false);

    let pdfData = reactive({
      doctor: {
        email: {
          label: 'commons.standards.email',
          value: true
        },
        phone: {
          label: 'commons.standards.phone',
          value: true
        },
        logo: {
          label: 'commons.standards.workspace-logo',
          value: true,
          hide: false
        }
      },
      patient: {
        name: {
          label: 'commons.standards.name',
          value: true
        },
        age: {
          label: 'commons.standards.age',
          value: true
        },
        gender: {
          label: 'commons.standards.gender',
          value: true
        },
        height: {
          label: 'commons.standards.height',
          value: true
        },
        weight: {
          label: 'commons.standards.weight',
          value: true
        }
      },
      analysis: {
        advanced: {
          label: i18n.t('commons.standards.advanced'),
          value: true
        },
        details: {
          label: i18n.tc('commons.standards.detail', 2),
          value: true
        },
        tooltips: {
          label: i18n.tc('commons.standards.information'),
          value: true
        }
      },
      orthotics: {
        label: i18n.tc('commons.standards.detail', 2),
        value: true
      }
    });

    // PDF cache
    const pdfDataCache = useLocalStorage('exportPdfData', pdfData);

    const showDoctorData = computed(() => {
      return {
        email: pdfData.doctor.email.value,
        phone: pdfData.doctor.phone.value,
        workspaceLogo: pdfData.doctor.logo.value
      };
    });

    const showPatientData = computed(() => {
      return {
        name: pdfData.patient.name.value,
        age: pdfData.patient.age.value,
        gender: pdfData.patient.gender.value,
        height: pdfData.patient.height.value,
        weight: pdfData.patient.weight.value
      };
    });

    const headerInformations = computed(() => {
      return {
        analysis: analysis.value,
        scenario: scenario.value,
        patient: patient.value,
        mode: mode.value,
        showDoctorData: showDoctorData.value,
        showPatientData: showPatientData.value,
        workspace: currentWorkspace.value
      };
    });

    const hasOrthotics = computed(() => {
      return (
        Object.prototype.hasOwnProperty.call(analysis.value.metrics, 'orthotics') &&
        (aclService.state.value as any).context.features.orthotics
      );
    });

    function closeDialog() {
      rDialog.value.close();
    }

    onMounted(() => {
      pdfDataCache.value.doctor.logo.hide = !currentWorkspace.value._logoS3signedUrl;
      if (pdfDataCache.value) pdfData = pdfDataCache.value;
    });

    watch(
      pdfData,
      (value) => {
        pdfDataCache.value = value;
      },
      { deep: true }
    );

    watch(
      exportSelectedSegments,
      () => {
        if (detailledRunningMode.value) pdfData.analysis.details.value = true;
      },
      { deep: true }
    );

    function print() {
      // ExportationViewer
      let dialog = rDialog.value.$children[0].$children[0].$children[0];
      dialog = dialog.$children[dialog.$children.length - 1];
      dialog.print();
    }

    return {
      // References
      rFrame,
      rDialog,
      // Dialog
      dialogId,
      // analysis
      pdfData,
      hasOrthotics,
      currentWorkspace,
      analysisWorkspaceFilter,
      customFilter,
      scenario,
      analysis,
      analyses,
      patient,
      mode,
      headerInformations,
      // Methods
      print,
      closeDialog,
      // Flags
      printDialogIsOpened,
      detailledRunningMode,
      exportSelectedSegments,
      resultsTab
    };
  }
});
