<template>
  <ChartExportationContainer
    v-bind="{ valid, hideTitle, analysisDate, displayTooltips }"
    :title="$t('commons.standards.propulsion-rate')"
    :tooltip="[{ type: 'propulsion-rate' }]"
  >
    <template #content>
      <ChartWalkingPropulsionRatio :values="aggregates.walking_propulsion_ratio" />
    </template>
  </ChartExportationContainer>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartWalkingPropulsionRatio from '@/components/charts/walking/ChartWalkingPropulsionRatio';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartPropulsionRatio',
  components: {
    ChartExportationContainer,
    ChartWalkingPropulsionRatio
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.walking_propulsion_ratio);

    return {
      // Valid
      valid
    };
  }
});
</script>
