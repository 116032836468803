








// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartStandardPronationSupinationAngles from '@/components/charts/standard/ChartStandardPronationSupinationAngles.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningGroupStepsAngles',
  components: {
    ChartStandardPronationSupinationAngles
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    simplified: {
      type: Boolean,
      default: false
    }
  },
  setup(properties) {
    const advancedAngles = ref([{ heelStrike: 'foot_in' }, { flatFoot: 'mid' }, { toesOff: 'foot_out' }]);
    const simplifiedAngles = ref([{ heelStrike: 'foot_in' }, { toesOff: 'foot_out' }]);

    const angles = ref(properties.simplified ? simplifiedAngles.value : advancedAngles.value);

    return { angles };
  }
});
