












































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, onBeforeMount, onBeforeUnmount, watch } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import { get } from 'lodash';
// Import components -------------------------------------------------------------------------------
import DialogPatientPathology from '@/components/dialogs/DialogPatientPathology.vue';
import { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import { Patient } from '@/plugins/podocore/repositories/patients.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  components: {
    DialogPatientPathology
  },
  setup(_, { root }) {
    const { data: patient, setPatientInfos } = usePatient();
    const busModule = usePodocoreModule('bus');
    const requestModule = usePodocoreModule('request');

    const retrievePathologiesRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/pathologies`
    );
    const patientPathologiesRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/patients/${root.$route.params.cuid}/infos`
    );

    const hasPathologies = computed(() => {
      return !!patient.value?.infos.pathologies.length;
    });

    function removePathology(pathologyCuid: string) {
      patientPathologiesRequest.request({
        axios: {
          method: 'PATCH',
          data: {
            pathologies: patient.value?.infos.pathologies.filter((pathology) => {
              return pathology.pathologyCuid !== pathologyCuid;
            })
          }
        }
      });
    }

    function getPathology(pathologyCuid: string) {
      return retrievePathologiesRequest?.data?.value?.pathologies.find(
        (pathology: any) => pathology.cuid === pathologyCuid
      );
    }

    function setPathology(pathology?: any) {
      busModule.publish(
        busModule.events.openDialog({
          id: 'patient-pathology',
          meta: pathology
            ? {
                editedPathology: {
                  pathology: {
                    pathologyTypeCuid: getPathology(pathology.pathologyCuid).pathologyTypeCuid,
                    cuid: pathology.pathologyCuid
                  },
                  sinceDate: pathology.sinceDate
                },
                mode: 'patch',
                patientPathologies: patient.value?.infos.pathologies
              }
            : {
                mode: 'add',
                patientPathologies: patient.value?.infos.pathologies
              }
        })
      );
    }

    function savePathology(pathology: any) {
      const metaId = get(pathology.meta, 'pathology.pathology.cuid') as string | undefined;

      const existentPathology = patient.value?.infos.pathologies.find(
        (_pathology) => metaId === _pathology.pathologyCuid
      );
      const newPathology = {
        pathologyCuid: pathology.pathologyPatched.pathology.cuid,
        sinceDate: pathology.pathologyPatched.sinceDate || undefined
      };

      const pathologies = existentPathology
        ? patient.value?.infos.pathologies.map((_pathology) =>
            _pathology.pathologyCuid === metaId ? newPathology : _pathology
          )
        : [...(patient.value as Entity<Patient>).infos.pathologies, newPathology];

      patientPathologiesRequest.request({
        axios: {
          method: 'PATCH',
          data: {
            pathologies
          }
        }
      });
    }

    watch(patientPathologiesRequest.isPending, (value) => {
      if (!value && !patientPathologiesRequest.error.value) {
        setPatientInfos(patientPathologiesRequest.data.value.infos);
        busModule.publish(
          busModule.events.patientPatched({
            patient: patient.value as Entity<Patient>
          })
        );
      }
    });

    onBeforeMount(() => {
      retrievePathologiesRequest.request();
    });

    onBeforeUnmount(() => {
      retrievePathologiesRequest.cancel();
    });

    return {
      // Requests
      retrievePathologiesRequest,
      patientPathologiesRequest,
      // Values
      patient,
      hasPathologies,
      // Methods
      setPathology,
      getPathology,
      savePathology,
      removePathology
    };
  }
});
