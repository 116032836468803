var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CompositeCard',{class:['home__latest__analysis mb-3', { light: _vm.light }]},[_c('div',{staticClass:"d-flex align-center",on:{"click":function($event){return _vm.navigateToAnalysis(_vm.analysis)}}},[_c('div',{staticClass:"d-flex flex-column mr-5"},[_c('span',{staticClass:"font-weight-bold black--text"},[_vm._v(_vm._s(_vm.patientName))]),(_vm.analysis.name)?_c('span',{staticClass:"font-weight-bold black--text mt-3"},[_vm._v(_vm._s(_vm.analysis.name))]):_vm._e(),_c('div',{staticClass:"home__latest__analysis__subtitle d-flex align-center"},[_c('AnalysisDot',{staticClass:"mr-2",attrs:{"analysisType":_vm.isDegraded ? null : _vm.scenarioKeyPrefix}}),_c('span',{staticClass:"mr-1 secondary--text"},[(_vm.analysis._scenario.key.split('_')[0] === 'jumping')?[_vm._v(_vm._s(_vm.$t(("commons.standards." + (_vm.getMode(_vm.analysis._scenario.key))))))]:[_vm._v(_vm._s(_vm.$t(("home.widgets.latest-analysisList.selector." + _vm.scenarioKeyPrefix))))]],2),(_vm.analysis.demoCuid)?_c('VChip',{staticClass:"chip-primary mr-1",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.$t('commons.standards.demo').toUpperCase()))]):_vm._e(),(
            _vm.analysis._scenario.steps[0].activity.format === 'embedded' && _vm.scenarioKeyPrefix === 'walking'
          )?_c('div',{staticClass:"embedded-icon mr-2"},[_c('img',{attrs:{"src":require('@/assets/images/embedded-icon.svg'),"alt":"embedded"}})]):_vm._e(),_c('span',{staticClass:"dot mr-1"}),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$moment(_vm.analysis.createdAt).format((_vm.dateFormat + " " + _vm.hourFormat))))]),_c('span',{staticClass:"mr-2"},[(_vm.analysis._hasVideo)?_c('GlobalIcon',{attrs:{"icon":"films","color":"secondary","library":"light","size":"12"}}):_vm._e()],1)],1),('podomigration' in _vm.analysis)?_c('div',{staticClass:"d-flex align-center caption font-weight-semibold podosmart--text"},[_c('img',{staticClass:"mr-1",staticStyle:{"height":"15px"},attrs:{"src":require('@/assets/images/logo-migrated.svg'),"alt":"migrated"}}),_vm._v(" Podosmart ")]):_vm._e(),(_vm.hasConditions && _vm.isFirstAnalysis)?_c('div',{staticClass:"mt-3"},[_c('HomeAnalysisSummary',_vm._b({},'HomeAnalysisSummary',{ conditions: _vm.conditions },false))],1):_vm._e()]),_c('div',{staticClass:"d-flex align-center flex-grow-1 justify-end"},[(_vm.isFirstAnalysis && _vm.firstAnalysisRequest && _vm.firstAnalysisRequest.isPending)?[_c('VSkeletonLoader',{staticClass:"d-none d-lg-block mr-5",attrs:{"height":"175","width":"150","type":"card"}}),_c('VSkeletonLoader',{staticClass:"d-none d-md-block mr-5",attrs:{"height":"175","width":"150","type":"card"}})]:(
          _vm.isFirstAnalysis &&
          _vm.firstAnalysisRequest &&
          !_vm.firstAnalysisRequest.isPending &&
          _vm.firstAnalysisRequest.data
        )?[(_vm.firstAnalysisRequest.data.metrics.aggregates && _vm.firstAnalysisRequest.data.metrics.norms)?_c('VRow',{staticClass:"mw-500 ml-auto justify-end"},[_c('VCol',{staticClass:"d-none d-lg-block",attrs:{"cols":"6"}},[(_vm.firstAnalysisRequest.data._scenario.key.startsWith('walking'))?_c('ChartWalkingContainerCadence',{staticClass:"home__latest__analysisList__chart flex-grow-2 mw-250 ml-auto",attrs:{"round":1,"aggregates":_vm.firstAnalysisRequest.data.metrics.aggregates,"norms":_vm.firstAnalysisRequest.data.metrics.norms,"displayMetrics":_vm.firstAnalysisRequest.data.display_metrics}}):_vm._e()],1),(
              (_vm.firstAnalysisRequest.data.analysis_validity === 2 ||
                _vm.firstAnalysisRequest.data.analysis_validity === -1) &&
              _vm.scenarioKeyPrefix === 'walking'
            )?_c('VCol',{staticClass:"d-none d-md-block",attrs:{"cols":"12","lg":"6"}},[_c('ChartWalkingContainerSymmetry',{staticClass:"home__latest__analysisList__chart flex-grow-2 mw-250 ml-auto",attrs:{"aggregates":_vm.firstAnalysisRequest.data.metrics.aggregates,"norms":_vm.firstAnalysisRequest.data.metrics.norms,"displayMetrics":_vm.firstAnalysisRequest.data.display_metrics}})],1):_vm._e()],1):_vm._e()]:_vm._e(),_c('VBtn',{staticClass:"font-weight-bold ml-5",attrs:{"color":"primary","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.navigateToAnalysis(_vm.analysis)}}},[_vm._v(" "+_vm._s(_vm.$t('home.widgets.latest-analysis.see-in-details').toUpperCase())+" ")])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }