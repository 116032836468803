<template>
  <div class="w-100">
    <highcharts :options="chartOptions" />
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabSideHopCadenceEvolution',
  props: {
    segments: {
      type: Object,
      required: true
    },
    activityDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  },
  setup(properties) {
    const { getSideHopCadenceEvolutionChartOptions } = useRehab();

    const chartOptions = getSideHopCadenceEvolutionChartOptions(properties.segments);

    return {
      // Values
      chartOptions
    };
  }
});
</script>

<style lang="scss" scoped></style>
