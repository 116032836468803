















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, onBeforeMount, onBeforeUnmount } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
import { useVuetifyBreakpoints } from '@/utils/vuetify-breakpoints.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import PatientPathologiesCard from '@/components/patient/PatientPathologiesCard.vue';
import PatientReasonsForConsultationCard from '@/components/patient/PatientReasonsForConsultationCard.vue';
import HomeLatestAnalysisList from '@/components/home/HomeLatestAnalysisList.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientDashboard',
  components: {
    PatientPathologiesCard,
    PatientReasonsForConsultationCard,
    HomeLatestAnalysisList
  },
  setup() {
    const { data: patient } = usePatient();

    const { isMdAndLarger } = useVuetifyBreakpoints();

    const requestModule = usePodocoreModule('request');

    const requestBaseParameters = {
      axios: {
        params: {
          sort: [['createdAt', '-1']],
          limit: 5,
          latestStatus: 'completed',
          patientCuid: patient.value?.cuid
        }
      }
    };

    const latestAnalysisListRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/analysis`,
      requestBaseParameters
    );

    onBeforeMount(() => {
      latestAnalysisListRequest.request();
    });

    onBeforeUnmount(() => {
      latestAnalysisListRequest.cancel();
    });

    return {
      // Values
      isMdAndLarger,
      latestAnalysisListRequest
    };
  }
});
