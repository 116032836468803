






























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, Ref } from '@vue/composition-api';
// Import utils ----------------------------------------------------------------------------------
import { chartColors } from '@/utils/charts-colors';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartStandardSlider',
  props: {
    data: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    side: {
      type: String,
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    imperial: {
      type: Boolean
    }
  },
  setup(properties) {
    const unit = {
      min: properties.imperial ? 'imp_min' : 'min',
      max: properties.imperial ? 'imp_max' : 'max'
    };
    const value: number = properties.data[properties.imperial ? 'imp_avg' : 'avg'] || 0;

    const backgroundsColors: Ref<string[]> = ref(['#FF5E13', '#FFBE13', '#25C090', '#FFBE13', '#FF5E13']);
    const sides = {
      left_foot: 'left',
      right_foot: 'right'
    };

    const valuePosition = computed(() => {
      const std = (properties.norms.neutral[unit.max] - properties.norms.neutral[unit.min]) / 2;
      const max = properties.norms.very_high[unit.min] + std;
      const min = properties.norms.very_low[unit.max] - std;

      const percentvalue = ((value - min) * 100) / (max - min);
      if (percentvalue < 0) return 'calc(0% - 8px)';
      if (percentvalue > 100) return 'calc(100% - 8px)';
      return `calc(${percentvalue}% - 8px)`;
    });

    const colorStatus = computed(() => {
      switch (properties.data.range) {
        case 'neutral':
          return {
            color: chartColors.good.fill,
            background: chartColors.good.background
          };
        case 'low':
        case 'high':
          return {
            color: chartColors.warning.fill,
            background: chartColors.warning.background
          };
        case 'very_low':
        case 'very_high':
          return {
            color: chartColors.bad.fill,
            background: chartColors.bad.background
          };
        default:
          return {
            color: chartColors.good.fill,
            background: chartColors.good.background
          };
      }
    });
    return {
      // Values
      value,
      backgroundsColors,
      sides,
      // Methods
      valuePosition,
      colorStatus
    };
  }
});
