













// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, PropType } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils ------------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionSpeed',
  components: {
    ChartEvolutionContainer
  },
  props: {
    manifest: {
      type: Array as PropType<string[]>,
      required: true
    },
    speed: {
      type: Object
    },
    norms: {
      type: Object
    },
    exportation: {
      type: Boolean
    }
  },
  setup(properties) {
    const {
      evolutionChartStructure,
      getGlobalData,
      getGlobalDataVariations,
      getSerieItemStructure,
      getNormsStructure
    } = useChart();
    const speedUnit = computed(() => getUnit(EValueTypeUnit.Speed));

    const title = 'commons.standards.speed';

    const speed = computed(() => getGlobalData(properties.speed, true));

    const dataVariations = computed(() => getGlobalDataVariations(speed.value?.global));

    const valid = computed(() => !!speed.value);

    const series = computed(() => {
      let data = [
        getSerieItemStructure(
          'speed',
          `${i18n.t('commons.standards.speed')}`,
          speed.value?.global,
          EThemeColor.Chart2
        ),
        getSerieItemStructure('speed', undefined, speed.value?.ranges, EThemeColor.Chart2, true)
      ];
      // Have norms
      if (properties.norms)
        data.push(getNormsStructure('speed_norms', properties.norms as { min: number[]; max: number[] }));

      return data;
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(title, properties.manifest, speedUnit.value, 1, {
          exportation: properties.exportation
        }),
        series: series.value
      };
    });

    return {
      speedUnit,
      title,
      dataVariations,
      chartOptions,
      valid
    };
  }
});
