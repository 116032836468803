






















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabWidgetTotalDistance',
  components: {
    ChartContainer
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { isImperial } = useUnit().currentUnit();
    const valueType = computed(() => (isImperial.value ? 'imp_sum' : 'sum'));
    const unit = getUnit(EValueTypeUnit.TotalDistance);

    return {
      valueType,
      unit
    };
  }
});
