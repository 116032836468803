var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column"},[(_vm.isPending && !_vm.analysisList.length)?_c('div',{staticClass:"mt-3"},[_c('VProgressLinear',{attrs:{"indeterminate":"","color":"primary","width":"2","size":"13"}})],1):(_vm.error)?_c('AlertError',{attrs:{"error":_vm.error}}):_vm._e(),(_vm.analysisList.length)?_vm._l((_vm.analysisList),function(analysis){return _c('div',{key:analysis.cuid,staticClass:"chart-card mb-4 flex-row pointer",on:{"click":function($event){return _vm.openAnalysis(analysis.cuid)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:("analysis-type__icon analysis-type__" + (analysis.analysis_validity === 1 ? 'degraded' : analysis._scenario.key.split('_')[0]) + "__icon")},[_c('GlobalIcon',{attrs:{"icon":_vm.getIconByScenario(analysis._scenario.key).icon,"library":_vm.getIconByScenario(analysis._scenario.key).library,"color":"white","size":"16"}})],1),(
              analysis._scenario.steps[0].activity.format === 'embedded' &&
              _vm.nameByAnalysisType[analysis._scenario.key.split('_')[0]] === 'walk'
            )?_c('div',{staticClass:"embedded-icon mr-2"},[_c('img',{attrs:{"src":require('@/assets/images/embedded-icon.svg'),"alt":"embedded"}})]):_vm._e(),('podomigration' in analysis)?_c('div',{staticClass:"migrated-icon mr-1"},[_c('img',{attrs:{"src":require('@/assets/images/logo-migrated.svg'),"alt":"migrated"}})]):_vm._e(),_c('div',{staticClass:"flex column justify-center ml-3"},[_c('div',{staticClass:"analysis-name"},[(analysis.name)?_c('span',[_vm._v(_vm._s(analysis.name))]):(analysis._scenario.key.startsWith('jumping'))?_c('span',[_vm._v(_vm._s(_vm.$t(("commons.standards." + (_vm.getMode(analysis._scenario.key))))))]):_c('span',[_vm._v(_vm._s(_vm.$t(("commons.standards." + (_vm.nameByAnalysisType[analysis._scenario.key.split('_')[0]])))))]),_c('VTooltip',{attrs:{"bottom":"","open-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltip = ref.on;
return [_c('VBtn',_vm._g({attrs:{"icon":"","small":"","aria-label":_vm.$tc('commons.sentences.rename-analysis')},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openRenameAnalysis(analysis.cuid)}}},tooltip),[_c('GlobalIcon',{attrs:{"icon":"pen","color":"secondary","size":"14"}})],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$tc('commons.sentences.rename-analysis'))+" ")])],1),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.$moment(analysis.createdAt).format(_vm.formattedDate))+" - "),_c('span',[_vm._v(_vm._s(_vm.$moment(analysis.createdAt).format('LT')))]),_c('span',{staticClass:"ml-2"},[(analysis._hasVideo)?_c('GlobalIcon',{attrs:{"icon":"films","color":"secondary","library":"light","size":"12"}}):_vm._e()],1),(analysis.demoCuid)?_c('VChip',{staticClass:"chip-primary mr-1",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.$t('commons.standards.demo')))]):_vm._e()],1)])]),_c('VSpacer'),_c('div',{staticClass:"d-flex align-center"},[(!_vm.isOnlyOrthotics)?_c('VBtn',{staticClass:"ml-5",attrs:{"color":"white","icon":"","depressed":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openExport(analysis)}}},[_c('GlobalIcon',{attrs:{"icon":"file-pdf","color":"secondary","library":"light","size":"24"}})],1):_vm._e(),(!_vm.isOnlyOrthotics)?_c('VBtn',{staticClass:"ml-5",attrs:{"color":"white","icon":"","depressed":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openComparison(analysis.cuid)}}},[_c('GlobalIcon',{attrs:{"icon":"copy","color":"secondary","library":"light","size":"24"}})],1):_vm._e(),_c('VMenu',{staticClass:"ml-5",attrs:{"content-class":"elevation-3","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var menu = ref.on;
return [_c('VBtn',_vm._g({staticClass:"ml-5",attrs:{"color":"white","icon":"","depressed":"","small":""}},menu),[_c('GlobalIcon',{attrs:{"icon":"ellipsis-h","color":"secondary","size":"18"}})],1)]}}],null,true)},[_c('VList',{attrs:{"dense":""}},[(_vm.$can('delete', 'analysis'))?_c('VListItem',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openDeleteAnalysis(analysis)}}},[_c('div',{staticClass:"list-menu-item"},[_c('GlobalIcon',{attrs:{"icon":"trash-alt","color":"#E8A500","size":"13"}}),_c('span',{staticClass:"remove"},[_vm._v(_vm._s(_vm.$t('commons.sentences.remove-acquisition')))])],1)]):_vm._e()],1)],1)],1)],1)}):(!_vm.isPending)?_c('VAlert',{staticClass:"mt-3",attrs:{"color":"grey","text":"","dense":""}},[_vm._v(" "+_vm._s(_vm.$t('commons.sentences.no-acquisition'))+" ")]):_vm._e(),(_vm.analysisListHasNext)?_c('VListItem',{staticClass:"load-more-list-item",attrs:{"disabled":_vm.isPending},on:{"click":function($event){return _vm.loadMore()}}},[(_vm.isPending)?_c('VProgressCircular',{attrs:{"indeterminate":"","color":"primary","width":"2","size":"13"}}):_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.$t('commons.actions.load-more')))])],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }