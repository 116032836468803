














































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingFootProgressionAngleCircumduction',
  props: {
    aggregates: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { currentUnit } = useUnit();
    const valueType = computed(() => (currentUnit().isImperial.value ? 'imp_avg' : 'avg'));

    const data = computed(() => {
      return {
        left: {
          color: '#ffbd1a',
          angle: {
            value: properties.aggregates.walking_foot_progression_angle_flat_foot.left_foot.avg,
            type: getAngleType(properties.aggregates.walking_foot_progression_angle_flat_foot.left_foot.range)
            // Manque type
          },
          fauchage: {
            value: properties.aggregates.walking_swing_width.left_foot[valueType.value],
            translate: 'translate(13.47 12)'
          },
          external: {
            foot: 'M37.8 184.74a1.4 1.4 0 00-.89 1.67l1.7 6.63a9.49 9.49 0 0017.94-6.18l-2.73-6.27a1.4 1.4 0 00-1.73-.77zm13-12.22c3-12.54-3.89-34.08-17.56-28S24.59 170.14 35 178a1.37 1.37 0 001.28.2l13.6-4.68a1.38 1.38 0 00.89-1z',
            footFauchageCurve: 'M49.35 35.22c-26.51 0-48 29-48 64.72 0 21.33 7.65 40.24 19.44 52',
            footAngleShape:
              'M51.51 196.49v-68.57c-8.27 0-17 2.25-24.34 4.93q11.38 32 22.78 63.91a.81.81 0 001 .48.8.8 0 00.56-.75z',
            footAngleLine: 'M50.31 197.12l-22.8-63.6',
            footTranslateValue: 'translate(21.13 121.28)'
          },
          internal: {
            foot: 'M47.57 181.05a1.41 1.41 0 00-1.71.82l-2.57 6.33a9.49 9.49 0 0018.09 5.72l1.53-6.67a1.39 1.39 0 00-.91-1.65zM65.26 179c9.93-8.26 17.16-29.7 2.52-32.91S45.64 161.46 49.34 174a1.4 1.4 0 00.91.92L64 179.21a1.36 1.36 0 001.26-.21z',
            footFauchageCurve: 'M49.35 35.22c-26.51 0-48 31.52-48 67.27 0 25.43 14.68 58.39 41.6 67.27',
            footAngleShape:
              'M51.31 197.24a.81.81 0 001-.48q11.38-31.95 22.78-63.91c-7.34-2.68-16.07-4.93-24.34-4.93v68.57a.8.8 0 00.56.75z',
            footAngleLine: 'M50.52 197.87l23-63.53',
            footTranslateValue: 'translate(21.13 139.28)'
          },
          neutral: {
            foot: 'M43.77 182.91a1.4 1.4 0 00-1.4 1.27l-.6 6.82a9.5 9.5 0 0019 .15l-.5-6.82a1.39 1.39 0 00-1.37-1.3zm16.3-7.2c7.06-10.82 7.68-33.44-7.25-32.21s-16.65 21.24-9.44 32.1a1.41 1.41 0 001.14.62l14.38.11a1.4 1.4 0 001.17-.62z',
            footFauchageCurve: 'M49.35 35.47c-26.51 0-48 33.54-48 69.29 0 31.67 17.88 50 33 57.21',
            footAngleShape: null,
            footAngleLine: 'M51.44 198.74l-.32-67.56',
            footTranslateValue: 'translate(23.35 140.54)'
          }
        },
        right: {
          color: '#2962fd',
          angle: {
            value: properties.aggregates.walking_foot_progression_angle_flat_foot.right_foot.avg,
            type: getAngleType(
              properties.aggregates.walking_foot_progression_angle_flat_foot.right_foot.range
            )
          },
          fauchage: {
            value: properties.aggregates.walking_swing_width.right_foot[valueType.value],
            translate: 'translate(93.31 12)'
          },
          external: {
            foot: 'M95.67 257.79a1.39 1.39 0 01.86 1.68l-1.82 6.6a9.49 9.49 0 01-17.83-6.49l2.85-6.22a1.38 1.38 0 011.74-.74zm-12.76-12.44c-2.83-12.6 4.49-34 18-27.65s8.17 25.72-2.37 33.38a1.42 1.42 0 01-1.29.18l-13.51-4.92a1.4 1.4 0 01-.83-.99z',
            footFauchageCurve: 'M82.35 92.1c26.51 0 48 33.16 48 74.07 0 23.42-7 44.3-18 57.87',
            footAngleShape:
              'M82.28 267.74v-69.23c8.09 0 16.62 2.28 23.81 5Q95 235.75 83.84 268a.8.8 0 01-1.56-.26z',
            footAngleLine: 'M83.05 269.75l23.2-68',
            footTranslateValue: 'translate(90 194.28)'
          },
          internal: {
            foot: 'M85.6 251.37a1.4 1.4 0 011.73.78l2.7 6.28a9.49 9.49 0 01-18 6.08l-1.67-6.64a1.38 1.38 0 01.9-1.66zm-17.73-1.74c-10.08-8.07-17.74-29.36-3.18-32.86s22.45 15 19 27.54a1.4 1.4 0 01-.89.94l-13.62 4.6a1.4 1.4 0 01-1.31-.22z',
            footFauchageCurve: 'M82.35 92.1c26.51 0 47.5 38.09 47.5 79 0 23.42-13.82 58.09-39.85 68.34',
            footAngleShape:
              'M82.51 268.5a.81.81 0 01-1-.5l-22.26-64.5c7.18-2.71 15.71-5 23.8-5v69.23a.81.81 0 01-.54.77z',
            footAngleLine: 'M83.23 268.59l-24.38-67.58',
            footTranslateValue: 'translate(90 209.28)'
          },
          neutral: {
            foot: 'M90.38 256a1.39 1.39 0 011.35 1.32l.37 6.83a9.49 9.49 0 01-19-.49l.72-6.8a1.39 1.39 0 011.42-1.25zm-16-7.74c-6.69-11-6.55-33.68 8.33-31.95s15.92 21.79 8.35 32.4a1.35 1.35 0 01-1.16.57l-14.37-.36a1.42 1.42 0 01-1.2-.69z',
            footFauchageCurve: 'M82.35 92.36c26.51 0 48 33.16 48 74.07 0 27.85-9.07 63.25-31.5 71',
            footAngleShape: null,
            footAngleLine: 'M82.22 271.32l.38-71.85',
            footTranslateValue: 'translate(90 210.87)'
          }
        }
      };
    });

    function getAngleType(range: string) {
      switch (range) {
        case 'neutral':
          return 'neutral';
        case 'low':
        case 'very_low':
          return 'internal';
        case 'high':
        case 'very_high':
          return 'external';
        default:
          return 'neutral';
      }
    }

    const unit = computed(() => getUnit(EValueTypeUnit.Circumduction));

    return {
      // Values
      data,
      unit
    };
  }
});
