





























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ElementResultsTooltip from '@/components/elements/ElementResultsTooltip.vue';
import ElementChartUnvailable from '@/components/elements/ElementChartUnvailable.vue';
import PatientResultsChartInterpretations from '@/components/patient/results/interpretations/PatientResultsChartInterpretations.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartContainer',
  components: {
    ElementResultsTooltip,
    ElementChartUnvailable,
    PatientResultsChartInterpretations
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    tooltip: {
      type: Array,
      required: false
    },
    interpretations: {
      type: String,
      required: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    simple: {
      type: Boolean
    }
  }
});
