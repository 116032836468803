











































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartRehabTripleHopJumpProfile from '@/components/charts/rehab/ChartRehabTripleHopJumpProfile.vue';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameJumpingChartTripleHopJumpProfile',
  components: {
    ChartExportationContainer,
    ChartRehabTripleHopJumpProfile
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    isAdvancedMode: {
      type: Boolean,
      required: true
    },
    activityDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const { getTripleHopJumpProfileData } = useRehab();

    const data = getTripleHopJumpProfileData(properties.aggregates, properties.segments);

    return {
      data
    };
  }
});
