import i18n from '@/plugins/i18n';
import { TranslateResult } from 'vue-i18n';

export type OrthoticsProperties = {
  isWalking: boolean;
  isRunning: boolean;
  analysis?: Record<string, any>;
  orthotics?: Record<string, any>;
};

export type InsoleImage = [string, number, number];
export type WedgeDetailedImage = [boolean, string | undefined, string | undefined];

export type WedgesImages = {
  imageLeft?: InsoleImage;
  imageRight?: InsoleImage;
  forefootLeft: WedgeDetailedImage;
  rearfootLeft: WedgeDetailedImage;
  forefootRight: WedgeDetailedImage;
  rearfootRight: WedgeDetailedImage;
};

export const getOrthoticsData = ({ isWalking, isRunning, analysis = {} }: OrthoticsProperties) => {
  return (isWalking || isRunning) && analysis?.metrics?.orthotics['Dspro_BAL'];
};

export const getInsoleImage = ({
  isWalking,
  isRunning,
  orthotics
}: OrthoticsProperties): string | undefined => {
  if (!orthotics) return undefined;

  if (isWalking) {
    switch (orthotics.insole.global) {
      case 'comfort_insole':
        return 'WALK-COMFORT-05.png';
      case 'support_insole':
        return 'WALK-SUPPORT-05.png';
      case 'performance_insole':
        return 'WALK-PERFORMANCE-05.png';
    }
  } else if (isRunning) {
    switch (orthotics.insole.global) {
      case 'forefoot_insole':
        return 'RUN-FOREFOOT-05.png';
      case 'midfoot_insole':
        return 'RUN-MIDFOOT-05.png';
      case 'rearfoot_insole':
        return 'RUN-HEELSTRIKE-05.png';
    }
  }

  return undefined;
};

export const getInsoleDescriptions = ({
  isWalking,
  isRunning,
  orthotics
}: OrthoticsProperties): TranslateResult[] | undefined => {
  if (!orthotics) return undefined;

  const walkingInsoles: Record<string, TranslateResult> = {
    comfort_insole: i18n.t('commons.standards.orthotics.insole.walk.comfort_insole'),
    support_insole: i18n.t('commons.standards.orthotics.insole.walk.support_insole'),
    performance_insole: i18n.t('commons.standards.orthotics.insole.walk.performance_insole')
  };

  const runningInsoles: Record<string, TranslateResult> = {
    forefoot_insole: i18n.t('commons.standards.orthotics.insole.run.forefoot_insole'),
    midfoot_insole: i18n.t('commons.standards.orthotics.insole.run.midfoot_insole'),
    rearfoot_insole: i18n.t('commons.standards.orthotics.insole.run.rearfoot_insole')
  };

  if (isWalking) {
    return [walkingInsoles[orthotics.insole.global]];
  }

  if (isRunning) {
    return [runningInsoles[orthotics.insole.global]];
  }

  return [`Unknown value : ${orthotics.insole.global}`, ''];
};

export const getWedgesImages = ({
  isWalking,
  isRunning,
  orthotics
}: OrthoticsProperties): WedgesImages | undefined => {
  if (!orthotics) return undefined;

  const imageLeft = (): InsoleImage | undefined => {
    if (isWalking) {
      switch (orthotics.insole.global) {
        case 'comfort_insole':
          return ['WALK-COMFORT-A-DROITE.svg', 0, 0];
        case 'support_insole':
          return ['WALK-SUPPORT-A-GAUCHE.svg', 0, 0];
        case 'performance_insole':
          return ['WALK-PERFORMANCE-A-DROITE.svg', 0, 0];
      }
    } else if (isRunning) {
      switch (orthotics.insole.global) {
        case 'forefoot_insole':
          return ['RUN-FOREFOOT-A-GAUCHE.svg', 7, 3];
        case 'midfoot_insole':
          return ['RUN-MIDFOOT-A-GAUCHE.svg', 3, 3];
        case 'rearfoot_insole':
          return ['RUN-HEELSTRIKE-A-GAUCHE.svg', 7, 3];
      }
    }
  };

  const imageRight = (): InsoleImage | undefined => {
    if (isWalking) {
      switch (orthotics.insole.global) {
        case 'comfort_insole':
          return ['WALK-COMFORT-A-GAUCHE.svg', -6, 0];
        case 'support_insole':
          return ['WALK-SUPPORT-A-DROITE.svg', -6, 0];
        case 'performance_insole':
          return ['WALK-PERFORMANCE-A-GAUCHE.svg', -6, 0];
      }
    } else if (isRunning) {
      switch (orthotics.insole.global) {
        case 'forefoot_insole':
          return ['RUN-FOREFOOT-A-DROITE.svg', -91, 3];
        case 'midfoot_insole':
          return ['RUN-MIDFOOT-A-DROITE.svg', -94, 4];
        case 'rearfoot_insole':
          return ['RUN-HEELSTRIKE-A-DROITE.svg', -91, 3];
      }
    }
  };

  const forefootLeft = (): WedgeDetailedImage => {
    switch (orthotics.forefoot_insert.right_foot) {
      case 'no_insert':
        return [true, undefined, undefined];
      case 'supination_insert':
        return [true, 'insert-01-gauche.svg', 'supination-right'];
      case 'pronation_insert':
        return [true, 'insert-02-gauche.svg', 'pronation-right'];
      default:
        return [false, orthotics.forefoot_insert.right_foot, ''];
    }
  };

  const rearfootLeft = (): WedgeDetailedImage => {
    switch (orthotics.rearfoot_insert.right_foot) {
      case 'no_insert':
        return [true, undefined, undefined];
      case 'supination_insert':
        return [true, 'insert-03-gauche.svg', 'supination-right'];
      case 'pronation_insert':
        return [true, 'insert-04-gauche.svg', 'pronation-right'];
      default:
        return [false, orthotics.rearfoot_insert.right_foot, ''];
    }
  };

  const forefootRight = (): WedgeDetailedImage => {
    switch (orthotics.forefoot_insert.left_foot) {
      case 'no_insert':
        return [true, undefined, undefined];
      case 'supination_insert':
        return [true, 'insert-01-droit.svg', 'supination-left'];
      case 'pronation_insert':
        return [true, 'insert-02-droit.svg', 'pronation-left'];
      default:
        return [false, orthotics.forefoot_insert.left_foot, ''];
    }
  };

  const rearfootRight = (): WedgeDetailedImage => {
    switch (orthotics.rearfoot_insert.left_foot) {
      case 'no_insert':
        return [true, undefined, undefined];
      case 'supination_insert':
        return [true, 'insert-03-droit.svg', 'supination-left'];
      case 'pronation_insert':
        return [true, 'insert-04-droit.svg', 'pronation-left'];
      default:
        return [true, orthotics.rearfoot_insert.left_foot, ''];
    }
  };

  return {
    imageLeft: imageLeft(),
    imageRight: imageRight(),
    forefootLeft: forefootLeft(),
    rearfootLeft: rearfootLeft(),
    forefootRight: forefootRight(),
    rearfootRight: rearfootRight()
  };
};
