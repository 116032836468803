












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartWalkingFootProgressionAngleCircumduction from '@/components/charts/walking/ChartWalkingFootProgressionAngleCircumduction.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartFootProgressionAngleCircumduction',
  components: {
    ChartExportationContainer,
    ChartWalkingFootProgressionAngleCircumduction
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  }
});
