
















































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref, watch, onMounted, Ref, PropType } from '@vue/composition-api';

// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
import AlertError from '@/components/alerts/AlertError.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useFormFieldText } from '@/utils/forms.utils';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
import type { AnalysisEntityCompleted } from '@digitsole/blackburn-entities/dist/entities/analysis/analysis-completed.entity';
// -------------------------------------------------------------------------------------------------

export type TChartList = {
  [key: string]: any;
  title: string | string[];
  subtitle: string;
  selected: boolean;
  list?: TChartsList[];
};
export type TChartsList = {
  [key: string]: any;
  title: string;
  selected: boolean;
};
export default defineComponent({
  name: 'DialogAnalysisCustomFilter',
  components: { CompositeDialog, FormFieldText, AlertError },
  props: {
    evolution: {
      type: Boolean
    },
    analysis: {
      type: Object as PropType<AnalysisEntityCompleted>,
      required: true
    }
  },
  setup(properties, { emit }) {
    const busModule = usePodocoreModule('bus');
    const workspacesService = usePodocoreModuleService('workspaces');

    // Analysis filters
    const filterName = useFormFieldText<string>({
      key: 'firstName',
      value: '',
      rules: {
        required: true,
        min: 2,
        max: 50
      }
    });

    const dialogId: DialogId = 'analysis-filter-custom-select';
    const rDialog: any = ref(null);

    const chartsList: Ref<TChartList[]> = ref([
      {
        id: 'walkingPhases',
        title: 'commons.standards.walking-phase',
        subtitle: 'commons.standards.walking-phase.subtitle',
        selected: false,
        list: [
          { id: 'stanceTime', title: 'commons.standards.stance-time', selected: false, evolution: true },
          { id: 'swingTime', title: 'commons.standards.swing-time', selected: false, evolution: true },
          {
            id: 'strideDuration',
            title: 'commons.standards.stride-duration',
            selected: false,
            evolution: false
          },
          { id: 'strideLength', title: 'commons.standards.stride-length', selected: false, evolution: true }
        ],
        evolution: true
      },
      {
        id: 'steppageClearance',
        title: ['commons.standards.steppage', 'commons.standards.clearance'],
        subtitle: 'commons.standards.steppage-clearance.subtitle',
        selected: false,
        evolution: true
      },
      {
        id: 'propulsionRate',
        title: 'commons.standards.propulsion-rate',
        subtitle: 'commons.standards.propulsion-rate.subtitle',
        selected: false,
        evolution: true
      },
      {
        id: 'pronationSupinationAngles',
        title: 'commons.standards.angle-pro-sup',
        subtitle: 'commons.standards.angle-pro-sup.subtitle',
        selected: false,
        evolution: true
      },
      {
        id: 'gaitline',
        title: 'commons.standards.gait-line',
        subtitle: 'commons.standards.gait-line.subtitle',
        selected: false,
        evolution: false
      }
    ]);

    const isLoading = ref(false);
    const isDisabled = computed(
      () =>
        !filterName.value ||
        filterName.value.length < filterName.rules.min! ||
        filterName.value.length > filterName.rules.max!
    );
    const isEditing = computed(() => !!rDialog.value.meta.filter);

    function filteredChartsList() {
      chartsList.value = chartsList.value.filter((chart: any) => {
        if (chart.list)
          chart.list = chart.list.filter((x: any) => {
            if (properties.evolution || x.evolution) return x;
            if (!properties.evolution) return x;
          });
        if (properties.evolution || chart.evolution) return chart;
        if (!properties.evolution) return chart;
        return;
      });
    }

    const chartsSelected = computed(() => {
      const charts: string[] = [];
      for (const chart of chartsList.value) {
        if (chart.list) {
          for (const subchart of chart.list) {
            if (subchart.selected) charts.push(subchart.id);
          }
        } else if (chart.selected) charts.push(chart.id);
      }
      return charts;
    });

    function selectChart(index: number, value?: boolean) {
      const haveValue = value !== undefined;
      chartsList.value[index].selected = haveValue ? (value as boolean) : !chartsList.value[index].selected;
    }
    function selectSubChart(globalIndex: number, chartIndex: number, value?: boolean) {
      const haveValue = value !== undefined;
      if (chartsList.value[globalIndex].selected) unselectChart(globalIndex);
      chartsList.value[globalIndex].list![chartIndex].selected = haveValue
        ? (value as boolean)
        : !chartsList.value[globalIndex].list![chartIndex].selected;
    }
    function selectAllSubChart(index: number) {
      chartsList.value[index].selected = !chartsList.value[index].selected;
      for (const chart of chartsList.value[index].list!) chart.selected = chartsList.value[index].selected;
    }
    function unselectChart(index: number) {
      chartsList.value[index].selected = false;
    }

    function save() {
      busModule.publish(
        busModule.events.analysisFilter(
          rDialog.value.meta.filter
            ? {
                type: 'PATCH',
                filter: 'custom',
                name: filterName.value,
                selectedCharts: chartsSelected.value,
                cuid: rDialog.value.meta.filter.cuid
              }
            : {
                type: 'CREATE',
                filter: 'custom',
                name: filterName.value,
                selectedCharts: chartsSelected.value
              }
        )
      );
    }
    function deleteFilter() {
      workspacesService.send({
        type: 'DELETE_FILTER',
        data: {
          cuid: rDialog.value.meta.filter.cuid
        }
      } as any);
    }

    function fillChatCheckboxes(filters: string[]) {
      chartsList.value.forEach((chart: TChartList, index: number) => {
        if (chart.list) {
          chart.list.forEach((subChart: TChartsList, subIndex: number) => {
            selectSubChart(index, subIndex, filters.includes(subChart.id));
          });
          const fullListSelected =
            chart.list.filter((x: TChartsList) => x.selected).length === chart.list.length;
          selectChart(index, fullListSelected);
        }

        selectChart(index, filters.includes(chart.id));
      });
    }

    function unfillChatCheckboxes() {
      chartsList.value.forEach((chart: TChartList, index: number) => {
        if (chart.list) {
          chart.list.forEach((subChart: TChartsList, subIndex: number) => {
            selectSubChart(index, subIndex, false);
          });
        }
        selectChart(index, false);
      });
    }

    function closeDialog() {
      unfillChatCheckboxes();
      rDialog.value.close();
    }

    const isSuccessFilterCreated = computed(() =>
      [{ creatingFilter: 'success' }].some((element) => workspacesService.state.value.matches(element))
    );
    const isSuccessFilterPatched = computed(() =>
      [{ patchingFilter: 'success' }].some((element) => workspacesService.state.value.matches(element))
    );
    const isSuccessFilterDeleted = computed(() =>
      [{ deletingFilter: 'success' }].some((element) => workspacesService.state.value.matches(element))
    );

    const isErrored = computed(
      () =>
        [{ deletingFilter: 'failure' }].some((element) => workspacesService.state.value.matches(element)) ||
        [{ patchingFilter: 'failure' }].some((element) => workspacesService.state.value.matches(element)) ||
        [{ creatingFilter: 'failure' }].some((element) => workspacesService.state.value.matches(element))
    );

    watch(isSuccessFilterCreated, (value) => {
      if (
        value &&
        rDialog.value.displayContent &&
        (workspacesService.state.value as any).context.current.filters
      ) {
        isLoading.value = true;
        setTimeout(() => {
          isLoading.value = false;
          busModule.publish(
            busModule.events.analysisFilter({
              filter: 'custom',
              cuid: (workspacesService.state.value as any).context.current.filters[
                (workspacesService.state.value as any).context.current.filters.length - 1
              ].cuid,
              name: filterName.value,
              selectedCharts: chartsSelected.value,
              type: 'PATCH'
            })
          );
        }, 3000);
      }
    });

    watch(isSuccessFilterPatched, (value) => {
      if (value && rDialog.value.displayContent) {
        emit('renameFilter', filterName.value);
        closeDialog();
      }
    });

    watch(isSuccessFilterDeleted, (value) => {
      if (value && rDialog.value.displayContent) {
        closeDialog();
      }
    });

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        () => {
          if (isEditing.value) {
            fillChatCheckboxes(rDialog.value.meta.filter?.filters ?? []);
            filterName.value = rDialog.value.meta.filter?.name ?? '';
          } else {
            unfillChatCheckboxes();
            filterName.value = '';
          }
        }
      );
      if (properties.evolution) filteredChartsList();
    });

    return {
      chartsList,
      rDialog,
      dialogId,
      chartsSelected,
      filterName,
      workspacesService,
      // Flags
      isLoading,
      isDisabled,
      isEditing,
      isSuccessFilterCreated,
      isSuccessFilterPatched,
      isSuccessFilterDeleted,
      isErrored,
      // Methods
      closeDialog,
      selectChart,
      selectSubChart,
      selectAllSubChart,
      unselectChart,
      save,
      deleteFilter
    };
  }
});
