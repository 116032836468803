







































































// Import vendors ----------------------------------------------------------------------------------
import {
  ref,
  computed,
  defineComponent,
  watch,
  onUpdated,
  nextTick,
  Ref,
  PropType
} from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationFrameWalking from '@/components/exportation/frames/walking/ExportationFrameWalking.vue';
import ExportationFrameWalkingNeurological from '@/components/exportation/frames/walking/ExportationFrameWalkingNeurological.vue';
import ExportationFrameWalkingTraumatology from '@/components/exportation/frames/walking/ExportationFrameWalkingTraumatology.vue';
import ExportationFrameWalkingReathleticism from '@/components/exportation/frames/walking/ExportationFrameWalkingReathleticism.vue';
import ExportationFrameWalkingCustom from '@/components/exportation/frames/walking/ExportationFrameWalkingCustom.vue';
import ExportationFrameRunning from '@/components/exportation/frames/running/ExportationFrameRunning.vue';
import ExportationFrameJumping from '@/components/exportation/frames/jumping/ExportationFrameJumping.vue';
// Import types ------------------------------------------------------------------------------------
import { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import { Patient } from '@/plugins/podocore/repositories/patients.repository';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
import { usePodocoreModuleService } from '@/plugins/podocore';
import { getIsOnlyOrthotics } from '@/helpers/helped-functions.helper';
import ExportationFrameOrthotics from '@/components/exportation/frames/orthotics/ExportationFrameOrthotics.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationViewerSingleAnalysis',
  components: {
    ExportationFrameOrthotics,
    ExportationFrameWalking,
    ExportationFrameWalkingNeurological,
    ExportationFrameWalkingTraumatology,
    ExportationFrameWalkingReathleticism,
    ExportationFrameWalkingCustom,
    ExportationFrameRunning,
    ExportationFrameJumping
  },
  props: {
    scenario: {
      type: String,
      required: true
    },
    analysis: {
      type: Object,
      required: true
    },
    patient: {
      type: Object as PropType<Entity<Patient>>,
      required: true
    },
    pdfData: {
      type: Object,
      required: true
    },
    headerInformations: {
      type: Object,
      required: true
    },
    mode: {
      type: String as PropType<'normal' | 'advanced'>,
      required: true
    },
    exportSelectedSegments: {
      type: Array,
      required: false,
      default: () => []
    },
    detailledRunningMode: {
      type: Boolean
    },
    showDetails: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    },
    pending: {
      type: Boolean,
      required: true
    },
    filter: {
      type: String,
      required: true
    }
  },
  setup(properties) {
    const aclService = usePodocoreModuleService('acl');
    const rIframe: Ref<any | null> = ref(null);

    const { getMode } = useRehab();

    const pageTotal = ref(0);
    const comments = ref('');
    const actions = ref('');

    const advancedMode = computed(() => properties.mode === 'advanced');

    const hasOrthotics = computed(() => {
      return (
        Object.prototype.hasOwnProperty.call(properties.analysis.metrics, 'orthotics') &&
        (aclService.state.value as any).context.features.orthotics
      );
    });

    const hasOnlyOrthotics = computed(() =>
      getIsOnlyOrthotics((aclService.state.value as any).context.features)
    );

    function print() {
      if (rIframe.value?.exportApp) rIframe.value.exportApp.print();
    }

    function setComments(_comments: string) {
      comments.value = _comments;
    }
    function setActions(_actions: string) {
      actions.value = _actions;
    }

    function getPageLength(_rIframe: Ref<any>): number {
      if (rIframe.value.exportApp) {
        if (hasOrthotics.value && properties.pdfData.orthotics.value) {
          return (
            rIframe.value.exportApp.$children[0].$children.length +
            rIframe.value.exportApp.$children[rIframe.value.exportApp.$children.length - 1].$children.length
          );
        }

        return rIframe.value.exportApp.$children[rIframe.value.exportApp.$children.length - 1].$children
          .length;
      }

      return 1;
    }

    const customFilter = computed(() => {
      return properties.headerInformations.workspace.filters
        ? properties.headerInformations.workspace.filters.find((x: any) => x.cuid === properties.filter)
        : null;
    });

    nextTick(() => {
      setTimeout(() => {
        pageTotal.value = getPageLength(rIframe);
      }, 500);
    });

    watch(
      properties,
      () => {
        nextTick(() => {
          nextTick(() => {
            pageTotal.value = getPageLength(rIframe);
          });
        });
      },
      {
        deep: true,
        immediate: true
      }
    );

    onUpdated(() => {
      nextTick(() => {
        nextTick(() => {
          pageTotal.value = getPageLength(rIframe);
        });
      });
    });

    return {
      // References
      rIframe,
      // Values
      hasOrthotics,
      hasOnlyOrthotics,
      customFilter,
      pageTotal,
      comments,
      actions,
      advancedMode,
      // Methods
      print,
      setComments,
      setActions,
      getMode
    };
  }
});
