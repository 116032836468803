









// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// Import helpers ----------------------------------------------------------------------------------
import { getCountryByCode } from '@/helpers/helped-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientCountryCard',
  setup() {
    const { data: patient } = usePatient();

    return {
      // Values
      patient,
      // Methods
      getCountryByCode
    };
  }
});
