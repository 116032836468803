













































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartStandardGaugeSliderPersonnalized',
  props: {
    data: {
      type: String,
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    valid: {
      type: Boolean,
      default: true
    },
    noRange: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const color = '#003DD1';

    // SYMMETRY CHART
    const radius = ref(40);
    const strokeWidth = computed(() => {
      return radius.value * 0.12;
    });
    const innerRadius = computed(() => {
      return radius.value - strokeWidth.value;
    });
    const circumference = computed(() => {
      return innerRadius.value * 2 * Math.PI;
    });
    const arc = computed(() => {
      return circumference.value * (270 / 360);
    });
    const dashArray = computed(() => {
      return `${arc.value} ${circumference.value}`;
    });
    const transform = computed(() => {
      return `rotate(135, ${radius.value}, ${radius.value})`;
    });

    return {
      // Values
      radius,
      strokeWidth,
      innerRadius,
      color,
      dashArray,
      transform
    };
  }
});
