




















































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameRunningChartRunProfile',
  components: {
    ChartExportationContainer
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();
    const valueType = computed(() => (isImperial.value ? 'imp_avg' : 'avg'));

    const valid = computed(
      () =>
        properties.displayMetrics.running_proportion_stance_time &&
        properties.displayMetrics.running_proportion_swing_time &&
        properties.displayMetrics.running_stride_length &&
        properties.displayMetrics.running_proportion_flight_time
    );

    const strideLengthUnit = computed(() => getUnit(EValueTypeUnit.StrideLength));

    return {
      valid,
      valueType,
      strideLengthUnit
    };
  }
});
