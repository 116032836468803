

































































































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, reactive, computed } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingPropulsionRatio',
  props: {
    values: {
      type: Object,
      required: true
    }
  },

  setup(properties) {
    const valuePosition: any = reactive({
      high: 160,
      neutral: 124,
      low: 90
    });
    const hipClass: any = reactive({
      very_low: 'cl-warning',
      low: 'cl-warning',
      high: 'cl-neutral',
      very_high: 'cl-neutral',
      neutral: 'cl-success',
      unknow: 'cl-neutral'
    });
    const ankleClass: any = reactive({
      very_low: 'cl-neutral',
      low: 'cl-neutral',
      high: 'cl-warning',
      very_high: 'cl-warning',
      neutral: 'cl-success',
      unknow: 'cl-neutral'
    });

    const musclesClass = computed(() => {
      const _musclesClass: any = {
        left_foot: {
          hip: null,
          ankle: null
        },
        right_foot: {
          hip: null,
          ankle: null
        }
      };
      for (const side of ['left_foot', 'right_foot']) {
        _musclesClass[side].hip = hipClass[properties.values[side].range || 'unknow'];
        _musclesClass[side].ankle = ankleClass[properties.values[side].range || 'unknow'];
      }

      return _musclesClass;
    });

    function getRatioRange(indicator: string) {
      switch (indicator) {
        case 'high':
        case 'very_high':
          return {
            class: 'cl-warning',
            pos: valuePosition.high
          };
        case 'low':
        case 'very_low':
          return {
            class: 'cl-warning',
            pos: valuePosition.low
          };
        case 'neutral':
          return {
            class: 'cl-success',
            pos: valuePosition.neutral
          };
        default:
          return {
            class: 'cl-neutral',
            pos: valuePosition.neutral
          };
      }
    }

    function getCheckIndicatortranslate(side: string, checkIndicator: boolean, value: number) {
      const totalNeutral = value === 0;
      const leftSide = side === 'left_foot';
      if (checkIndicator) {
        if (totalNeutral) return leftSide ? 'translate(-2 -1)' : 'translate(163 -1)';
        return leftSide ? 'translate(-38 -1)' : 'translate(193 -1)';
      }

      return totalNeutral ? `translate(${leftSide ? -15 : 150} -2)` : `translate(${leftSide ? -51 : 180} -2)`;
    }

    function getArrowIndicatortranslate(side: string, checkIndicator: boolean, value: number) {
      const leftSide = side === 'left_foot';
      if (checkIndicator) {
        return leftSide ? 'translate(-2 0)' : 'translate(220 0)';
      }

      return `translate(${leftSide ? -15 : 207} -1)`;
    }

    return {
      // Values
      musclesClass,
      // Methods
      getRatioRange,
      getCheckIndicatortranslate,
      getArrowIndicatortranslate
    };
  }
});
