import { render, staticRenderFns } from "./ElementChipNormValue.vue?vue&type=template&id=3bed1e59&scoped=true&"
import script from "./ElementChipNormValue.vue?vue&type=script&lang=ts&"
export * from "./ElementChipNormValue.vue?vue&type=script&lang=ts&"
import style0 from "./ElementChipNormValue.vue?vue&type=style&index=0&id=3bed1e59&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bed1e59",
  null
  
)

export default component.exports