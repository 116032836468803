












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils - ----------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
// Import types ------------------------------------------------------------------------------------
import { TDataStandard } from '@/components/patient/PatientEvolution.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionSwingTime',
  components: {
    ChartEvolutionContainer
  },
  props: {
    manifest: {
      type: Array,
      required: true
    },
    swingTime: {
      type: Object
    },
    proportionSwingTime: {
      type: Object
    },
    norms: {
      type: Object
    },
    relativeNorms: {
      type: Object
    },
    relative: {
      type: Boolean,
      required: true
    },
    exportation: {
      type: Boolean
    }
  },
  setup(properties) {
    const {
      evolutionChartStructure,
      getSideData,
      getSerieItemStructure,
      getSideDataVariations,
      getNormsStructure
    } = useChart();

    const title = 'commons.standards.swing-time';

    const swingTime: TDataStandard = computed(() =>
      getSideData(properties[properties.relative ? 'proportionSwingTime' : 'swingTime'])
    );

    const dataVariations = computed(() =>
      getSideDataVariations({ left: swingTime.value?.left.values, right: swingTime.value?.right.values })
    );

    const valid = computed(() => !!swingTime.value);

    const series = computed(() => {
      let data = [
        // Left
        getSerieItemStructure(
          'swing_time_left',
          `${i18n.t('commons.standards.swing-time')} (${(i18n.t('commons.standards.left') as string).slice(
            0,
            1
          )})`,
          swingTime.value?.left.values,
          EThemeColor.Chart1
        ),
        getSerieItemStructure(
          'swing_time_left',
          undefined,
          swingTime.value?.left.ranges,
          EThemeColor.Chart1,
          true
        ),
        // Right
        getSerieItemStructure(
          'swing_time_right',
          `${i18n.t('commons.standards.swing-time')} (${(i18n.t('commons.standards.right') as string).slice(
            0,
            1
          )})`,
          swingTime.value?.right.values,
          EThemeColor.Chart2
        ),
        getSerieItemStructure(
          'swing_time_right',
          undefined,
          swingTime.value?.right.ranges,
          EThemeColor.Chart2,
          true
        )
      ];
      // Have norms
      if (properties.norms)
        data.push(
          getNormsStructure(
            'swing_time_norms',
            (properties.relative ? properties.relativeNorms : properties.norms) as {
              min: number[];
              max: number[];
            }
          )
        );

      return data;
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(
          title,
          properties.manifest,
          properties.relative ? EUnitKey.Percent : EUnitKey.Millisecond,
          0,
          { exportation: properties.exportation }
        ),
        series: series.value
      };
    });

    return {
      title,
      dataVariations,
      chartOptions,
      valid
    };
  }
});
