



















































































// Import vendors ----------------------------------------------------------------------------------
import {
  defineComponent,
  ref,
  watch,
  onBeforeMount,
  onBeforeUnmount,
  computed,
  reactive,
  readonly
} from '@vue/composition-api';
import { has } from 'lodash';
import SparkMD5 from 'spark-md5';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
import PatientMediasVideo from '@/components/patient/PatientMediasVideo.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientMedias',
  components: {
    AlertError,
    PatientMediasVideo
  },
  setup() {
    const { data: patient } = usePatient();

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    const mediasRequest = requestModule.useAuthenticatedRequest(`${apiConfig.default}/videos`);

    const latestEvent = ref(null);

    const isPortrait = ref(false);
    const analysisCuid = ref('');

    const defaultUploadingFile = {
      state: null as 'hashing' | 'registering' | 'uploading' | 'processing' | 'failure' | null,
      hashTotalParts: 0,
      hashCurrentPart: 0,
      hashResult: null as string | null,
      registrationCuid: null,
      uploadProgress: 0,
      processingProgress: 0
    };

    const uploadingFile = reactive(Object.assign({}, defaultUploadingFile));

    const registerVideoRequest = ref<any>(null);
    const uploadVideoRequest = ref<any>(null);

    busModule.useEventSubscriber(busModule.events.createNotification, ({ payload }) => {
      if (uploadingFile.state === 'processing') {
        const message = payload.message;

        if (has(message, 'body.message.messageType')) {
          if (message.body.message.messageType.startsWith('video')) {
            latestEvent.value = message.body.message;

            if (message.body.message.data.cuid === uploadingFile.registrationCuid) {
              if (message.body.message.messageType === 'video-completed') {
                mediasRequest.request();

                Object.assign(uploadingFile, defaultUploadingFile);
              } else {
                uploadingFile.processingProgress = message.body.message.data.processingJobProgression;
              }
            }
          }
        }
      }
    });

    async function handleFileUpload(event: { target: HTMLInputElement }) {
      const file = event.target.files?.[0];

      if (file) {
        // 1. Compute hash
        const bufferSize = Math.pow(1024, 2) * 10; // 10MB
        const reader = new FileReader();
        const hashAlgorithm = new SparkMD5();
        const totalParts = Math.ceil(file.size / bufferSize);

        uploadingFile.state = 'hashing';
        uploadingFile.hashTotalParts = totalParts;
        uploadingFile.hashCurrentPart = 0;
        uploadingFile.hashResult = null;

        const hash: string = await new Promise((resolve) => {
          function processNextPart() {
            const start = uploadingFile.hashCurrentPart * bufferSize;
            const end = Math.min(start + bufferSize, file!.size);

            reader.readAsBinaryString(file!.slice(start, end));
          }

          reader.onload = function (readerEvent) {
            uploadingFile.hashCurrentPart += 1;
            hashAlgorithm.appendBinary(readerEvent.target!.result as any);
            if (uploadingFile.hashCurrentPart < uploadingFile.hashTotalParts) {
              processNextPart();
            } else {
              resolve(window.btoa(hashAlgorithm.end(true)));
            }
          };

          processNextPart();
        });

        uploadingFile.hashResult = hash;
        uploadingFile.state = 'registering';

        // 2. Register video
        registerVideoRequest.value = requestModule.useAuthenticatedRequest(`${apiConfig.default}/videos`, {
          axios: {
            method: 'POST',
            data: {
              name: new Date().toISOString(),
              patientCuid: patient.value?.cuid,
              md5Checksum: uploadingFile.hashResult,
              orientation: isPortrait.value ? 'portrait' : 'landscape',
              analysisCuid: analysisCuid.value
            }
          }
        });

        const unwatchRegisterVideoRequest = watch(
          () => registerVideoRequest.value?.data,
          (data) => {
            unwatchRegisterVideoRequest();

            // 3. Upload video
            if (data) {
              uploadingFile.state = 'uploading';
              uploadingFile.registrationCuid = data.cuid;

              uploadVideoRequest.value = requestModule.useRequest(data.presignedS3UploadUrl, {
                axios: {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/octet-stream',
                    'Content-MD5': uploadingFile.hashResult
                  },
                  data: file,
                  onUploadProgress: (progressEvent) => {
                    uploadingFile.state = 'uploading';
                    uploadingFile.uploadProgress = Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100
                    );
                  }
                }
              });

              const unwatchUploadVideoRequest = watch(
                () => uploadVideoRequest.value?.data,
                () => {
                  unwatchUploadVideoRequest();

                  uploadingFile.state = 'processing';
                }
              );

              uploadVideoRequest.value.request();
            }
          }
        );

        registerVideoRequest.value.request();
      }
    }

    const progressLinear = computed(() => {
      switch (uploadingFile.state) {
        case 'hashing':
          return {
            visible: true,
            value: (uploadingFile.hashCurrentPart / uploadingFile.hashTotalParts) * 100
          };
        case 'registering':
          return {
            visible: true,
            indeterminate: true
          };
        case 'uploading':
          return {
            visible: true,
            value: uploadingFile.uploadProgress,
            indeterminate: uploadingFile.uploadProgress <= 0
          };
        case 'processing':
          return {
            visible: true,
            value: uploadingFile.processingProgress,
            indeterminate: uploadingFile.processingProgress <= 0
          };
        default:
          return {
            visible: false
          };
      }
    });

    onBeforeMount(() => {
      mediasRequest.request();
    });

    onBeforeUnmount(() => {
      mediasRequest.cancel();
    });

    return {
      // Values
      mediasRequest,
      latestEvent,
      uploadingFile: readonly(uploadingFile),
      progressLinear,
      isPortrait,
      analysisCuid,
      // Methods
      handleFileUpload
    };
  }
});
