





































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ElementChartUnvailable from '@/components/elements/ElementChartUnvailable.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartExportationContainer',
  components: {
    ElementChartUnvailable
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    tooltip: {
      type: Array,
      required: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    },
    analysisDate: {
      type: String,
      required: false
    }
  },
  setup(properties) {
    const haveMultipleValues = computed(() => properties.tooltip && properties.tooltip.length > 1);
    function breakLine(value: any) {
      return value.replace(/(\r\n|\n)/g, ' ');
    }

    return {
      haveMultipleValues,
      breakLine
    };
  }
});
