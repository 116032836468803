











// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRunningContainerGroupBanner from '@/components/charts/running/containers/ChartRunningContainerGroupBanner.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameRunningChartBanner',
  components: {
    ChartRunningContainerGroupBanner
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    degraded: {
      type: Boolean
    },
    analysisDate: {
      type: String,
      required: false
    }
  },
  setup() {
    const opacity = 1;
    const width = 24;

    return {
      opacity,
      width
    };
  }
});
