
























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRehabWidgetSymmetry from '@/components/charts/rehab/widgets/ChartRehabWidgetSymmetry.vue';
import ChartRehabWidgetTotalDistance from '@/components/charts/rehab/widgets/ChartRehabWidgetTotalDistance.vue';
import ChardRehabWidgetJumpCount from '@/components/charts/rehab/widgets/ChartRehabWidgetJumpCount.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabContainerBanner',
  components: {
    ChartRehabWidgetSymmetry,
    ChartRehabWidgetTotalDistance,
    ChardRehabWidgetJumpCount
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    activeWidgets: {
      type: Array,
      required: true
    },
    activityDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  }
});
