





























































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, reactive } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionRehabSide from '@/components/charts/evolution/rehab/ChartEvolutionRehabSide.vue';
import ChartEvolutionRehabGlobal from '@/components/charts/evolution/rehab/ChartEvolutionRehabGlobal.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionGroupsRehabCMJ',
  components: {
    ChartEvolutionRehabSide,
    ChartEvolutionRehabGlobal
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    relative: {
      type: Boolean,
      required: true
    }
  },
  setup(properties) {
    const aggregates = computed(() => properties.values.data.aggregates);
    const manifest = computed(() => properties.values.analysisManifests);

    const unit = reactive({
      centerofmass: getUnit(EValueTypeUnit.CenterOfMass)
    });

    const modes = reactive({
      jumpProfile: true,
      standard: false,
      advanced: false
    });

    return {
      aggregates,
      manifest,
      modes,
      unit
    };
  }
});
