





















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartRehabSideHopCadenceEvolution from '@/components/charts/rehab/ChartRehabSideHopCadenceEvolution.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameJumpingChartSideHopCadenceEvolution',
  components: {
    ChartExportationContainer,
    ChartRehabSideHopCadenceEvolution
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: false
    },
    displayTooltips: {
      type: Boolean
    }
  }
});
