




import type { PropType } from '@vue/composition-api';
// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, toRefs } from '@vue/composition-api';
// Import repositories -----------------------------------------------------------------------------
import { AnalysisType } from '@/plugins/podocore/repositories/analysis.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AnalysisDot',
  props: {
    analysisType: {
      type: String as PropType<AnalysisType>
    }
  },
  setup(properties: { analysisType: AnalysisType }) {
    const { analysisType } = toRefs(properties);

    const backgroundColor = computed(() => {
      switch (analysisType.value) {
        case AnalysisType.walking:
          return 'var(--v-primary-base)';
        case AnalysisType.running:
          return 'var(--v-success-base)';
        case AnalysisType.jumping:
          return 'var(--v-rehab-base)';
        default:
          return 'var(--v-grey3-base)';
      }
    });

    return {
      // Values
      backgroundColor
    };
  }
});
