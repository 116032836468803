













// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, watchEffect, Ref } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { EPhaseValuesTypesUnit } from '@/helpers/helped-chart-functions.helper';
// Import plugins ----------------------------------------------------------------------------------
import { DrawSVG } from '@/plugins/charts/gaitline/gaitline';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingGaitLine',
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    relative: {
      type: Boolean,
      required: true
    }
  },
  setup(properties) {
    const rCanvas: Ref<HTMLCanvasElement | null> = ref(null);
    const arrayIcons: any = ref([
      {
        id: 'digitigrade',
        icon: require('@/assets/images/propulsion.svg')
      },
      {
        id: 'plantigrade',
        icon: require('@/assets/images/flat-foot.svg')
      },
      {
        id: 'taligrade',
        icon: require('@/assets/images/heel-strike.svg')
      }
    ]);

    const data: any = computed(() => {
      const data: any = {
        taligrade: {},
        plantigrade: {},
        digitigrade: {},
        heelStrike: {},
        flatFoot: {},
        heelOff: {},
        toesOff: {}
      };
      const angleRanges: any = {
        heelStrike: {
          range0: -13.5,
          range1: -10,
          range2: -6,
          range3: -2
        },
        flatFoot: {
          range0: -13.5,
          range1: -10,
          range2: -7,
          range3: -3.5
        },
        heelOff: {
          range0: -15.5,
          range1: -11.5,
          range2: -6,
          range3: -2
        },
        toesOff: {
          range0: -7.5,
          range1: -9.5,
          range2: -0.5,
          range3: -2.5
        }
      };
      for (const phase of [
        { taligrade: 'loading' },
        { plantigrade: 'flatfoot' },
        { digitigrade: 'propulsion' }
      ]) {
        const leftAbs = {
          status: properties.aggregates[`walking_${Object.values(phase)[0]}_time`].left_foot.range,
          value: properties.aggregates[`walking_${Object.values(phase)[0]}_time`].left_foot.avg
        };
        const leftRelative = {
          status: properties.aggregates[`walking_proportion_${Object.values(phase)[0]}_time`].left_foot.range,
          value: properties.aggregates[`walking_proportion_${Object.values(phase)[0]}_time`].left_foot.avg
        };
        const rightAbs = {
          status: properties.aggregates[`walking_${Object.values(phase)[0]}_time`].right_foot.range,
          value: properties.aggregates[`walking_${Object.values(phase)[0]}_time`].right_foot.avg
        };
        const rightRelative = {
          status:
            properties.aggregates[`walking_proportion_${Object.values(phase)[0]}_time`].right_foot.range,
          value: properties.aggregates[`walking_proportion_${Object.values(phase)[0]}_time`].right_foot.avg
        };
        data[Object.keys(phase)[0]] = {
          left: {
            abs: leftAbs,
            rel: leftRelative
          },
          right: {
            abs: rightAbs,
            rel: rightRelative
          }
        };
      }

      for (const angle of [
        { heelStrike: 'foot_in' },
        { flatFoot: 'FFI' },
        { heelOff: 'FFO' },
        { toesOff: 'foot_out' }
      ]) {
        data[Object.keys(angle)[0]] = {
          left: 'P' + properties.aggregates[`walking_gaitline_${Object.values(angle)[0]}`].left_foot.avg,
          right: 'P' + properties.aggregates[`walking_gaitline_${Object.values(angle)[0]}`].right_foot.avg,
          ...angleRanges[Object.keys(angle)[0]]
        };
      }

      return data;
    });
    const dataMode = computed(() =>
      properties.relative ? EPhaseValuesTypesUnit.Relative : EPhaseValuesTypesUnit.Absolute
    );

    function draw() {
      if (rCanvas.value) {
        const graphCanvas = new DrawSVG(rCanvas.value as HTMLCanvasElement, data.value, dataMode.value);
        requestAnimationFrame(() => {
          graphCanvas.draw();
        });
      }
    }

    watchEffect(() => {
      draw();
    });

    return {
      rCanvas,
      arrayIcons
    };
  }
});
