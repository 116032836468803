import type { usePodocoreModuleService } from '@/plugins/podocore';
import { computed, onBeforeMount, Ref } from '@vue/composition-api';
import { useLocalStorage } from '@vueuse/core';
import { getIsOnlyOrthotics } from '@/helpers/helped-functions.helper';

export enum EResultsTab {
  General = 'general',
  Advanced = 'advanced',
  Scoring = 'scoring',
  Orthotics = 'orthotics'
}

const resultsTab = useLocalStorage<EResultsTab>('results-tab-v2', EResultsTab.General);

export function usePatientResultTab(aclService: ReturnType<typeof usePodocoreModuleService>): {
  isOnlyOrthotics: Ref<boolean>;
  resultsTab: Ref<EResultsTab>;
} {
  if (!aclService) throw new Error('aclService is undefined');

  const isOnlyOrthotics = computed(() =>
    getIsOnlyOrthotics((aclService.state.value as any).context.features)
  );

  onBeforeMount(() => {
    if (isOnlyOrthotics.value) {
      resultsTab.value = EResultsTab.Orthotics;
    }
  });

  return {
    isOnlyOrthotics,
    resultsTab
  };
}
