












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartExportationContainer from '@/components/charts/ChartExportationContainer.vue';
import ChartWalkingGaitLine from '@/components/charts/walking/ChartWalkingGaitLine.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartGaitLine',
  components: {
    ChartExportationContainer,
    ChartWalkingGaitLine
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    patient: {
      type: Object,
      required: false,
      default: null
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    },
    displayTooltips: {
      type: Boolean
    }
  },
  setup(properties) {
    const valid = computed(
      () =>
        properties.displayMetrics.walking_gaitline_FFO &&
        properties.displayMetrics.walking_gaitline_foot_in &&
        properties.displayMetrics.walking_gaitline_foot_out &&
        properties.displayMetrics.walking_gaitline_FFI &&
        properties.displayMetrics.walking_loading_time &&
        properties.displayMetrics.walking_propulsion_time &&
        properties.displayMetrics.walking_flatfoot_time &&
        properties.displayMetrics.walking_proportion_loading_time &&
        properties.displayMetrics.walking_proportion_propulsion_time &&
        properties.displayMetrics.walking_proportion_flatfoot_time
    );

    const opacity = 1;
    const width = 24;

    return {
      valid,
      opacity,
      width
    };
  }
});
