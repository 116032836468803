













// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, watch, onMounted, toRefs, Ref } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ElementResultsSwitchRelative',
  model: {
    prop: 'state',
    event: 'update'
  },
  props: {
    state: {
      type: Boolean,
      required: true
    },
    evolution: {
      type: Boolean,
      required: false
    }
  },
  setup(properties, { emit }) {
    const { state } = toRefs(properties);
    const _state: Ref<boolean> = ref(false);

    watch(_state, () => emit('update', _state.value));

    onMounted(() => (_state.value = state.value));

    return {
      _state
    };
  }
});
