






















import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';

export default defineComponent({
  name: 'ChartRunningWidgetVLR',
  components: {
    ChartContainer
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
});
