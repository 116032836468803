






















































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
import AnalysisRenameForm from '@/components/analysis/AnalysisRenameForm.vue';
// Import utils ------------------------------------------------------------------------------------
import { useAnalytics } from '@/utils/analytics.utils';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogAnalysisRename',
  components: { CompositeDialog, AlertError, AlertSuccess, AnalysisRenameForm },
  setup() {
    const { trackSuccess } = useAnalytics();
    const dialogId: DialogId = 'analysis-rename';
    const rDialog = ref<any>(null);

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    let analysisRequest = ref<any>(null);
    let analysisPatchedRequest = ref<any>(null);

    const name = ref<string | null>(null);

    function submitForm() {
      rDialog.value.$children[0].$children[0].$children[0].$children[3].submit();
    }

    function renameAnalysis(data: { name: string }) {
      analysisPatchedRequest.value.request({
        axios: {
          method: 'PATCH',
          data
        }
      });
    }

    function setName(_name: any | null) {
      name.value = _name.value;
    }

    function closeDialog() {
      rDialog.value.close();
    }

    const isMounted = computed(() => {
      return analysisRequest.value && analysisPatchedRequest.value;
    });

    const isDisabled = computed(() => {
      return !analysisRequest.value || analysisRequest.value.isPending;
    });

    const isSuccess = computed(() => {
      return !analysisPatchedRequest.value?.error && !!analysisPatchedRequest.value?.data;
    });

    watch(isSuccess, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.analysisRenamed({
            analysisCuid: rDialog.value.meta.analysisCuid,
            name: analysisPatchedRequest.value?.data.name
          })
        );
        trackSuccess('PatientAnalysis/Rename');
      }
    });

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (value) {
            analysisRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/analysis/${rDialog.value.meta.analysisCuid}`
            );
            analysisPatchedRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/analysis/${rDialog.value.meta.analysisCuid}/rename`,
              {
                axios: {
                  method: 'PATCH'
                }
              }
            );

            analysisRequest.value.request();
          } else {
            analysisRequest.value.cancel();
            analysisPatchedRequest.value.cancel();

            nextTick(() => {
              analysisRequest.value.clear();
              analysisPatchedRequest.value.clear();

              analysisRequest.value = null;
            });
          }
        }
      );
    });

    return {
      rDialog,
      dialogId,
      analysisRequest,
      analysisPatchedRequest,
      name,
      // Methods
      submitForm,
      renameAnalysis,
      setName,
      closeDialog,
      // Flags
      isMounted,
      isDisabled,
      isSuccess
    };
  }
});
