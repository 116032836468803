

































// Import vendors ----------------------------------------------------------------------------------
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import type { NavItem } from '@/components/nav/NavbarItem.vue';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
import { useRoute } from '@/utils/router.utils';
// Import components -------------------------------------------------------------------------------
import NavbarItem from '@/components/nav/NavbarItem.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientNavbar',
  props: {
    isOnlyOrthotics: Boolean
  },
  components: {
    NavbarItem
  },
  setup({ isOnlyOrthotics }, { root }) {
    const { data: patient } = usePatient();
    const busModule = usePodocoreModule('bus');

    const route = useRoute();
    const isPatientFilePage = computed(() => {
      return (
        !root.$route.path.includes('result') &&
        !root.$route.path.includes('evolution') &&
        !root.$route.path.includes('comparison') &&
        !root.$route.path.includes('medias')
      );
    });

    const navItems: ComputedRef<NavItem[]> = computed(() => [
      {
        title: root.$t('commons.word.dashboard'),
        hash: '#dashboard',
        isSelected: isPatientFilePage.value && route.hash.value === '#dashboard',
        to: {
          name: 'patient',
          params: {
            id: route.params.value.cuid
          },
          hash: '#dashboard'
        }
      },
      {
        title: root.$t('commons.word.patient-file'),
        hash: '#patient-file',
        isSelected: isPatientFilePage.value && route.hash.value === '#patient-file',
        to: {
          name: 'patient',
          params: {
            id: route.params.value.cuid
          },
          hash: '#patient-file'
        }
      },
      {
        title: root.$t('commons.word.practitioner-analysis'),
        hash: '#practitioner-analysis',
        isSelected: isPatientFilePage.value && route.hash.value === '#practitioner-analysis',
        to: {
          name: 'patient',
          params: {
            id: route.params.value.cuid
          },
          hash: '#practitioner-analysis'
        }
      },
      {
        title: root.$t('commons.standards.evolution'),
        hash: '#evolution',
        isSelected: isPatientFilePage.value && route.hash.value === '#evolution',
        isDisabled: isOnlyOrthotics,
        to: {
          name: 'patient',
          params: {
            id: route.params.value.cuid
          },
          hash: '#evolution'
        }
      }
    ]);

    function editPatient() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'patient-update',
          meta: { patientCuid: patient.value?.cuid }
        })
      );
    }

    return {
      // Values
      patient,
      navItems,
      // Methods
      editPatient
    };
  }
});
