








// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
import moment from 'moment';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartStandardGaugeSliderPersonnalized from '@/components/charts/standard/ChartStandardGaugeSliderPersonnalized.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningWidgetTotalTime',
  components: {
    ChartContainer,
    ChartStandardGaugeSliderPersonnalized
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    simple: {
      type: Boolean
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.aggregates?.running_duration);
    const data = computed(() =>
      moment.utc(properties.aggregates.running_duration.global.sum * 1000).format('mm:ss')
    );
    return {
      valid,
      data
    };
  }
});
